import dayjs from 'dayjs';
import { ICompanyInterface } from 'Interfaces/ICompaniesConfig';
import {
  EnumAddressType,
  EnumScheduleType,
} from 'Interfaces/IGlobalTypeConfig';
import { initialCompanyState } from 'pages/Companies/CompanyForm';

/* export const ticketEmployeeInitial: ITicketEmployeeInterface = {
  employee: employeeInitialState,
  employeeProducts: [] as ICompanyProductViewModel[],
} */


type CompanyAction =
  | { type: 'SET_COMPANY'; payload: ICompanyInterface }
;

export const CompanyReducer = (
  state: ICompanyInterface = initialCompanyState,
  action: CompanyAction
): ICompanyInterface => {
  switch (action.type) {
    case 'SET_COMPANY':
      console.log('ghg SET_COMPANY', action.payload);
      return {
        ...state,
        ...action.payload,   
      };     
    default:
      return state;
  }
};
