import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AccountMenu } from 'components/ui/AccountMenu';

import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Business,
  Dashboard as DashboardIcon,
  DevicesOther,
  ShoppingCart,
  SupportAgent,
} from '@mui/icons-material';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import FeedbackIcon from '@mui/icons-material/Feedback';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import CloudRoundedIcon from '@mui/icons-material/CloudRounded';
import ApiIcon from '@mui/icons-material/Api';

import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import ownerAtom from 'data/atoms/ownerAtom';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import ratesAtom from 'data/atoms/ratesAtom';
import { t } from 'i18next';

const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + '/img/',
};
export const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [companyQty, setCompanyQty] = useState(0);
  const [ticketsQty, setTicketsQty] = useState(0);
  const [salesQty, setSalesQty] = useState(0);
  const [projectsQty, setProjectsQty] = useState(0);
  const [ordersQty, setOrdersQty] = useState(0);
  const [emailsQty, setEmailsQty] = useState(''); // State to store email count
  const [timeLeft, setTimeLeft] = useState(300); // Countdown timer set to 300 seconds (5 minutes)
  const [isRunning, setIsRunning] = useState(false); // State to track whether the countdown is running
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null); // State to store the interval ID
  const [isEmail, setIsEmail] = useState<boolean>(false); // Control whether to fetch emails or not

  const [owner, setOwner] = useAtom(ownerAtom);
  const [user, setUser] = useAtom(userAtom);
  const [rates, setRates] = useAtom(ratesAtom);

  // API
  let isEnabled = email !== '' ? true : false;
  const { data: userData } = useGet(
    rq.employeeByEmail(email, isEnabled),
    isEnabled
  );
  let IsUser = userData?.id ? true : false;
  const { data: ownerData } = useGet(rq.appOwner(IsUser), IsUser);
  const { data: ratesData } = useGet(rq.rates(IsUser), IsUser);

  // Email fetching API using isEmail state to control the request
  const {
    data: emailsData,
    error,
    refetch,
  } = useGet(rq.getEmail(isEmail), isEmail);

  useEffect(() => {
    if (ratesData) {
      // console.log(ratesData);
      setRates(ratesData.rates);
    }
  }, [ratesData, setRates]);

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData, setUser]);

  useEffect(() => {
    if (ownerData) {
      setOwner(ownerData);
    }
  }, [ownerData, setOwner]);

  // Handle the API call and reset the countdown when the countdown reaches 0
  useEffect(() => {
    if (timeLeft === 0 && isRunning) {
      refetch(); // Trigger the API call when the countdown reaches 0
      setTimeLeft(300); // Reset the countdown to 5 minutes (300 seconds)
    }
  }, [timeLeft, isRunning, refetch]);

  // Error handling for the API call
  useEffect(() => {
    if (error) {
      console.error('Failed to fetch emails:', error);
    }
    if (emailsData) {
      setEmailsQty(emailsData); // Update email count if the API call is successful
    }
  }, [emailsData, error]);

  // Function to start the countdown and API call
  const startCountdown = () => {
    const id = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0)); // Countdown by 1 second
    }, 1000); // 1000ms = 1 second
    setIntervalId(id); // Save the interval ID
  };

  // Function to stop the countdown and API call
  const stopCountdown = () => {
    if (intervalId) {
      clearInterval(intervalId); // Clear the interval to stop the countdown
      setIntervalId(null); // Reset the interval ID
    }
    setTimeLeft(300); // Reset the timer back to 5 minutes
  };

  // Function to handle the button click (start/stop logic)
  const handleToggleCountdown = () => {
    if (isRunning) {
      stopCountdown(); // Stop the countdown
      setIsEmail(false); // Disable the email fetching
    } else {
      setIsEmail(true); // Enable email fetching
      startCountdown(); // Start the countdown
      refetch(); // Trigger the API call immediately on first button click
    }
    setIsRunning(!isRunning); // Toggle the running state
  };

  return (
    <Paper
      variant='elevation3'
      sx={{
        //width: drawerWidth,
        height: '100%',
        p: 1,
        //ml: 1,
        mr: 1,
        //position: 'fixed',
        alignItems: 'center',
      }}
    >
      <Link to='/'>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img height='100' src={variables.PHOTO_URL + 'iBluu.png'} alt='' />
        </Grid>
      </Link>

      <AccountMenu setEmail={setEmail} url={user?.url ?? null} />

      <Divider sx={{ backgroundColor: 'primary.lighter' }} />
      <List>
        <ListItem
          component={Link}
          to={'/dashboard'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.dashboard')} />
          <Badge>{''}</Badge>
        </ListItem>
        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem
          component={Link}
          to={'/companies'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.companies')} />
          <Badge>{companyQty}</Badge>
        </ListItem>
        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem
          component={Link}
          to={'/tickets'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <SupportAgent />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.tickets')} />

          <Badge>{ticketsQty}</Badge>
        </ListItem>
        {/* 
        <ListItem
          component={Link}
          to={'/sales'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText primary={'Sales'} />
          <Badge>{salesQty}</Badge>
        </ListItem>
 */}
        {/*  <ListItem
          component={Link}
          to={'/projects'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <ContentPasteRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Projects'} />
          <Badge>{projectsQty}</Badge>
        </ListItem> */}

        {/* <Divider sx={{ backgroundColor: 'primary.lighter' }} /> */}

        <ListItem
          component={Link}
          to={'/orders'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <ReceiptRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.orders')} />

          <Badge>{ordersQty}</Badge>
        </ListItem>

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem
          component={Link}
          to={'/products/new'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <DevicesOther />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.products')} />

          <Badge>{0}</Badge>
        </ListItem>
        {/*  <Divider sx={{ backgroundColor: 'primary.lighter' }} />
         */}
        {/*   <ListItem
          component={Link}
          to={'/storage'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <WarehouseRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.storage')} />

          <Badge>{0}</Badge>
        </ListItem>
        <Divider sx={{ backgroundColor: 'primary.lighter' }} /> */}

        {/* <ListItem          
          component={Link}
          to={'/marketing'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <EventNoteRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Marketing'} />
          <Badge>{''}</Badge>
        </ListItem>
       
        <Divider sx={{ backgroundColor: 'primary.lighter' }} /> */}

        <ListItem
          component={Link}
          to={'/BillingView'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <PriceChangeRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.billing')} />

          <Badge>{0}</Badge>
        </ListItem>

        {/*  <ListItem
          component={Link}
          to={'/finance'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <AutoGraphRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.finance')} />

          <Badge>{0}</Badge>
        </ListItem> */}

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem
          component={Link}
          to={'/feedback'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.feedback')} />

          <Badge>{0}</Badge>
        </ListItem>

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem component={Link} to={'/setup'} sx={{ color: 'primary.text' }}>
          <ListItemIcon>
            <SettingsRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.settings')} />

          <Badge>{''}</Badge>
        </ListItem>

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem
          component={Link}
          to={'/modules'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <ApiIcon />
          </ListItemIcon>
          <ListItemText primary={t('Settings.SetupNav.Modules')} />

          <Badge>{'1'}</Badge>
        </ListItem>
        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem component={Link} to={'/test'} sx={{ color: 'primary.text' }}>
          <ListItemIcon>
            <EventNoteRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Test'} />
          <Badge>{timeLeft}</Badge>
        </ListItem>

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        {/* Button to manually trigger the API call */}
        <ListItem sx={{ color: 'primary.text', cursor: 'pointer' }}>
          <ListItemIcon>
            <ApiIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Button variant='contained' onClick={handleToggleCountdown}>
                {isRunning ? 'Stop Emails' : 'Get Emails Now'}
              </Button>
            }
          />
          <Badge>{emailsQty}</Badge>
        </ListItem>

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />
      </List>
    </Paper>
  );
};
