import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { pdfStyle } from './PDFStyle';
import dayjs from 'dayjs';
import { BillingCompanyViewModel } from 'pages/Billing/BillingConfig';
import { formatDate } from 'functions/misc';
import {
  ITicketPostBillingViewModel,
  ITicketPostViewModel,
} from 'Interfaces/ITicketConfig';
import { useTranslation } from 'react-i18next';

// Define the data type for the prop
interface Props {
  data: BillingCompanyViewModel;
}

const totalTime = (billing: ITicketPostBillingViewModel) => {
  let totalMinutes = 0;

  if (billing) {
    // Summerer opp total tid i minutter og runder av for å unngå desimaltall
    totalMinutes = Math.round(
      (billing.workHours || 0) * 60 +
        (billing.eveningHours || 0) * 60 +
        (billing.midDayHours || 0) * 60 +
        (billing.nightHours || 0) * 60
    );
  }

  // Konverterer total minutter til timer og minutter
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Formaterer som hh:mm med to sifre
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};

const totalValue = (billing: ITicketPostBillingViewModel) => {
  let totalValue = 0;

  if (billing) {
    totalValue =
      (billing.nightBillableValue || 0) +
      (billing.workBillableValue || 0) +
      (billing.midDayBillableValue || 0) +
      (billing.eveningBillableValue || 0);
  }

  return totalValue.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const totalValueDecimal = (billing: ITicketPostBillingViewModel) => {
  let totalValue = 0;

  if (billing) {
    totalValue =
      (billing.nightBillableValue || 0) +
      (billing.workBillableValue || 0) +
      (billing.midDayBillableValue || 0) +
      (billing.eveningBillableValue || 0);
  }

  return totalValue;
};

// Create Document Component
export const PDFSupportView = ({ data }: Props) => {
  const { t } = useTranslation();

  const ticketBillingList = Array.isArray(data.ticketBillingList)
    ? data.ticketBillingList
    : [];
  const totalSupportValue = ticketBillingList.reduce((acc, ticket) => {
    let ticketTotal = 0;
    if (ticket.fixedPrice > 0) {
      ticketTotal = ticket.fixedPrice;
    } else if (ticket.maxPrice > 0) {
      ticketTotal = Math.min(
        ticket.maxPrice,
        ticket.fixedHourlyRate * ticket.fixedTime
      );
    } else {
      ticketTotal = (
        Array.isArray(ticket.ticketPosts) ? ticket.ticketPosts : []
      ).reduce(
        (postAcc, post) =>
          postAcc +
          (totalValueDecimal(
            post.ticketPostBilling as ITicketPostBillingViewModel
          ) || 0),
        0
      );
    }
    ticketTotal =
      ticketTotal +
      (Array.isArray(ticket.consumableProducts)
        ? ticket.consumableProducts
        : []
      ).reduce((postAcc, post) => postAcc + (post.retailPrice || 0), 0);
    return acc + ticketTotal;
  }, 0);
  console.log('totalSupportValue: ', totalSupportValue);

  return (
    <>
      <View style={pdfStyle.column100}>
        <Text style={pdfStyle.textLeft}>{'SUPPORT'}</Text>
      </View>
      <View style={pdfStyle.divider}></View>

      {/* Rows for each ticket's information */}
      {data.ticketBillingList.map((ticket, index) => {
        const ticketTotal =
          ticket.fixedPrice > 0
            ? ticket.fixedPrice
            : ticket.maxPrice > 0
            ? Math.min(
                ticket.maxPrice,
                ticket.fixedHourlyRate * ticket.fixedTime
              )
            : ticket.ticketPosts.reduce(
                (sum: number, post: ITicketPostViewModel) =>
                  sum +
                  totalValueDecimal(
                    post.ticketPostBilling as ITicketPostBillingViewModel
                  ),
                0
              );

        const ticketDate = dayjs(ticket.created).format('DD.MM.YYYY');

        return (
          <View key={index}>
            <View key={index} style={pdfStyle.space}></View>
            <View style={pdfStyle.space}></View>
            {/* HEADER TICKETNO */}
            <View style={pdfStyle.column100}>
              <Text style={pdfStyle.textLeft}>{ticket.ticketNo}</Text>
            </View>
            <View style={pdfStyle.ticketHeader}>
              <View style={pdfStyle.rowView}>
                {/* HEADER DATE */}
                <View style={pdfStyle.column15}>
                  <Text style={pdfStyle.textLeft}>{ticketDate}</Text>
                </View>
                {/* HEADER TITLE */}
                <View style={pdfStyle.column65}>
                  <Text style={pdfStyle.textLeft}>{ticket.title}</Text>
                </View>
                {/* HEADER TOTAL */}
                <View style={pdfStyle.column20}>
                  <Text style={pdfStyle.textRightBold}>
                    {ticketTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                </View>
              </View>
            </View>

            {/* Divider */}
            <View style={pdfStyle.divider}></View>

            {/* Rows for each post */}
            <View>
              {ticket.fixedPrice > 0 ? (
                <View key={'fixedPrice'}>
                  <View style={pdfStyle.rowView}>
                    <View style={pdfStyle.column15}>
                      <Text style={pdfStyle.textLeft}>
                        <Text style={pdfStyle.textLeft}>
                          {dayjs(ticket.created).format('DD.MM.YYYY')}
                        </Text>
                      </Text>
                    </View>
                    <View style={pdfStyle.column60}>
                      <Text style={pdfStyle.textLeft}>
                        Info: {t('Billing.billingView.fixedPrice')}
                      </Text>
                    </View>
                    <View style={pdfStyle.column25}>
                      <Text style={pdfStyle.textRight}>
                        {ticket.fixedPrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    </View>
                  </View>
                  <View key={index} style={pdfStyle.divider}></View>
                  <View key={index} style={pdfStyle.space}></View>
                </View>
              ) : (
                ticket.ticketPosts.map((post, postIndex) => (
                  <View key={postIndex}>
                    <View style={pdfStyle.rowView}>
                      <View style={pdfStyle.column15}>
                        <Text style={pdfStyle.textLeft}>
                          <Text style={pdfStyle.textLeft}>
                            {dayjs(post.startTime).format('DD.MM.YYYY')}
                          </Text>
                        </Text>
                      </View>
                      <View style={pdfStyle.column30}>
                        <Text style={pdfStyle.textLeft}>
                          CCS: {post.author?.name || 'Unknown'}
                        </Text>
                      </View>
                      <View style={pdfStyle.column30}>
                        <Text style={pdfStyle.textLeft}>
                          Contact: {post.receiver?.name || 'Unknown'}
                        </Text>
                      </View>
                      <View style={pdfStyle.column25}>
                        <Text style={pdfStyle.textLeft}></Text>
                      </View>
                    </View>
                    <View style={pdfStyle.rowView}>
                      <View style={pdfStyle.column15}>
                        <Text style={pdfStyle.textLeft}>
                          <Text style={pdfStyle.textLeft}></Text>
                        </Text>
                      </View>
                      <View style={pdfStyle.column30}>
                        <Text style={pdfStyle.textLeft}>
                          Time:{' '}
                          {totalTime(
                            post.ticketPostBilling as ITicketPostBillingViewModel
                          )}
                        </Text>
                      </View>
                      <View style={pdfStyle.column30}>
                        <Text style={pdfStyle.textLeft}>
                          Rate:{' '}
                          {post.workRole?.inHouseHourlyRate.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </Text>
                      </View>
                      <View style={pdfStyle.column25}>
                        <Text style={pdfStyle.textRight}>
                          {totalValue(
                            post.ticketPostBilling as ITicketPostBillingViewModel
                          )}
                        </Text>
                      </View>
                      *
                    </View>
                    <View key={index} style={pdfStyle.divider}></View>
                    <View key={index} style={pdfStyle.space}></View>
                  </View>
                ))
              )}
            </View>
            {/* Rows for each consumable */}
            <View>
              {ticket.consumableProducts.map((post, postIndex) => (
                <View key={postIndex}>
                  <View style={pdfStyle.rowView}>
                    <View style={pdfStyle.column15}>
                      <Text style={pdfStyle.textLeft}>
                        <Text style={pdfStyle.textLeft}>
                          {dayjs(post.created).format('DD.MM.YYYY')}
                        </Text>
                      </Text>
                    </View>
                    <View style={pdfStyle.column60}>
                      <Text style={pdfStyle.textLeft}>
                        Service: {post.name || 'Unknown'}
                      </Text>
                    </View>
                    <View style={pdfStyle.column25}>
                      <Text style={pdfStyle.textLeft}></Text>
                    </View>
                  </View>
                  <View style={pdfStyle.rowView}>
                    <View style={pdfStyle.column15}></View>
                    <View style={pdfStyle.column60}></View>
                    <View style={pdfStyle.column25}>
                      <Text style={pdfStyle.textRight}>
                        {post.retailPrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    </View>
                    *
                  </View>
                  <View key={index} style={pdfStyle.divider}></View>
                  <View key={index} style={pdfStyle.space}></View>
                </View>
              ))}
            </View>
            {/* Divider */}
          </View>
        );
      })}
      {/* Total Support Value */}
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'Total Support'}</Text>
        </View>
        <View style={pdfStyle.column20}>
          <Text style={pdfStyle.textRight}>
            {totalSupportValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>
    </>
  );
};
