import {
  Text,
  View,
} from '@react-pdf/renderer';
import React from 'react';
import { pdfStyle } from './PDFStyle';
import dayjs from 'dayjs';
import { BillingCompanyViewModel } from 'pages/Billing/BillingConfig';


// Define the data type for the prop
interface Props {
  data: BillingCompanyViewModel;
}

export const PDFHeaderView = ({ data }: Props) => (
  <View style={pdfStyle.headerView}>
    <View style={pdfStyle.headerLeftView}>
      <Text style={pdfStyle.headerText}>{data.company?.name}</Text>
      <Text style={pdfStyle.subText}>Org.No: {data.company?.organizationNumber}</Text>
    </View>

    <View style={pdfStyle.headerRightView}>
      <Text style={pdfStyle.text}>Invoice No: {data.invoiceNo}</Text>
      <Text style={pdfStyle.text}>{dayjs().format('MMMM DD, YYYY')}</Text>
    </View>
    <View style={pdfStyle.avsnitt} />
  </View>
);
