import * as React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { EnumPlacement, MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import { useTranslation } from 'react-i18next';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';

export type CompanyProductProps = {
  companyProduct: ICompanyProductViewModel;
};

export const SingleCompanyProductView = ({
  companyProduct,
}: CompanyProductProps) => {
  const { t } = useTranslation();
  console.log('cccv product', companyProduct);
  return (
    <Grid
      container
      flexGrow={1}
      direction={'row'}
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(
          companyProduct.product?.productType?.name || 'unknown'
        ),
        backgroundColor: 'primary.main',
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
        height: 84,
      }}
    >
      <Grid
        item
        sx={{
          backgroundColor: 'primary.light',
          width: 84,
          height: 84,
        }}
      >
        <img
          src={
            companyProduct?.product?.url
              ? companyProduct?.product?.url
              : MissingPhoto.PRODUCT_URL
          }
          style={{
            borderRadius: 3,
            objectFit: 'scale-down',
            width: 80,
            height: 80,
          }}
          alt={'previewUrl'}
        />
      </Grid>

      {/* COLUMN 1*/}
      <Grid item xs={5.5} sx={{ pl: 0.5 }}>
        <Typography
          sx={{
            letterSpacing: 1,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            fontSize: 18,
          }}
        >
          {companyProduct?.product?.name || ' '} 
        </Typography>
        <Stack display={'flex'} direction={'row'} spacing={0}>
          <Grid item xs={2}
            sx={{
              width: 56,
              height: 56,
            }}
          >
            <img
              src={
                companyProduct?.owner?.url
                  ? companyProduct?.owner?.url
                  : MissingPhoto.PRODUCT_URL
              }
              style={{
                borderRadius: 30,
                objectFit: 'cover',
                width: 56,
                height: 56,
              }}
              alt={'previewUrl'}
            />
          </Grid>
          <Grid item xs={10}  sx={{  pr:1, pl:.5, }}>
          <Typography
          sx={{
            letterSpacing: 1,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            fontSize: 12,
          }}
        >
          {companyProduct?.owner?.name || ' '} 
        </Typography>
        <Typography
          sx={{
            letterSpacing: 1,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'normal',
            fontSize: 12,
          }}
        >
          {companyProduct?.owner?.phone || ' '} 
        </Typography>
        <Typography
          sx={{
            letterSpacing: 1,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'normal',
            fontSize: 12,
          }}
        >
          {companyProduct?.owner?.email || ' '} 
        </Typography>
          </Grid>
        </Stack>
      </Grid>

      {/* COLUMN 2*/}

      <Grid item xs={3}>
        <Typography
          sx={{
            minHeight: 80/3,
            maxHeight: 80/3,
            letterSpacing: 1,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            fontSize: 14,
          }}
        >
          {companyProduct?.serviceTag || 
            `${t('company.singleCompanyProductView.noServiceTag')}`}
        </Typography>
        <Typography
          sx={{
            minHeight: 80/3,
            maxHeight: 80/3,
            letterSpacing: 1,
            textAlign: 'left',
            fontSize: 14,
          }}
        >
          {companyProduct?.product?.productCategory ||  
            `${t('company.singleCompanyProductView.noCategory')}`}
        </Typography>
        <Typography
          sx={{
            minHeight: 80/3,
            maxHeight: 80/3,
            letterSpacing: 1,
            textAlign: 'left',
            fontSize: 14,
          }}
        >
          {companyProduct.product?.productType?.name ||
            `${t('company.singleCompanyProductView.noType')}`}
        </Typography>
      </Grid>

      {/* COLUMN 3*/}

      <Grid item flexGrow={1} sx={{pr:1,}}>
        <Typography
          noWrap
          sx={{
            minHeight: 80/3,
            maxHeight: 80/3,
            letterSpacing: 0,
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'normal',
            textTransform: 'capitalize',
            fontSize: 14,
          }}
        >         
          {companyProduct?.product?.brand?.brandName ||
            `${t('company.singleCompanyProductView.noBrand')}`}
           
        </Typography>
        <Typography
          sx={{
            mt:-.5,
            minHeight: 80/3,
            maxHeight: 80/3,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            alignContent: 'stretch',
            fontWeight: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textTransform: 'capitalize',
            color: 'primary.text',
            fontSize: 14,
          }}
        >
          {(companyProduct?.retailPrice ?? 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
            ' * ' +
            companyProduct?.quantity}
        </Typography>
        <Typography
          sx={{
            minHeight: 80/3,
            maxHeight: 80/3,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            alignContent: 'stretch',
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textTransform: 'capitalize',
            color: 'secondary.text',
            letterSpacing: 2,
            fontSize: 16,
          }}
        >
          {((companyProduct?.retailPrice ?? 0) * companyProduct?.quantity).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
