import {
  ITicketPostInterface,
  ITicketPostAddViewModel,
} from 'Interfaces/ITicketConfig';
import { ConvertToAttachment } from './ConvertToAttachment';
import { GetWorkRole } from './GetWorkRole';
import dayjs from 'dayjs';
import { calculateTicketPostBilling } from './calculateTicketPostBilling';

export const ConvertToTicketPost = (
  ticketPost: ITicketPostInterface,
  user: any
) => {
  console.log('calculateTicketPostBilling: ConvertToTicketPost', ticketPost);

  let _ticketPost: ITicketPostAddViewModel = {
    ticketId: ticketPost.ticket.id,
    status: ticketPost.ticketPost.status,
    startTime: dayjs(ticketPost.ticketPost.startTime), // Send local time, no UTC conversion
    endTime: dayjs(ticketPost.ticketPost.endTime), // Send local time, no UTC conversion
    startDeduction: null, // Send local time
    endDeduction: null, // Send local time
    isBillable: ticketPost.ticketPost.isBillable,
    isOvertime: ticketPost.ticketPost.isOvertime, // Send Overtime, no UTC conversion conversionoverTime,
    isBilled: false,
    internal: ticketPost.ticketPost.internal,
    isInHouse: ticketPost.ticketPost.isInHouse,
    isEmailSent: ticketPost.ticketPost.isEmailSent,
    direction: 1,
    authorId: user.id,
    receiverId: ticketPost.ticket?.contact.id,
    message: ticketPost.ticketPost.message || '',
    attachments: ticketPost.ticketPost.attachments
      ? ConvertToAttachment(ticketPost.ticketPost.attachments)
      : null,
    workRoleId: ticketPost.ticketPost.workRole?.id
      ? ticketPost.ticketPost.workRole?.id
      : null,
    ticketPostBilling: calculateTicketPostBilling(ticketPost),
    isCCSent: ticketPost.ticketPost.isCCSent,
    isApp: false,
  };
  console.log(
    'calculateTicketPostBilling: ConvertToTicketPost ++',
    _ticketPost
  );

  return _ticketPost;
};
