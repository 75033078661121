import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import {
  EnumBillingType,
  EnumBoard,
  EnumJobType,
  EnumPriority,
  EnumScheduleType,
  EnumSubButtonInterface,
  EnumSupportBilling,
  EnumTicketStatus,
  HeaderButtonInterface,
  IDeliveryAddressViewModel,
  IMobileAnswersViewModel,
  IScheduleViewModel,
  SubButtonInterface,
} from 'Interfaces/IGlobalTypeConfig';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import JoinFullRoundedIcon from '@mui/icons-material/JoinFullRounded';
import ChecklistRtlRoundedIcon from '@mui/icons-material/ChecklistRtlRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import {
  IEmployeeViewModel,
  ITicketPostInterface,
  ITicketCompanyViewModel,
  ITicketDetailsInterface,
  ITicketPostViewModel,
  ITicketViewModel,
} from 'Interfaces/ITicketConfig';

import dayjs from 'dayjs';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { returnGuidEmpty } from 'functions/GuidChecks';
import { IProductViewModel } from 'Interfaces/IProductConfig';

export const TicketHeaderButtonDetails = (
  linkId1: string | null,
  linkId2: string | null
): HeaderButtonInterface[] => [
  {
    name: 'Ticket.TicketStyleConfig.Home',
    component: { Link },
    to: `/tickets/${linkId1}/communications`,
    icon: HomeRoundedIcon,
  },

  {
    name: 'Ticket.TicketStyleConfig.economy',
    component: { Link },
    to: `/tickets/${linkId1}/economy`,
    icon: AttachMoneyRoundedIcon,
  },
  {
    name: 'Ticket.TicketStyleConfig.products',
    component: { Link },
    to: `/tickets/${linkId1}/products`,
    icon: DevicesRoundedIcon,
  },

  {
    name: 'Ticket.TicketStyleConfig.productHistory',
    component: { Link },
    to: `/tickets/${linkId1}/productshistory`,
    icon: DevicesOtherRoundedIcon,
  },

  {
    name: 'Ticket.TicketStyleConfig.resolution',
    component: { Link },
    to: `/tickets/${linkId1}/resolution`,
    icon: ReceiptRoundedIcon,
  },

  {
    name: 'Ticket.TicketStyleConfig.attachments',
    component: { Link },
    to: `/tickets/${linkId1}/attachments`,
    icon: AttachFileRoundedIcon,
  },
  {
    name: 'Ticket.TicketStyleConfig.merge',
    component: { Link },
    to: `/tickets/${linkId1}/merge`,
    icon: JoinFullRoundedIcon,
  },
];

export const TicketHeaderButtonDetail = (): SubButtonInterface[] => [
  {
    name: 'Ticket.TicketStyleConfig.details',
    type: EnumSubButtonInterface.Details,
    icon: DashboardIcon,
  },
  {
    name: 'Ticket.TicketStyleConfig.Communication',
    type: EnumSubButtonInterface.Communication,
    icon: SmsRoundedIcon,
  },
  {
    name: 'Ticket.TicketStyleConfig.CheckList',
    type: EnumSubButtonInterface.CheckList,
    icon: ChecklistRtlRoundedIcon,
  },
  {
    name: 'Ticket.TicketStyleConfig.NewPost',
    type: EnumSubButtonInterface.NewPost,
    icon: AddBoxRoundedIcon,
  },
];

export const initialState: ITicketDetailsInterface = {
  id: 'new',
  ticketNo: 'new',
  board: EnumBoard.Support,
  priority: EnumPriority.NORMAL,
  status: EnumTicketStatus.New,
  companyId: 'new',
  company: {} as ITicketCompanyViewModel,
  contactId: 'new',
  contact: {} as IEmployeeViewModel,
  creatorId: 'new',
  creator: {} as unknown as IEmployeeViewModel,
  responsibleId: null,
  responsible: {} as unknown as IEmployeeViewModel,
  shippingCost: 0,
  deliveryAddress: {} as IDeliveryAddressViewModel,
  scheduleTime: {
    id: '0',
    scheduleType: EnumScheduleType.None,
    deliveryDate: dayjs(new Date()),
    startDate: dayjs(new Date()),
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  },
  jobType: EnumJobType.Support,
  supportType: {} as IMobileAnswersViewModel,
  errorDescriptionId: '',
  errorDescription: {} as IMobileAnswersViewModel,
  companyProductWithErrorId: '',
  companyProductWithError: {} as ICompanyProductViewModel,
  technicians: null,
  comment: '',
  ccEmail: '',
  fixedHourlyRate: 0,
  fixedTime: 0,
  fixedPrice: 0,
  maxTime: 0,
  maxPrice: 0,
  productBilling: true,
  updated: dayjs(new Date()),
  billing: EnumBillingType.SystemPeriod,
  title: '',
  companyProducts: [] as ICompanyProductViewModel[],
  techniciansId: [] as string[],
  storageProducts: [] as ICompanyProductViewModel[],
  productCatalog: [] as ICompanyProductViewModel[],
  addressList: [] as IDeliveryAddressViewModel[],
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  isDirty: false,
  supportBilling: EnumSupportBilling.BillOnCompletion,
};

export const initialTicketPostCommunicationData: ITicketPostInterface = {
  ticketPosts: [],
  ticketPost: {} as unknown as ITicketPostViewModel,
  scheduleTime: {
    id: returnGuidEmpty(),
    deliveryDate: dayjs(new Date()),
    scheduleType: 0,
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  } as IScheduleViewModel,
  ticket: {} as unknown as ITicketViewModel,
  workRoles: [] as IWorkRoleViewModel[],
  totalTime: '00:00',
  totalValue: 0,
  isTotalValueOpen: false,
  isTotalTimeOpen: false,
  selectedDocuments: null,
  rates: [],
  isShowOverTime: false,
  isEditable: true,
  isEmailAllowed: true,
  page: EnumSubButtonInterface.Communication,
  ticketPostBilling: null,
};

export interface ITicketProductViewModel {
  ticketId: string;
  ticket: ITicketViewModel;
  companyProductId: string;
  companyProduct: ICompanyProductViewModel;
  productId: string;
  product: IProductViewModel;
  isConsumable: boolean;
  isBilled: boolean;
}
