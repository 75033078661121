import * as React from 'react';
import { useEffect, useState } from 'react';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import { Grid, Avatar, Stack, Typography, Paper } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import { useTranslation } from 'react-i18next';

export const SingleEmployeeView = (props: any) => {
  const { t } = useTranslation();
  const { employee, gfx, height } = props;
  const dynamicFontSize = height ? `${height / 5}px` : '16px'; // Example calculation
  const dynamicImageSize = height ? `${height}px` : '80px';
  return (
    <Paper
      variant='elevation3'
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(`${employee?.department?.name}`),
        backgroundColor: employee.isEmailFoundInBluu ? 'success.back' : 'rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100%',
        maxHeight: !height ? 80 : height,
        cursor: 'pointer',
      }}
    >
      <Grid container direction={'row'}>
        <Grid
          item
          sx={{
            mx: 'auto',
            width: 80,
            height: 80,
            backgroundColor: 'primary.light',
          }}
        >
          <img
            src={employee?.url || MissingPhoto.EMPLOYEE_URL}
            style={{
              width: dynamicImageSize,
              height: dynamicImageSize,
              borderRadius: 3,
              objectFit: 'contain',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            }}
            alt={'previewUrl'}
          />
        </Grid>

        <Grid
          item
          xs={9}
          sx={{
            pl: 1,
          }}
        >
          <Stack direction={'column'}>
            <Typography
              sx={{
                letterSpacing: 2,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: dynamicFontSize,
              }}
            >
              {employee?.name}
            </Typography>
            <Typography
              sx={{
                height: 24,
                letterSpacing: 3,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
              {employee?.phone}
            </Typography>
            <Typography
                sx={{
                  height: 24,
                  letterSpacing: 3,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 16,
                }}
              >
                {employee?.email}
              </Typography>

          </Stack>
        </Grid>
        <Grid
          item
        
          flexGrow={1}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            p: 0,
            mb: 1,
          }}
        >
          <Stack direction={'column'} sx={{ p: 0, mt: 0 }}>
            <Stack direction={'row-reverse'} sx={{ p: 0, pt: 1 }}>
              {!!gfx ? gfx : <></>}
              <Typography
                sx={{
                  pr: 1,
                  letterSpacing: 2,
                  textAlign: 'right',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 16,
                  color: 'secondary.text',
                }}
              >
                {employee?.primaryContact ? <StarTwoToneIcon /> : ''}
              </Typography>
            </Stack>
            <Typography
              sx={{
                pr: 1,
                mt: -1,
                height: 24,
                letterSpacing: 3,
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
             {t("Setup.SingleEmployeeView.bugdget")}
            </Typography>
            <Typography
              sx={{
                pr: 1,
                height: 24,
                letterSpacing: 2,
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 16,
                color: 'secondary.text',
              }}
            >
              {employee?.budget?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
