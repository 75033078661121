import { url } from 'inspector';
import { EnumOrderStatus } from 'Interfaces/IGlobalTypeConfig';
import { keys } from 'lodash';
import { ICloudFactoryProductsViewModel } from 'pages/Modules/CloudFactory/Converts/CludFactoryConfig';

export const rq = {
  employeeDrawerProducts: (employeeId: string, isEnabled: boolean) => ({
    url: `/employee/${employeeId}/drawerProducts`,
    keys: ['drawerProducts', employeeId],
    cacheQuery: { keys: ['drawerProducts', employeeId] },
    enabled: isEnabled,
  }),
  getEmail: (isEnabled: boolean) => ({
    url: `/Setup/GetEmails`,
    keys: ['GetEmails'],
    cacheQuery: { keys: ['GetEmails'] },
    enabled: isEnabled,
  }),
  getBudgetData: (year: number, isEnabled: boolean) => ({
    url: `/Economy/Budget/${year}`,
    keys: ['Budget', year],
    cacheQuery: { keys: ['Budget', year] },
    enabled: isEnabled,
  }),
  getYearData: (
    companyId: string,
    startYear: number,
    endYear: number,
    detailLevel: number,
    isEnabled: boolean
  ) => ({
    url: `/Economy/Total/${companyId}/StartYear/${startYear}/EndYear/${endYear}/detailLevel/${detailLevel}`,
    keys: ['TotalYear', startYear, endYear],
    cacheQuery: { keys: ['TotalYear', startYear, endYear] },
    enabled: isEnabled,
  }),
  emails: (isEnabled: boolean) => ({
    url: `/Email/Shared`,
    keys: ['Email/Shared'],
    cacheQuery: { keys: ['Email/Shared'] },
    enabled: isEnabled,
  }),
  objectList: (
    companyId: string,
    employeeId: string,
    departmentId: string,
    resourceId: string,
    isEnabled: boolean
  ) => ({
    url: `/Setup/ObjectList/${companyId}/${employeeId}/${departmentId}/${resourceId}`,
    keys: ['ObjectList', companyId],
    cacheQuery: { keys: ['ObjectList', companyId] },
    enabled: isEnabled,
  }),
  getPayedBillingPosts: (isPayed: boolean) => ({
    url: `/Billing/Invoice/${isPayed}`,
    keys: ['isPayed'],
    cacheQuery: { keys: ['isPayed'] },
    isPayed: isPayed,
  }),
  getUnassignedCompanyProducts: (companyId: string, isEnabled: boolean) => ({
    url: `/CompanyProduct/${companyId}/Unassigned`,
    keys: ['unassignedCompanyProduct'],
    cacheQuery: { keys: ['unassignedCompanyProduct'] },
    enabled: isEnabled,
  }),
  getAvailableCompanyProducts: (companyId: string, isEnabled: boolean) => ({
    url: `/CompanyProduct/${companyId}/Available`,
    keys: ['availableCompanyProduct'],
    cacheQuery: { keys: ['availableCompanyProduct'] },
    enabled: isEnabled,
  }),
  getAllBrands: (brandState: number, isEnabled: boolean) => ({
    url: `/Setup/Brand/${brandState}`,
    keys: ['Brand'],
    cacheQuery: { keys: ['Brand'] },
    enabled: isEnabled,
  }),
  allEmployees: (isEnabled: boolean) => ({
    url: `/employee/All`,
    keys: ['allEmployees', isEnabled],
    cacheQuery: { keys: ['allEmployees', isEnabled] },
    enabled: isEnabled,
  }),

  feedbackAdmin: (page: number, quantity: number, isEnabled: boolean) => ({
    url: `/Feedback/Page/${page}/Quantity/${quantity}`,
    keys: ['FeedbackAdmin'],
    cacheQuery: { keys: ['FeedbackAdmin'] },
    enabled: isEnabled,
  }),
  productTypes: (isEnabled: boolean) => ({
    url: `/ProductType`,
    keys: ['ProductType', isEnabled],
    cacheQuery: { keys: ['ProductType', isEnabled] },
    enabled: isEnabled,
  }),
  mobileAutoAnswers: (productTypeid: string, isEnabled: boolean) => ({
    url: `/Setup/MobileAutoAnswers/${productTypeid}`,
    keys: ['MobileAutoAnswers', productTypeid],
    cacheQuery: { keys: ['MobileAutoAnswers', productTypeid] },
    enabled: isEnabled,
  }),
  settings: (isEnabled: boolean) => ({
    url: `/Setup/Settings`,
    keys: ['Settings', isEnabled],
    cacheQuery: { keys: ['Settings', isEnabled] },
    enabled: isEnabled,
  }),
  billing: (isEnabled: boolean) => ({
    url: `/Billing`,
    keys: ['Billing', isEnabled],
    cacheQuery: { keys: ['Billing', isEnabled] },
    enabled: isEnabled,
  }),
  rates: (isEnabled: boolean) => ({
    url: `/Company/Rates`,
    keys: ['Rates', isEnabled],
    cacheQuery: { keys: ['Rates', isEnabled] },
    enabled: isEnabled,
  }),
  appOwner: (isEnabled: boolean) => ({
    url: `/Company/Owner`,
    keys: ['appOwner', isEnabled],
    cacheQuery: { keys: ['appOwner', isEnabled] },
    enabled: isEnabled,
  }),
  appEmployees: (companyId: string, isEnabled: boolean) => ({
    url: `/company/${companyId}/employees`,
    keys: ['appEmployees', companyId],
    cacheQuery: { keys: ['appEmployees', companyId] },
    enabled: isEnabled,
  }),
  appDepartments: (companyId: string, isEnabled: boolean) => ({
    url: `/company/${companyId}/departments`,
    keys: ['appDepartments', companyId],
    cacheQuery: { keys: ['appDepartments', companyId] },
    enabled: isEnabled,
  }),
  productPackageProducts: (packageId: string, isEnabled: boolean) => ({
    url: `/productPackage/${packageId}/products`,
    keys: ['productPackageProducts', packageId],
    cacheQuery: { keys: ['productPackageProducts', packageId] },
    enabled: isEnabled,
  }),
  storageProducts: (isEnabled: boolean) => ({
    url: '/StorageProduct/All',
    keys: ['storageProduct'],
    cacheQuery: { keys: ['storageProduct'] },
    enabled: isEnabled,
  }),
  products: (isEnabled: boolean) => ({
    url: '/product',
    keys: ['products'],
    cacheQuery: { keys: ['products'] },
    enabled: isEnabled,
  }),
  productByName: (name: string, isEnabled: boolean) => ({
    url: `/Module/CloudFactory/iBluuProduct/${name}`,
    keys: ['iBluuProduct'],
    cacheQuery: { keys: ['iBluuProduct'] },
    enabled: isEnabled,
  }),
  agreement: (companyId: string, isEnabled: boolean) => ({
    url: `/Agreement/${companyId}`,
    keys: ['Agreement'],
    cacheQuery: { keys: ['Agreement'] },
    enabled: isEnabled,
  }),
  agreementPeriod: (companyId: string, period: number, isEnabled: boolean) => ({
    url: `/Agreement/${companyId}/agreement/${period}`,
    keys: ['AgreementPeriod'],
    cacheQuery: { keys: ['AgreementPeriod'] },
    enabled: isEnabled,
  }),
  economyPeriod: (
    companyId: string,
    fromYear: number,
    toYear: number,
    isEnabled: boolean
  ) => ({
    url: `/Economy/${companyId}/${fromYear}/${toYear}`,
    keys: ['Economy'],
    cacheQuery: { keys: ['Economy'] },
    enabled: isEnabled,
  }),
  companyProduct: (isEnabled: boolean) => ({
    url: `/CompanyProduct`,
    keys: ['CompanyProduct'],
    cacheQuery: { keys: ['CompanyProduct'] },
    enabled: isEnabled,
  }),
  companyProducts: (companyId: string, isEnabled: boolean) => ({
    url: `/CompanyProduct/${companyId}/All`,
    keys: ['CompanyProducts'],
    cacheQuery: { keys: ['CompanyProducts'] },
    enabled: isEnabled,
  }),
  commonCompanyProducts: (companyId: any, isEnabled: boolean) => ({
    url: `/CompanyProduct/${companyId}/Common`,
    keys: ['CommonCompanyProducts'],
    cacheQuery: { keys: ['CommonCompanyProducts'] },
    enabled: isEnabled,
  }),
  consumableCompanyProduct: (
    companyId: string,
    productId: string,
    ticketId: string,
    isEnabled: boolean
  ) => ({
    url: `Product/ConsumableCompanyProduct/companyId/${companyId}/ticketId/${ticketId}/productId/${productId}`, //  [Route("ConsumableCompanyProduct/companyId/{companyId}/ticketId/{ticketId}/productId/{producId}")]
    keys: ['availableCompanyProduct'],
    cacheQuery: { keys: ['availableCompanyProduct'] },
    enabled: isEnabled,
  }),
  consumables: (isEnabled: boolean) => ({
    url: `product/Consumables`,
    keys: ['consumables'],
    cacheQuery: { keys: ['consumables'] },
    enabled: isEnabled,
  }),
  employeeCompanyProducts: (employeeId: any, isEnabled: boolean) => ({
    url: `/CompanyProduct/${employeeId}/Employee`,
    keys: ['EmployeeCompanyProducts'],
    cacheQuery: { keys: ['EmployeeCompanyProducts'] },
    enabled: isEnabled,
  }),
  CompanyTickets: (companyId: string, isEnabled: boolean) => ({
    url: `/Ticket/Company/${companyId}`,
    keys: ['CompanyTickets', companyId],
    cacheQuery: { keys: ['CompanyTickets', companyId] },
    enabled: isEnabled,
  }),
  product: (productId: string, isEnabled: boolean) => ({
    url: `/product/${productId}`,
    keys: ['product', productId],
    cacheQuery: { id: productId, keys: rq.products },
    enabled: isEnabled,
  }),
  productTypeFeatures: (productTypeId: string, isEnabled: boolean) => ({
    url: `/producttype/${productTypeId}/productfeatures`,
    keys: ['productTypeFeatures', productTypeId],
    cacheQuery: { keys: ['productTypeFeatures', productTypeId] },
    enabled: isEnabled,
  }),
  categories: {
    url: '/productcategory',
    keys: ['categories'],
    cacheQuery: { keys: ['categories'] },
  },
  category: (categoryId: string) => ({
    url: `/productcategory/${categoryId}`,
    keys: ['category', categoryId],
    cacheQuery: { id: categoryId, keys: rq.categories.keys },
  }),
  companies: (type: string, isEnabled: boolean) => ({
    url: `/company/${type}`,
    keys: ['companies', type],
    cacheQuery: { keys: ['companies', type] },
    enabled: isEnabled,
  }),
  company: (companyId: any, isEnabled: boolean) => ({
    url: `/company/${companyId}`,
    keys: ['company', companyId],
    // XXX hard coded type for now
    cacheQuery: { id: companyId, keys: rq.companies('2', true).keys },
    enabled: isEnabled, //
  }),
  departments: (companyId: string, isEnabled: boolean) => ({
    url: `/company/${companyId}/departments`,
    keys: ['departments', companyId],
    cacheQuery: { keys: ['departments', companyId] },
    enabled: isEnabled, // !(!!companyId && companyId !== "new")
  }),
  department: (
    companyId: string,
    departmentId: string,
    isEnabled: boolean
  ) => ({
    url: `/department/${departmentId}`,
    keys: ['department', departmentId],
    cacheQuery: {
      id: departmentId,
      keys: rq.departments(companyId, isEnabled).keys,
    },
    enabled: isEnabled, // !(!!companyId && companyId !== "new"),
  }),
  resource: (resourceId: string, isEnabled: boolean) => ({
    url: `/resources/${resourceId}`,
    keys: ['resource', resourceId],
    cacheQuery: {
      id: resourceId,
      keys: rq.resources(resourceId, isEnabled).keys,
    },
    enabled: isEnabled, // !(!!companyId && companyId !== "new"),
  }),
  resources: (companyId: string, isEnabled: boolean) => ({
    url: `/resources/company/${companyId}`,
    keys: ['company', companyId],
    cacheQuery: {
      id: companyId,
      keys: rq.departments(companyId, isEnabled).keys,
    },
    enabled: isEnabled, // !(!!companyId && companyId !== "new"),
  }),
  resourceTypes: (isEnabled: boolean) => ({
    url: `/resources/resourceTypes`,
    keys: ['ResourceTypes'],
    cacheQuery: { keys: ['ResourceTypes'] },
    enabled: isEnabled,
  }),
  employees: (companyId: string, isEnabled: boolean) => ({
    url: `/employee/${companyId}/CompanyEmployees`,
    keys: ['employees', companyId],
    cacheQuery: { keys: ['employees', companyId] },
    enabled: isEnabled,
  }),
  orderEmployees: (companyId: string, isEnabled: boolean) => ({
    url: `/order/${companyId}/employees`,
    keys: ['orderEmployees', companyId],
    cacheQuery: { keys: ['orderEmployees', companyId] },
    enabled: isEnabled,
  }),
  employee: (companyId: string, employeeId: string, isEnabled: boolean) => ({
    url: `/employee/${employeeId}`,
    keys: ['employee', employeeId],
    cacheQuery: {
      id: employeeId,
      keys: rq.employees(companyId, isEnabled).keys,
    },
    enabled: isEnabled, // !(!!companyId && companyId !== "new"),
  }),
  employeeByEmail: (email: string, isEnabled: boolean) => ({
    url: `/employee/Email/${email}`,
    keys: ['employeeByEmail', email],
    cacheQuery: { keys: ['employeeByEmail', email] },
    enabled: isEnabled,
  }),
  employeeWithProducts: (
    companyId: string,
    employeeId: string,
    isEnabled: boolean
  ) => ({
    url: `/employee/${employeeId}/CompanyProducts`,
    keys: ['employee', employeeId],
    cacheQuery: { keys: ['employee', companyId] },
    //cacheQuery: { id: employeeId, keys: rq.employees(companyId).keys },
    enabled: isEnabled,
  }),
  orderPosts: (status: string, isEnabled: boolean) => ({
    url: `/Order/OrderPosts/${status}`,
    keys: ['orders', status],
    cacheQuery: { keys: ['orders', status] },
    enabled: isEnabled,
  }),
  packages: (companyId: string, isEnabled: boolean) => ({
    url: `/company/${companyId}/productPackages`,
    keys: ['packages', companyId],
    cacheQuery: { keys: ['packages', companyId] },
    enabled: isEnabled,
  }),
  package: (companyId: string, packageId: string, isEnabled: boolean) => ({
    url: `/productPackage/${packageId}`,
    keys: ['package', packageId],
    cacheQuery: { id: packageId, keys: rq.packages(companyId, isEnabled).keys },
    enabled: isEnabled,
  }),
  tickets: (boardNr: any, status: any, isEnabled: boolean) => ({
    url: `/ticket/Board/${boardNr}/Status/${status}`,
    keys: ['tickets', boardNr, status],
    cacheQuery: { keys: ['tickets', boardNr, status] },
    enabled: isEnabled,
  }),
  userTickets: (boardNr: any, id: string, isEnabled: boolean) => ({
    url: `/ticket/Board/${boardNr}/UserTickets/${id}`, //ticket/Board/{boardNr}/UserTickets/{id:guid}"
    keys: ['userTickets', boardNr, id],
    cacheQuery: { keys: ['userTickets', boardNr, id] },
    enabled: isEnabled,
  }),
  ticket: (ticketId: string, isEnabled: boolean, boardNr: any) => ({
    url: `/ticket/${ticketId}`,
    keys: ['ticket', ticketId],
    cacheQuery: {
      id: ticketId,
      keys: rq.tickets(ticketId, isEnabled, boardNr).keys,
    },
    enabled: isEnabled,
  }),
  userTicketsPeriod: (
    id: string,
    startDate: string,
    endDate: string,
    isEnabled: boolean
  ) => ({
    url: `/ticket/${id}/startDate/${startDate}/endDate/${endDate}`,
    keys: ['userTicketsPeriod', id, startDate],
    cacheQuery: { keys: ['userTicketsPeriod', id, startDate] },
    enabled: isEnabled,
  }),
  ticketPosts: (ticketId: string, isEnabled: boolean) => ({
    url: `/ticket/${ticketId}/TicketPosts`,
    keys: ['ticketPosts', ticketId],
    cacheQuery: { keys: ['posts', ticketId] },
    enabled: isEnabled,
  }),
  ticketPost: (ticketId: string, id: string) => ({
    url: `/ticket/${ticketId}/TicketPost/${id}`,
    keys: ['ticketPost', id],
    cacheQuery: { id: ticketId, keys: rq.ticketPosts(ticketId, true).keys },
    enabled: !(!!ticketId && ticketId !== 'new'),
  }),
  ticketProducts: (ticketId: string, isEnabled: boolean) => ({
    url: `/ticket/${ticketId}/TicketProducts`,
    keys: ['ticketProducts', ticketId],
    cacheQuery: { keys: ['ticketProducts', ticketId] },
    enabled: isEnabled,
  }),
  unmanagedPost: (isEnabled: boolean) => ({
    url: `/Order/UnManaged`,
    keys: ['UnManaged'],
    cacheQuery: { keys: ['UnManaged'] },
    enabled: isEnabled,
  }),
  orders: (status: EnumOrderStatus, isEnabled: boolean) => ({
    url: `/Order/Status/${status}`,
    keys: ['Orders', status],
    cacheQuery: { keys: ['Orders'] },
    enabled: isEnabled,
  }),
  workRoles: (companyId: string, isEnabled: boolean) => ({
    url: `/Company/${companyId}/workRoles`,
    keys: ['workRoles', companyId],
    cacheQuery: { keys: ['workRoles'] },
    enabled: isEnabled,
  }),
};

export const rqPost = {
  ticketAndPost: (isEnabled: boolean) => ({
    url: `ticket/ticketAndPost`,
    keys: ['ticketAndPost'],
    cacheQuery: { keys: ['ticketAndPost'] },
    enabled: isEnabled,
  }),
  getCloudFactoryComparedProducts: (isEnabled: boolean) => ({
    url: `/Module/CloudFactory/ComparedProducts`,
    keys: ['cloudFactoryProducts', isEnabled],
    cacheQuery: { keys: ['cloudFactoryProducts', isEnabled] },
    enabled: isEnabled,
  }),
  getCloudFactoryComparedEmployees: (isEnabled: boolean) => ({
    url: `/Module/CloudFactory/ComparedEmployees`,
    keys: ['cloudFactoryEmployees', isEnabled],
    cacheQuery: { keys: ['cloudFactoryEmployees', isEnabled] },
    enabled: isEnabled,
  }),
  postBudget: (isEnabled: boolean) => ({
    url: `/Economy/Budget`,
    keys: ['budget', isEnabled],
    cacheQuery: { keys: ['budget', isEnabled] },
    enabled: isEnabled,
  }),
  responsibleFor: (isEnabled: boolean) => ({
    url: `/Employee/ResponsibleFor`,
    keys: ['responsibleFor', isEnabled],
    cacheQuery: { keys: ['responsibleFor', isEnabled] },
    enabled: isEnabled,
  }),
  assignStorageProduct: (isEnabled: boolean) => ({
    url: `/StorageProduct/Assign`,
    keys: ['assign', isEnabled],
    cacheQuery: { keys: ['assign', isEnabled] },
    enabled: isEnabled,
  }),

  storageProduct: (isEnabled: boolean) => ({
    url: `/StorageProduct`,
    keys: ['storageProduct', isEnabled],
    cacheQuery: { keys: ['storageProduct', isEnabled] },
    enabled: isEnabled,
  }),
  brandPost: (isEnabled: boolean) => ({
    url: `/Setup/Brand`,
    keys: ['Brand'],
    cacheQuery: { keys: ['Brand'] },
    enabled: isEnabled,
  }),

  brandPut: (isEnabled: boolean) => ({
    url: `/Setup/Brand`,
    keys: ['Brand', isEnabled],
    cacheQuery: { keys: ['Brand', isEnabled] },
    enabled: isEnabled,
  }),
  sendEmail: (isEnabled: boolean) => ({
    url: `/setup/SendEmail`,
    keys: ['SendEmail'],
    cacheQuery: { keys: ['SendEmail'] },
    enabled: isEnabled,
  }),
  companyProduct: (isEnabled: boolean) => ({
    url: `/CompanyProduct`,
    keys: ['CompanyProduct'],
    cacheQuery: { keys: ['CompanyProduct'] },
    enabled: isEnabled,
  }),
  companyProducts: (employeeId: string, isEnabled: boolean) => ({
    url: `/CompanyProduct/addList/${employeeId}`,
    keys: ['CompanyProductsList'],
    cacheQuery: { keys: ['CompanyProductsList'] },
    enabled: isEnabled,
  }),
  consumableProducts: (
    companyId: string,
    ticketId: string,
    isEnabled: boolean
  ) => ({
    url: `/product/ConsumableList/companyId/${companyId}/ticketId/${ticketId}`,
    keys: ['ConsumableList'],
    cacheQuery: { keys: ['ConsumableList'] },
    enabled: isEnabled,
  }),
  updateCompanyProduct: (id: string, isEnabled: boolean) => ({
    url: `/CompanyProduct`,
    keys: ['CompanyProduct'],
    cacheQuery: { keys: ['CompanyProducts'] },
    enabled: isEnabled,
  }),
  updateCompanyProducts: (employeeId: string, isEnabled: boolean) => ({
    url: `/CompanyProduct/updateList/${employeeId}`,
    keys: ['CompanyProductsList'],
    cacheQuery: { keys: ['CompanyProductsList'] },
    enabled: isEnabled,
  }),
  commonCompanyProducts: (companyId: string, isEnabled: boolean) => ({
    url: `/CompanyProduct/addList/${companyId}`,
    keys: ['CompanyProductsList'],
    cacheQuery: { keys: ['CompanyProductsList'] },
    enabled: isEnabled,
  }),
  commonUpdateCompanyProducts: (companyId: string, isEnabled: boolean) => ({
    url: `/CompanyProduct/updateList/${companyId}`,
    keys: ['CompanyProductsList'],
    cacheQuery: { keys: ['CompanyProductsList'] },
    enabled: isEnabled,
  }),
  billing: (isEnabled: boolean) => ({
    url: `/Billing`,
    name: 'Billing',
    keys: ['Billing', isEnabled],
    enabled: isEnabled,
  }),
  Invoice: (isEnabled: boolean) => ({
    url: `/Billing/Invoice`,
    name: 'Invoice',
    keys: ['Invoice', isEnabled],
    enabled: isEnabled,
  }),
  mobileAutoAnswers: (productTypeid: string, isEnabled: boolean) => ({
    url: `/Setup/MobileAutoAnswer/${productTypeid}`,
    name: 'MobileAutoAnswers',
    keys: ['MobileAutoAnswers', productTypeid],
    enabled: isEnabled,
  }),
  workRole: (companyId: string, isEnabled: boolean) => ({
    url: `/Company/${companyId}/workRole`,
    name: 'Work Role',
    keys: ['workRole', companyId],
    enabled: isEnabled,
  }),
  rates: (isEnabled: boolean) => ({
    url: `/Company/Rates`,
    name: 'Rates',
    keys: ['Rates', isEnabled],
    enabled: isEnabled,
  }),
  company: (companyId: string, type: string, isEnabled: boolean) => ({
    url: '/company',
    name: 'Company',
    keys: {
      all: rq.companies(type, isEnabled).keys,
      single: rq.company(companyId, isEnabled).keys,
    },
  }),
  employee: (companyId: string, employeeId: string, isEnabled: boolean) => ({
    url: `/employee`,
    name: 'Employee',
    keys: {
      all: rq.employees(companyId, isEnabled).keys,
      single: rq.employee(companyId, employeeId, isEnabled).keys,
    },
  }),
  importEmployees: (companyId: string, isEnabled: boolean) => ({
    url: `/Module/CloudFactory/ImportEmployees`,
    name: 'importEmployees',
    keys: {
      all: rq.employees(companyId, isEnabled).keys,
    },
  }),
  importResources: (companyId: string, isEnabled: boolean) => ({
    url: `/Module/CloudFactory/ImportResources`,
    name: 'importResources',
    keys: {
      all: rq.resources(companyId, isEnabled).keys,
    },
  }),
  importProducts: (companyId: string, isEnabled: boolean) => ({
    url: `/Module/CloudFactory/ImportProducts`,
    name: 'importProducts',
    keys: {
      all: rq.resources(companyId, isEnabled).keys,
    },
  }),
  order: (status: string, isEnabled: boolean) => ({
    url: `/Order`,
    name: 'Order',
    keys: {
      all: rq.orderPosts(status, isEnabled).keys,
      single: rq.orderPosts(status, isEnabled).keys,
    },
  }),
  orderPost: (status: string, isEnabled: boolean) => ({
    url: `/Order/Test`,
    name: 'Test',
    keys: {
      all: rq.orderPosts(status, isEnabled).keys,
      single: rq.orderPosts(status, isEnabled).keys,
    },
  }),
  employeeDeactivate: (
    companyId: string,
    employeeId: string,
    isEnabled: boolean
  ) => ({
    url: `/employee/${employeeId}/deactivate`,
    name: 'Deactivate employee',
    keys: {
      all: rq.employees(companyId, isEnabled).keys,
      single: rq.employee(companyId, employeeId, isEnabled).keys,
    },
  }),
  department: (
    companyId: string,
    isEnabled: boolean,
    departmentId: string
  ) => ({
    url: '/department',
    name: 'Department',
    keys: {
      all: rq.departments(companyId, isEnabled).keys,
      single: rq.department(companyId, departmentId, isEnabled).keys,
    },
  }),
  departmentDeactivate: (
    companyId: string,
    departmentId: string,
    isEnabled: boolean
  ) => ({
    url: `/department/${departmentId}/deactivate`,
    name: 'Deactivate department',
    keys: {
      all: rq.departments(companyId, isEnabled).keys,
      single: rq.department(companyId, departmentId, isEnabled).keys,
    },
  }),
  resources: (
    /* resourceId: string, companyId: string,  */ isEnabled: boolean
  ) => ({
    url: '/resources',
    name: 'Resources',
    keys: ['Resources', isEnabled],
  }),
  package: (companyId: string, isEnabled: boolean) => ({
    url: '/productpackage',
    name: 'Package',
    keys: rq.packages(companyId, isEnabled).keys,
  }),
  product: (productId: string, isEnabled: boolean) => ({
    url: '/product',
    name: 'Product',
    keys: {
      all: rq.products,
      single: rq.product(productId, isEnabled).keys,
    },
  }),
  ticketPut: (ticketId: string, boardNr: any, isEnabled: boolean) => ({
    url: `/ticket/${ticketId}`,
    name: 'Ticket',
    keys: {
      all: rq.tickets(ticketId, boardNr, isEnabled).keys,
      single: rq.ticket(ticketId, boardNr, isEnabled).keys,
    },
  }),
  ticket: (ticketId: any, boardNr: any, isEnabled: boolean) => ({
    url: `/ticket`,
    name: 'Ticket',
    keys: {
      all: rq.tickets(ticketId, boardNr, isEnabled).keys,
      single: rq.ticket(ticketId, boardNr, isEnabled).keys,
    },
  }),
  ticketPost: (ticketId: string, boardNr: any) => ({
    url: `/ticket/${ticketId}/TicketPost`,
    name: 'Ticket Post',
    keys: {
      all: rq.ticketPosts(ticketId, true).keys,
      single: rq.ticketPost(ticketId, boardNr).keys,
    },
  }),
  ticketPostPut: (ticketId: string, id: string) => ({
    url: `/ticket/${ticketId}/TicketPost`,
    name: 'Ticket Post',
    keys: {
      all: rq.ticketPosts(ticketId, true).keys,
      single: rq.ticketPost(ticketId, id).keys,
    },
  }),
  ticketMerge: (id1: string, id2: string) => ({
    url: `/ticket/Merge/${id1}/${id2}`,
    name: 'Ticket Merge',
    keys: ['ticketMerge'],
    cacheQuery: { keys: ['ticketMerge'] },
  }),
  ticketPostStorage: (isEnabled: boolean) => ({
    url: `/ticket/TicketPost`,
    keys: ['ticketPostStorage'],
    cacheQuery: { keys: ['ticketPostStorage'] },
    enabled: isEnabled,
  }),
  ticketResponsible: (ticketId: string, isEnabled: boolean) => ({
    url: `/ticket/${ticketId}/Responsible`,
    name: 'Ticket Responsible',
    keys: {
      all: rq.ticket(ticketId, isEnabled, 0).keys,
      single: rq.ticket(ticketId, isEnabled, 0).keys,
    },
  }),
};

export const rqDel = {
  company: (companyId: string, type: string, isEnabled: boolean) => ({
    url: `/company/${companyId}`,
    name: 'Company',
    keys: rq.companies(type, isEnabled).keys,
  }),
  workRole: (companyId: string, id: string, isEnabled: boolean) => ({
    url: `/company/${companyId}/workRole/${id}}`,
    name: 'WorkRole',
    enabled: isEnabled,
  }),
  product: (productId: string) => ({
    url: `/product/${productId}`,
    name: 'Product',
  }),
  companyProduct: (id: string, productId: string) => ({
    url: `/employee/${id}/product/${productId}`,
    name: 'Product',
  }),
};
