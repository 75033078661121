import React, { useEffect, useReducer, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Pagination,
  Select,
  MenuItem,
} from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import useWindowsDimension from 'functions/useWindowsDimension';
import { useAtom } from 'jotai';
import accessTokenAtom from 'data/atoms/accessTokenAtom';
import CloudRoundedIcon from '@mui/icons-material/CloudRounded';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import { Business } from '@mui/icons-material';
import { t } from 'i18next';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { ImportCompanyForm } from './ImportCompanyForm';
import {
  ICompanyAddViewModel,
  ICompanyViewModel,
  IDepartmentViewModel,
  ThirdPartEnum,
} from 'Interfaces/ICompaniesConfig';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormBox } from 'components/form';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { IResourcesViewModel } from 'Interfaces/IResourcesConfig';
import { ToResource } from './Converts/ToResource';
import { ToCompanies } from './Converts/ToCompanies';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePost } from 'http/useInnovit';
import { ImportEmployeesList } from './ImportEmployeesList';
import { ImportResourcesList } from './ImportResourcesList';
import { IProductViewModel } from 'Interfaces/IProductConfig';
import {
  IImportEmployeeAddListViewModel,
  IImportEmployeeAddViewModel,
  IImportProductAddViewModel,
  removeLastColonPart,
} from './Converts/ICloudFactoryInterfaces';
import { IBrandViewModel } from 'pages/Setup/setupConfig';
import { ImportProductList } from './ImportProductList';
import CloudFactoryProductView from './Converts/CloudFactoryProductView';
import userAtom from 'data/atoms/userAtom';
import { ToAddEmployees } from './Converts/ToAddEmployee';
import _, { set } from 'lodash';
import { truncateText } from 'functions/truncateText';
import DomainAddRoundedIcon from '@mui/icons-material/DomainAddRounded';
import { ToCloudFactoryTempProduct } from './Converts/ToCloudFactoryTempProduct';
import { ICloudFactoryProductsViewModel } from './Converts/CludFactoryConfig';
import { useSnackbar } from 'notistack';

const moduleOptions = [
  {
    id: 1,
    name: 'Get Companies',
    description: 'Import companies from Cloud Factory',
  },
  {
    id: 2,
    name: 'Get Users',
    description: 'Import Users from Company: ',
  },
  {
    id: 3,
    name: 'Get Products',
    description: 'Import Products from Cloud Factory',
  },
  {
    id: 4,
    name: 'API Documentation',
    description: 'Access API usage and examples',
  },
];

export const enum ShowRightSideEnum {
  companies = 'companies',
  employee = 'employee',
  resource = 'resource',
  employees = 'employees',
  resources = 'resources',
  products = 'products',
}

export interface ICloudFactoryData {
  id: string | null;
  companies: ICompanyAddViewModel[];
  localCompany: ICompanyAddViewModel | ICompanyViewModel;
  companyEmployees: IImportEmployeeAddViewModel[];
  products: IImportProductAddViewModel[];
  cloudTempProducts: ICloudFactoryProductsViewModel[];
  selectedCompany: ICompanyViewModel;
  selectedCompanyEmployeeList: IEmployeeViewModel[];
  selectedEmployee: IImportEmployeeAddViewModel | null;
  selectedResource: IResourcesViewModel | null;
  selectedAddCompany: ICompanyAddViewModel | null;
  departments: IDepartmentViewModel[];
  brands: IBrandViewModel[];
  rightSideView: ShowRightSideEnum;
  nextCompany: boolean;
  page: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  currentPage: number;
}

const initialState: ICloudFactoryData = {
  id: null,
  companies: [],
  localCompany: {} as unknown as ICompanyAddViewModel,
  companyEmployees: [],
  products: [],
  cloudTempProducts: [] as unknown as ICloudFactoryProductsViewModel[],
  selectedCompany: {} as unknown as ICompanyViewModel,
  selectedCompanyEmployeeList: [],
  selectedEmployee: null,
  selectedResource: null,
  selectedAddCompany: null,
  departments: [],
  brands: [],
  rightSideView: ShowRightSideEnum.companies,
  nextCompany: false,
  page: 1,
  pageSize: 10,
  totalPages: 1,
  totalRecords: 0,
  currentPage: 1,
};

type Action =
  | { type: 'SET_ID'; payload: string }
  | { type: 'SET_COMPANIES'; payload: ICompanyAddViewModel[] }
  | {
      type: 'SET_LOCAL_COMPANY';
      payload: ICompanyViewModel | ICompanyAddViewModel;
    }
  | { type: 'SET_COMPANY_EMPLOYEES'; payload: IImportEmployeeAddViewModel[] }
  | { type: 'SET_PRODUCTS'; payload: IImportProductAddViewModel[] }
  | { type: 'ADD_PRODUCT'; payload: IImportProductAddViewModel }
  | { type: 'SET_SELECTED_COMPANY'; payload: ICompanyViewModel }
  | { type: 'SET_SELECTED_COMPANY_EMPLOYEES'; payload: IEmployeeViewModel[] }
  | {
      type: 'SET_SELECTED_EMPLOYEE';
      payload: IImportEmployeeAddViewModel | null;
    }
  | { type: 'SET_SELECTED_RESOURCE'; payload: IResourcesViewModel | null }
  | { type: 'SET_SELECTED_ADD_COMPANY'; payload: ICompanyAddViewModel | null }
  | { type: 'SET_DEPARTMENTS'; payload: IDepartmentViewModel[] }
  | { type: 'SET_BRANDS'; payload: IBrandViewModel[] }
  | { type: 'SET_RIGHT_SIDE_VIEW'; payload: ShowRightSideEnum }
  | { type: 'REMOVE_COMPANY_PRODUCTS'; payload: string[] }
  | { type: 'REMOVE_COMPANY_EMPLOYEES'; payload: string[] }
  | { type: 'REMOVE_SELECTED_COMPANY'; payload: string }
  | { type: 'SET_NEXT_COMPANY'; payload: boolean }
  | {
      type: 'ADD_CLOUD_FACTORY_TEMP_PRODUCTS';
      payload: ICloudFactoryProductsViewModel;
    }
  | {
      type: 'SET_PAGINATION';
      payload: {
        page: number;
        pageSize: number;
        totalPages: number;
        totalRecords: number;
        currentPage: number;
      };
    }
  | { type: 'SET_CURRENT_PAGE'; payload: number };

const reducer = (
  state: ICloudFactoryData,
  action: Action
): ICloudFactoryData => {
  switch (action.type) {
    case 'SET_ID':
      return { ...state, id: action.payload };
    case 'SET_COMPANIES':
      return { ...state, companies: action.payload };
    case 'SET_LOCAL_COMPANY':
      console.log('ccc : SET_LOCAL_COMPANY  1', action.payload);
      return { ...state, localCompany: action.payload };
    case 'SET_PRODUCTS':
      return { ...state, products: action.payload };
    case 'ADD_PRODUCT':
      return { ...state, products: [...state.products, action.payload] };
    case 'ADD_CLOUD_FACTORY_TEMP_PRODUCTS':
      console.log('III : ADD_CLOUD_FACTORY_TEMP_PRODUCTS', action.payload);
      return {
        ...state,
        cloudTempProducts: [...state.cloudTempProducts, action.payload],
      };
    case 'SET_COMPANY_EMPLOYEES':
      console.log('qqq : SET_COMPANY_EMPLOYEES', action.payload);
      return { ...state, companyEmployees: action.payload };
    case 'SET_SELECTED_COMPANY':
      return { ...state, selectedCompany: action.payload };
    case 'SET_SELECTED_COMPANY_EMPLOYEES':
      return { ...state, selectedCompanyEmployeeList: action.payload };
    case 'SET_SELECTED_EMPLOYEE':
      return { ...state, selectedEmployee: action.payload };
    case 'SET_SELECTED_RESOURCE':
      return { ...state, selectedResource: action.payload };
    case 'SET_SELECTED_ADD_COMPANY':
      return { ...state, selectedAddCompany: action.payload };
    case 'SET_DEPARTMENTS':
      return { ...state, departments: action.payload };
    case 'SET_BRANDS':
      return { ...state, brands: action.payload };
    case 'SET_RIGHT_SIDE_VIEW':
      return { ...state, rightSideView: action.payload };
    case 'REMOVE_COMPANY_PRODUCTS':
      return {
        ...state,
        products: state.products.filter(
          (product) => !action.payload.includes(product.thirdPartyId.toString())
        ),
      };
    case 'REMOVE_COMPANY_EMPLOYEES':
      return {
        ...state,
        companyEmployees: state.companyEmployees.filter(
          (employee) => !action.payload.includes(employee.email)
        ),
      };
    case 'REMOVE_SELECTED_COMPANY':
      return {
        ...state,
        companies: state.companies.filter(
          (company) => !action.payload.includes(company.email)
        ),
      };
    case 'SET_NEXT_COMPANY':
      return { ...state, nextCompany: action.payload };
    case 'SET_PAGINATION':
      return {
        ...state,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalRecords,
        currentPage: action.payload.currentPage,
      };
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload };
    default:
      return state;
  }
};
export const CloudFactory = () => {
  /* INITIALIZATIONS */
  const [state, dispatch] = useReducer(reducer, initialState);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('Loading...');
  const [selectedModule, setSelectedModule] = useState<number>(0);
  const { height } = useWindowsDimension();
  const [mouseOver, setMouseOver] = useState<number>(0);
  const [expanded, setExpanded] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  // PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(50);
  const [isFirstEmployeeSaved, setIsFirstEmployeeSaved] = useState(false);
  const [getEmployeeList, setGetEmployeeList] = useState(false);
  const [getDepartmentList, setGetDepartmentList] = useState(false);

  // const ref = useRef<number>(2);
  const {
    mutate: compareWithBackend,
    data: compareResult,
    isLoading: isLoadingCompare,
    error: errorCompare,
  } = usePost(rqPost.getCloudFactoryComparedProducts(true));

  const {
    mutate: employeeCompareWithBackend,
    data: employeeCompareResult,
    isLoading: isLoadingEmployeeCompare,
    error: errorEmployeeCompare,
  } = usePost(rqPost.getCloudFactoryComparedEmployees(true));

  // api
  const { data: employeeData } = useGet(
    rq.employees(state.localCompany.thirdPartyId || '', getEmployeeList)
  );
  let getDepartments = state.selectedCompany?.id && getDepartmentList ? true : false;
  const { data: departmentData } = useGet(
    rq.departments(state.selectedCompany.id || '', getDepartments)
  );
  useEffect(() => {
    if (employeeData !== undefined) {
      console.log('aaa employeeData', employeeData);
      if (state.localCompany.thirdPartyId.length < 0) return;
      let idx = state.localCompany.thirdPartyId.toString();
      fetchCompanyEmployees(idx);
      dispatch({
        type: 'SET_RIGHT_SIDE_VIEW',
        payload: ShowRightSideEnum.employees,
      });

      setGetEmployeeList(false);
    }
  }, [employeeData]);
  useEffect(() => {
    if (departmentData !== undefined) {
      dispatch({
        type: 'SET_DEPARTMENTS',
        payload: departmentData.departments,
      });

      setGetDepartmentList(false);
    }
  }, [departmentData]);

  useEffect(() => {
    console.log('III : Useeffect: ', state.cloudTempProducts);
  }, [state.cloudTempProducts]);
  // get users from local company

  const { data: localCompanyData } = useGet(rq.companies('2', true));
  console.log('localcompany data: ', localCompanyData);

  // API CALLS MOT CLOUD FACTORY
  const [refreshToken] = useState(
    'ozAPKTeYhy7KW1JJnZbFh0W90glf1mV1i-L-xjSjtBgg4'
  );

  const fetchAccessToken = async (refreshToken: string) => {
    const url = `https://portal.api.cloudfactory.dk/Authenticate/ExchangeRefreshToken/${refreshToken}`;

    setLoading(true);
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        setAccessToken(data.access_token);
        console.log('Access Token:', data.access_token);
      } else {
        throw new Error('Failed to fetch access token');
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompaniesData = async (pageIndex: number, pageSize: number) => {
    if (!refreshToken) return;
    console.log('Fetching module data');

    const url = `https://portal.api.cloudfactory.dk/v2/customers/Customers?PageIndex=${pageIndex}&PageSize=${pageSize}`;

    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: 'SET_PAGINATION',
          payload: {
            page: data.metadata.page,
            pageSize: data.metadata.pageSize,
            totalPages: data.metadata.totalPages,
            totalRecords: data.metadata.totalRecords,
            currentPage: data.metadata.page,
          },
        });
        console.log('ssss', data);
        let result = ToCompanies(data);
        dispatch({
          type: 'SET_COMPANIES',
          payload: result,
        });
      } else {
        throw new Error('Failed to fetch module data');
      }
    } catch (error) {
      console.error('Error fetching module data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyEmployees = async (legacyCustomerId: string) => {
    if (!accessToken) return;
    console.log('xxx fetchCompanyEmployees, legacyId: ', legacyCustomerId);
    //`https://portal.api.cloudfactory.dk/Microsoft/GetAllUsersOnCustomer?customerId='${legacyCustomerId}'`;
    //url = `https://portal.api.cloudfactory.dk/v2/microsoft/customer/${legacyCustomerId}/users`;
    let url = `https://portal.api.cloudfactory.dk/v2/microsoft/customer/${legacyCustomerId}/users`;
    setLoading(true);
    // Når alle er hentet
    enqueueSnackbar('Henter enployees. Dette kan ta litt tid!', {
      variant: 'info',
    });
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        enqueueSnackbar(`${data.length} ansatte funnet!`, {
          variant: 'info',
        });
        console.log('vbv state.selectedCompany.id: ', state.selectedCompany.id);
        // 1) Bygg en midlertidig array
        const newEmployees = ToAddEmployees(
          data,
          user.id,
          state.selectedCompany.id,
          state.departments[0]?.id
        );
        enqueueSnackbar(`${newEmployees.length} Sjekker Ansatte mot Bluu!`, {
          variant: 'info',
        });
        // Gå gjennom alle produkter sekvensielt
        console.log('vbv newEmployees: ', newEmployees);

        // Sammenlign med backend
        employeeCompareWithBackend(
          {
            NumberOfCloudFactoryEmployees: data.length,
            cloudFactoryEmployees: newEmployees,
          },
          {
            onSuccess: (res: IImportEmployeeAddListViewModel) => {
              enqueueSnackbar(
                `${newEmployees.length} Fjerne de som er registrert!`,
                {
                  variant: 'info',
                }
              );

              /*  const employeesToRemove = res.cloudFactoryEmployees.filter(
                (emp: IImportEmployeeAddViewModel) =>
                  emp.isEmailFoundInBluu === false
              ); */
              console.log('vbv onSuccess: ', res);
              enqueueSnackbar(
                `${res.cloudFactoryEmployees.length} klar for visning!`,
                {
                  variant: 'info',
                }
              );
              dispatch({
                type: 'SET_COMPANY_EMPLOYEES',
                payload: res.cloudFactoryEmployees,
              });
            },
          }
        );

        if (state.departments.length > 0) {
          enqueueSnackbar(`Henter Departments!`, {
            variant: 'info',
          });
          setGetDepartmentList(true);
        }

        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.employees,
        });
        setExpanded(!expanded);
      } else {
        throw new Error('xxx Failed to fetch users data');
      }
    } catch (error) {
      console.error('xxx Error fetching users data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeeProducts = async (
    employeeId: string,
    legacyCustomerId: string
  ) => {
    if (!accessToken) return;
    //legacyCustomerId = '93a40604-5ff6-4bfa-81f5-45134707e539';
    const url = `https://portal.api.cloudfactory.dk/v2/microsoft/customer/${legacyCustomerId}/users/${employeeId}/licenses`;
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log('NEW : fetchEmployeeProducts:', data);
        return data;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };
  const fetchBluuProductData = (product: any) => {
    console.log('xxx NEW : product:', product);
    let returnProduct: IProductViewModel = {} as IProductViewModel;

    return returnProduct;
  };
  const fetchCompanyProducts = async (legacyCustomerId: string) => {
    if (!accessToken) return;
    // const url = `https://portal.api.cloudfactory.dk/v2/microsoft/customer/${legacyCustomerId}/subscriptions?Statuses=Active`;
    //const url = `https://portal.api.cloudfactory.dk/v2/microsoft/customer/${legacyCustomerId}/licenses`;
    const url = `https://portal.api.cloudfactory.dk/v2/microsoft/customer/${legacyCustomerId}/subscriptions?Statuses=Active`;
    // Først: nullstill, eller legg til "start"-melding

    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        // 1) Bygg en midlertidig array
        const newProducts: ICloudFactoryProductsViewModel[] = [];

        // Gå gjennom alle produkter sekvensielt
        let index = 0;
        for (const product of data) {
          // Oppdater status for hvert produkt
          index++;
          enqueueSnackbar(`${index},Henter produkt: ${product.name}`, {
            variant: 'info',
          });

          const productResult = await fetchCFProductData(product, 1, 250);

          if (productResult) {
            newProducts.push(productResult);
          }
        }

        // Når alle er hentet
        enqueueSnackbar('Sender til backend!', {
          variant: 'info',
        });
        // Sammenlign med backend
        compareWithBackend(
          { cloudFactoryProducts: newProducts },
          {
            onSuccess: (res: any) => {
              enqueueSnackbar('Viser product resultat!', {
                variant: 'success',
              });
              console.log('III : Products created:', res);
              // Håndter res om du vil
            },
          }
        );
      } else {
        throw new Error('Failed to fetch users data');
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
      enqueueSnackbar('En feil oppsto!', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchCFProductData = async (
    product: any,
    pageIndex: number,
    pageSize: number
  ) => {
    if (!accessToken) return;

    const _pageIndex = pageIndex + 0;
    const _PageSize = pageSize;

    // Lag et URLSearchParams-objekt
    const params = new URLSearchParams({
      PageIndex: _pageIndex.toString(),
      PageSize: _PageSize.toString(),
    });
    console.log('xxx Data: Fetch ', product);
    // Legg til hver verdi for Filter.BillingCycles
    if (product.billingCycle === 'Monthly') {
      params.append('Filter.BillingCycles', '1');
    } else {
      params.append('Filter.BillingCycles', '12');
    }

    /*   const billingCycles = [1, 12];
    billingCycles.forEach((bc) => {
      params.append('Filter.BillingCycles', bc.toString());
    }); */

    // Legg til hver verdi for Filter.CommitmentLengths
    if (product.termDuration === 'P1Y') {
      params.append('Filter.CommitmentLengths', '12');
    } else {
      params.append('Filter.CommitmentLengths', '1');
    }
    /* const commitmentLengths = [1, 12];
    commitmentLengths.forEach((cl) => {
      params.append('Filter.CommitmentLengths', cl.toString());
    }); */

    // Legg til hver verdi for Filter.ProductIds
    /*   const productIds = [product.skuId, product.id];
    productIds.forEach((pid) => {
      params.append('Filter.ProductIds', pid);
    }); */

    // Legg til hver verdi for Filter.SKUs
    let _sku = removeLastColonPart(product.sku);
    const skus = [_sku];
    skus.forEach((sku) => {
      params.append('Filter.SKUs', sku);
    });

    // Bygg den endelige URL-en:
    const url = `https://portal.api.cloudfactory.dk/v2/catalogue/products?&Filter.CategoryIds=db584fbc-8a3a-4c68-b486-d9c8764dc10e&${params.toString()}&Filter.Name=${
      product.name
    }`;
    // console.log('xxx Data: url ', url);
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log('III :', data.results);
        // bygg productResult
        let productResult = ToCloudFactoryTempProduct(
          product,
          data.results[0].product,
          data.results[0].price,
          state.selectedCompany.id.toString()
        );

        // returnér direkte i stedet for dispatch
        return productResult;
      } else {
        // logg feilmelding etc.
        return null;
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
      return null;
    }
  };

  // HANDLE FUNCTIONS
  const HandleShow = (type: string) => {
    switch (type) {
      case 'test':
        let companyId = '93a40604-5ff6-4bfa-81f5-45134707e539';
        let employeeId = 'c4ea4b54-fd63-4835-a32f-ef2fe71d0dde';
        console.log(
          'NEW : state.selectedCompany.thirdPartyId.toString()',
          state.selectedCompany.thirdPartyId.toString()
        );
        handleShowProducts(state.selectedCompany);
        // fetchCompanyEmployees(companyId);
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.employee,
        });
        break;
      case 'companies':
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.companies,
        });
        break;
      case 'products':
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.products,
        });
        break;
      case 'employees':
        //fetchCompanyEmployees(state.selectedCompany.legacyId.toString());
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.employees,
        });
        break;

      case 'resources':
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.resources,
        });
        break;
      case 'nextCompany':
        dispatch({
          type: 'SET_NEXT_COMPANY',
          payload: false,
        });
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.companies,
        });
        break;
      default:
        break;
    }
  };

  const handleModuleClick = (
    moduleId: number,
    pageIndex: number,
    pageSize: number
  ) => {
    console.log('handleModuleClick', moduleId, pageIndex, pageSize);
    setExpanded(!expanded);
    setSelectedModule(moduleId);
    switch (moduleId) {
      case 1:
        fetchCompaniesData(pageIndex, pageSize);
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.companies,
        });
        break;
      case 2:
        console.log('xxx fetchCompanyProducts', state.selectedCompany);
        if (state.selectedCompany.thirdPartyId.length <= 0) return;
        let id = state.selectedCompany.thirdPartyId.toString();
        fetchCompanyProducts(id);
        console.log('xxx done', state.products);
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.products,
        });
        break;
      case 3:
        console.log('vbv state.selectedCompany.id', state);
        if (
          state.selectedCompany.thirdPartyId &&
          state.selectedCompany.thirdPartyId.length <= 0
        )
          return;
        let idx = state.selectedCompany.thirdPartyId.toString();
        fetchCompanyEmployees(idx);
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.employees,
        });
        break;
      case 4:
        console.log('xxx handleModuleClick', state);
        if (state.selectedCompany.legacyId < 0) return;
        id = state.selectedCompany.legacyId.toString();
        fetchCompanyEmployees(id);
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.resources,
        });
        break;
      default:
        break;
    }
  };

  const handleCompanySelect = (company: ICompanyAddViewModel) => {
    console.log('ccc handleCompanySelect her ', company);
    dispatch({
      type: 'SET_SELECTED_ADD_COMPANY',
      payload: company,
    });
    setGetDepartmentList(true);
  };

  const handleShowEmployee = (company: ICompanyViewModel) => {
    console.log('handleShowEmployee', company);
    if (company.legacyId < 0) return;
    dispatch({
      type: 'SET_SELECTED_COMPANY',
      payload: company,
    });
    dispatch({
      type: 'SET_RIGHT_SIDE_VIEW',
      payload: ShowRightSideEnum.employee,
    });
  };

  const handleShowProducts = (company: ICompanyViewModel) => {
    console.log('handleShowProducts', company);
    if (company.legacyId < 0) return;
    dispatch({
      type: 'SET_SELECTED_COMPANY',
      payload: company,
    });
    dispatch({
      type: 'REMOVE_SELECTED_COMPANY',
      payload: company.email,
    });
    fetchCompanyProducts(company.thirdPartyId.toString());
    dispatch({
      type: 'SET_RIGHT_SIDE_VIEW',
      payload: ShowRightSideEnum.products,
    });
  };

  const handleClickEmployee = (employee: IImportEmployeeAddViewModel) => {
    if (state.selectedCompany === null) return;
    dispatch({
      type: 'SET_SELECTED_EMPLOYEE',
      payload: employee,
    });

    let resource: IResourcesViewModel = ToResource(
      employee,
      state.selectedCompany
    );
    console.log('handleClickEmployee', resource, state.selectedCompany);
    dispatch({
      type: 'SET_SELECTED_RESOURCE',
      payload: resource,
    });
  };

  const handleRemoveEmployees = (employees: IEmployeeViewModel[]) => {
    setIsFirstEmployeeSaved(true);
    console.log('handleRemoveEmployees', employees);
    let newEmployeeList = state.companyEmployees;
    //finne employye og sette isEmailFoundInBluu til true
    employees.forEach((employee) => {
      let index = newEmployeeList.findIndex(
        (emp) => emp.email === employee.email
      );
      if (index >= 0) {
        newEmployeeList[index].isEmailFoundInBluu = true;
      }
    });
    dispatch({
      type: 'SET_COMPANY_EMPLOYEES',
      payload: newEmployeeList,
    });
  };

  const handleRemoveResources = (resource: IResourcesViewModel[]) => {
    console.log('handleRemoveEmployees', resource);
    const emailsToRemove = resource.map((employee) => employee.email);
    dispatch({ type: 'REMOVE_COMPANY_EMPLOYEES', payload: emailsToRemove });
  };

  const handleRemoveProducts = (products: IProductViewModel[]) => {
    console.log('NEW : handleRemoveProducts', products);
    const productsToRemove = products.map((product) =>
      product.thirdPartyId.toString()
    );
    dispatch({ type: 'REMOVE_COMPANY_PRODUCTS', payload: productsToRemove });

    if (productsToRemove.length === state.products.length) {
      fetchCompanyEmployees(state.selectedCompany.thirdPartyId.toString());
      //fetchCompanyEmployees(state.selectedCompany.legacyId.toString());
      console.log('NEW : DONE', state);
      dispatch({
        type: 'SET_RIGHT_SIDE_VIEW',
        payload: ShowRightSideEnum.employees,
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const handleSelectBluuCompany = (e: any, value: string) => {
    console.log('ccc: handleSelectBluuCompany 111', e, value);

    if (value.toUpperCase() === 'ALL') {
      // Dersom "ALL" er valgt, sett localCompany til et tomt objekt
      dispatch({
        type: 'SET_LOCAL_COMPANY',
        payload: {} as ICompanyViewModel,
      });
    } else {
      // Finn selskapet med det gitte navnet og sett det som localCompany
      const selectedCompany = localCompanyData.companies.find(
        (company: ICompanyViewModel) => company.name === value
      );
      console.log('aaa : handleSelectBluuCompany 222', selectedCompany);
      if (selectedCompany) {
        dispatch({
          type: 'SET_LOCAL_COMPANY',
          payload: selectedCompany,
        });
        dispatch({
          type: 'SET_SELECTED_COMPANY',
          payload: selectedCompany,
        });
        setGetDepartmentList(true);
      }
    }
  };
  const handleMergedCompanySelect = (company: ICompanyViewModel) => {
    console.log('ccc handleMergedCompanySelect her ', company);
    dispatch({
      type: 'SET_LOCAL_COMPANY',
      payload: company,
    });
    dispatch({
      type: 'SET_SELECTED_COMPANY',
      payload: company,
    });
    setExpanded(!expanded);
  };
  const handleClickProduct = (product: IImportProductAddViewModel) => {
    console.log('handleClickProduct', product);
  };
  console.log('NEW : STATE : ', state);

  // USE EFFECTS
  useEffect(() => {
    if (state.rightSideView === ShowRightSideEnum.companies) return;
    console.log('NEW : state Fetching companies data', state.selectedEmployee);
  }, [state.rightSideView, state.selectedEmployee]);

  /*   useEffect(() => {
    if (!_.isEmpty(state.localCompany)) {
      console.log('xxx localCompany useEffect', state.localCompany);
      fetchCompanyEmployees(state.localCompany.thirdPartyId.toString());
    }
  }, [state.localCompany]); */

  useEffect(() => {
    console.log('NEW : state.totalPages', state.totalPages);
  }, [state.totalPages]);
  useEffect(() => {
    fetchCompaniesData(state.currentPage, state.pageSize);
  }, [state.currentPage, state.pageSize]);

  return (
    <Grid container display={'flex'} flexDirection={'row'} flexGrow={1} sx={{}}>
      <List
        sx={{
          height: height - 20,
          width: '100%',
          mb: 0,
          p: 0,
          backgroundColor: 'primary.light',
          overflow: 'auto',
        }}
      >
        <Grid item xs={12}>
          <SearchFilter Header='Cloud Factory API' Icon={CloudRoundedIcon} />
        </Grid>
        <Grid item xs={12}>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              backgroundColor: 'primary.main',
              borderRadius: 2,
              width: '100%',
            }}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                <FormBox
                  Icon={Business}
                  title={t('modules.api')}
                  FormBTN={null}
                />
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={0}
                sx={{
                  m: 0,
                  p: 0,
                  height: height - 160,
                  flexWrap: 'wrap', // lar elementene bryte til ny rad
                  alignItems: 'flex-start', // justerer vertikalt for enkeltrad
                  alignContent: 'flex-start', // justerer linjene mot toppen ved flere rader
                }}
              >
                {/*   TOKEN MANAGER  */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    mb: 1,
                  }}
                >
                  <Stack
                    direction='row'
                    spacing={1}
                    sx={{
                      m: 1,
                      p: 1,
                      backgroundColor: accessToken
                        ? 'success.light'
                        : 'warning.light',
                    }}
                  >
                    <Typography sx={{ flexGrow: 1, p: 0, m: 0 }}>
                      {t('modules.cloudFactory.tokenManager')}:
                    </Typography>
                    {accessToken ? (
                      <Typography sx={{ p: 0, m: 0, color: 'success.text' }}>
                        {t('modules.cloudFactory.accessTokenStatusOK')}
                      </Typography>
                    ) : (
                      <Typography sx={{ p: 0, m: 0, color: 'warning.test' }}>
                        {t('modules.cloudFactory.accessTokenStatus')}
                      </Typography>
                    )}
                  </Stack>
                  <Paper
                    elevation={1}
                    sx={{ p: 1, borderRadius: 2, width: '100%' }}
                  >
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      <Button
                        sx={{ height: 88, width: 200, borderRadius: 2 }}
                        variant='contained'
                        onClick={() => fetchAccessToken(refreshToken)}
                        startIcon={
                          loading ? (
                            <CircularProgress size={48} color='success' />
                          ) : (
                            <Box sx={{ width: 48 }}>
                              <CloudRoundedIcon
                                fontSize='large'
                                sx={{
                                  color: accessToken
                                    ? 'success.lighter'
                                    : 'warning.lighter',
                                }}
                              />
                            </Box>
                          )
                        }
                      >
                        {t('modules.cloudFactory.refreshToken')}
                      </Button>

                      <Grid item xs={9} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.tokenManager')}
                        </Typography>
                        <Typography sx={{ color: 'primary.text' }}>
                          {t('modules.cloudFactory.tokenManagerInfo')
                            .split('\n')
                            .map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ pl: 2 }}></Grid>
                    </Stack>
                  </Paper>
                </Grid>

                {/*   UPDATE PRODUCTS FOR ALL COMPANIES  */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    mb: 1,
                  }}
                >
                  <Paper
                    elevation={1}
                    sx={{ p: 1, borderRadius: 2, width: '100%' }}
                  >
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      {/* <Typography sx={{ flexGrow: 1, p: 0, m: 0 }}>
                        {t('modules.cloudFactory.getCompany')}:
                      </Typography> */}
                    </Stack>
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      <Button
                        sx={{ height: 88, width: 200, borderRadius: 2 }}
                        disabled={
                          !accessToken ||
                          (state.localCompany &&
                            Object.keys(state.localCompany).length > 0)
                        }
                        variant='contained'
                        onClick={() =>
                          handleModuleClick(1, currentPage, itemsPerPage)
                        }
                        startIcon={
                          loading ? (
                            <CircularProgress size={48} color='success' />
                          ) : (
                            <Box sx={{ width: 48 }}>
                              <DomainAddRoundedIcon
                                fontSize='large'
                                sx={{
                                  color: accessToken
                                    ? 'success.lighter'
                                    : 'warning.lighter',
                                }}
                              />
                            </Box>
                          )
                        }
                      >
                        {t('modules.cloudFactory.allProducts')}
                      </Button>

                      <Grid item xs={9} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.allProducts')}
                        </Typography>
                        <Typography sx={{ color: 'primary.text' }}>
                          {t('modules.cloudFactory.allProductsInfo')
                            .split('\n')
                            .map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.progress')}
                        </Typography>
                      </Grid>
                    </Stack>
                  </Paper>
                </Grid>

                {/*   IMPORT COMPANY  */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    mb: 1,
                  }}
                >
                  <Paper
                    elevation={1}
                    sx={{ p: 1, borderRadius: 2, width: '100%' }}
                  >
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      {/* <Typography sx={{ flexGrow: 1, p: 0, m: 0 }}>
                        {t('modules.cloudFactory.getCompany')}:
                      </Typography> */}
                    </Stack>
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      <Button
                        sx={{ height: 88, width: 200, borderRadius: 2 }}
                        disabled={
                          !accessToken ||
                          (state.localCompany &&
                            Object.keys(state.localCompany).length > 0)
                        }
                        variant='contained'
                        onClick={() =>
                          handleModuleClick(1, currentPage, itemsPerPage)
                        }
                        startIcon={
                          loading ? (
                            <CircularProgress size={48} color='success' />
                          ) : (
                            <Box sx={{ width: 48 }}>
                              <DomainAddRoundedIcon
                                fontSize='large'
                                sx={{
                                  color: accessToken
                                    ? 'success.lighter'
                                    : 'warning.lighter',
                                }}
                              />
                            </Box>
                          )
                        }
                      >
                        {t('modules.cloudFactory.getCompany')}
                      </Button>

                      <Grid item xs={9} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.getCompany')}
                        </Typography>
                        <Typography sx={{ color: 'primary.text' }}>
                          {t('modules.cloudFactory.companyInfo')
                            .split('\n')
                            .map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.company')}
                        </Typography>
                        <Select
                          sx={{ width: '95%' }}
                          variant='standard'
                          value={state.localCompany?.name || 'ALL'}
                          onChange={(e) => {
                            handleSelectBluuCompany(e, e.target.value);
                          }}
                        >
                          <MenuItem key={-1} value={'ALL'}>
                            All
                          </MenuItem>
                          {localCompanyData &&
                            localCompanyData.companies.map(
                              (localCompany: ICompanyViewModel) =>
                                localCompany.thirdParty ===
                                  ThirdPartEnum.CloudFactory && (
                                  <MenuItem
                                    key={localCompany.id}
                                    value={localCompany.name}
                                  >
                                    <Typography
                                      sx={{
                                        p: 0,
                                        m: 0,
                                        width: '100%',
                                        color:
                                          localCompany.thirdParty ===
                                          ThirdPartEnum.CloudFactory
                                            ? 'success.text'
                                            : 'primary.text',
                                      }}
                                    >
                                      {truncateText(localCompany.name, 20)}
                                    </Typography>
                                  </MenuItem>
                                )
                            )}
                        </Select>
                      </Grid>
                    </Stack>
                  </Paper>
                </Grid>

                {/*   GET PRODUCT FOR SELECTED COMPANY  */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    mb: 1,
                  }}
                >
                  <Paper
                    elevation={1}
                    sx={{ p: 1, borderRadius: 2, width: '100%' }}
                  >
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      {/* <Typography sx={{ flexGrow: 1, p: 0, m: 0 }}>
                        {t('modules.cloudFactory.getCompany')}:
                      </Typography> */}
                    </Stack>
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      <Button
                        sx={{ height: 88, width: 200, borderRadius: 2 }}
                        disabled={
                          !accessToken ||
                          (state.localCompany &&
                            Object.keys(state.localCompany).length === 0)
                        }
                        variant='contained'
                        onClick={() =>
                          handleModuleClick(2, currentPage, itemsPerPage)
                        }
                        startIcon={
                          loading ? (
                            <CircularProgress size={48} color='success' />
                          ) : (
                            <Box sx={{ width: 48 }}>
                              <DomainAddRoundedIcon
                                fontSize='large'
                                sx={{
                                  color: accessToken
                                    ? 'success.lighter'
                                    : 'warning.lighter',
                                }}
                              />
                            </Box>
                          )
                        }
                      >
                        {t('modules.cloudFactory.product')}
                      </Button>

                      <Grid item xs={9} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.product')}
                        </Typography>
                        <Typography sx={{ color: 'primary.text' }}>
                          {t('modules.cloudFactory.productInfo')
                            .split('\n')
                            .map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.company')}
                        </Typography>
                        <Typography
                          sx={{
                            width: '100%',
                            p: 0,
                            m: 0,
                            color: 'success.text',
                          }}
                        >
                          {state.localCompany.name}
                        </Typography>
                      </Grid>
                    </Stack>
                  </Paper>
                </Grid>

                {/*   GET EMPLOYEE FOR SELECTED COMPANY  */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    mb: 1,
                  }}
                >
                  <Paper
                    elevation={1}
                    sx={{ p: 1, borderRadius: 2, width: '100%' }}
                  >
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      {/* <Typography sx={{ flexGrow: 1, p: 0, m: 0 }}>
                        {t('modules.cloudFactory.getCompany')}:
                      </Typography> */}
                    </Stack>
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      <Button
                        sx={{ height: 88, width: 200, borderRadius: 2 }}
                        disabled={
                          !accessToken ||
                          (state.localCompany &&
                            Object.keys(state.localCompany).length === 0)
                        }
                        variant='contained'
                        onClick={() =>
                          handleModuleClick(3, currentPage, itemsPerPage)
                        }
                        startIcon={
                          loading ? (
                            <CircularProgress size={48} color='success' />
                          ) : (
                            <Box sx={{ width: 48 }}>
                              <DomainAddRoundedIcon
                                fontSize='large'
                                sx={{
                                  color: accessToken
                                    ? 'success.lighter'
                                    : 'warning.lighter',
                                }}
                              />
                            </Box>
                          )
                        }
                      >
                        {t('modules.cloudFactory.employee')}
                      </Button>

                      <Grid item xs={9} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.employee')}
                        </Typography>
                        <Typography sx={{ color: 'primary.text' }}>
                          {t('modules.cloudFactory.employeeInfo')
                            .split('\n')
                            .map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.company')}
                        </Typography>
                        <Typography
                          sx={{
                            width: '100%',
                            p: 0,
                            m: 0,
                            color: 'success.text',
                          }}
                        >
                          {state.localCompany.name}
                        </Typography>
                      </Grid>
                    </Stack>
                  </Paper>
                </Grid>

                {/*   GET RESOURCES FOR SELECTED COMPANY  */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    mb: 1,
                  }}
                >
                  <Paper
                    elevation={1}
                    sx={{ p: 1, borderRadius: 2, width: '100%' }}
                  >
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      {/* <Typography sx={{ flexGrow: 1, p: 0, m: 0 }}>
                        {t('modules.cloudFactory.getCompany')}:
                      </Typography> */}
                    </Stack>
                    <Stack direction='row' spacing={1} sx={{ m: 0 }}>
                      <Button
                        sx={{ height: 88, width: 200, borderRadius: 2 }}
                        disabled={
                          !accessToken ||
                          (state.localCompany &&
                            Object.keys(state.localCompany).length === 0)
                        }
                        variant='contained'
                        onClick={() =>
                          handleModuleClick(4, currentPage, itemsPerPage)
                        }
                        startIcon={
                          loading ? (
                            <CircularProgress size={48} color='success' />
                          ) : (
                            <Box sx={{ width: 48 }}>
                              <DomainAddRoundedIcon
                                fontSize='large'
                                sx={{
                                  color: accessToken
                                    ? 'success.lighter'
                                    : 'warning.lighter',
                                }}
                              />
                            </Box>
                          )
                        }
                      >
                        {t('modules.cloudFactory.resources')}
                      </Button>

                      <Grid item xs={9} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.resources')}
                        </Typography>
                        <Typography sx={{ color: 'primary.text' }}>
                          {t('modules.cloudFactory.resourcesInfo')
                            .split('\n')
                            .map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography sx={{ width: '100%', p: 0, m: 0 }}>
                          {t('modules.cloudFactory.company')}
                        </Typography>
                        <Typography
                          sx={{
                            width: '100%',
                            p: 0,
                            m: 0,
                            color: 'success.text',
                          }}
                        >
                          {state.localCompany.name}
                        </Typography>
                      </Grid>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {accessToken && (
          <Grid
            container
            spacing={0}
            sx={{
              m: 0,
              p: 0,
              height: height - 160,
              flexWrap: 'wrap', // lar elementene bryte til ny rad
              alignItems: 'flex-start', // justerer vertikalt for enkeltrad
              alignContent: 'flex-start', // justerer linjene mot toppen ved flere rader
            }}
          >
            <Grid item xs={5} sx={{ pr: 1, mt: 1 }}>
              <Grid item xs={12} sx={{ p: 0, m: 0 }}>
                <SearchFilter
                  Icon={Business}
                  Header={
                    state.rightSideView === ShowRightSideEnum.employee
                      ? t('employees.header')
                      : state.rightSideView === ShowRightSideEnum.companies
                      ? t('company.header')
                      : state.rightSideView === ShowRightSideEnum.resources
                      ? t('resources.header')
                      : t('product.header')
                  }
                  /*    gfx={
                  <Stack direction='row' spacing={1} sx={{ mt: -1 }}>
                    <IconButton
                      onClick={() => HandleShow('test')}
                      sx={{
                        color:
                          state.companies.length > 0
                            ? 'success.lighter'
                            : 'primary.light',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <RecentActorsRoundedIcon
                        sx={{ fontSize: 35, m: 0, p: 0 }}
                      />
                    </IconButton>

                    <IconButton
                      disabled={
                        state.products.length > 0 ||
                        state.companyEmployees.length > 0 ||
                        state.companies.length === 0
                      }
                      onClick={() => HandleShow('companies')}
                      sx={{
                        color:
                          state.companies.length > 0
                            ? 'success.lighter'
                            : 'primary.light',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <BusinessRoundedIcon sx={{ fontSize: 35, m: 0, p: 0 }} />
                    </IconButton>

                    <IconButton
                      disabled={state.products.length <= 0}
                      onClick={() => HandleShow('products')}
                      sx={{
                        color:
                          state.products.length > 0
                            ? 'success.lighter'
                            : 'primary.light',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <DevicesOtherRoundedIcon
                        sx={{ fontSize: 35, m: 0, p: 0 }}
                      />
                    </IconButton>

                    <IconButton
                      disabled={
                        state.products.length > 0 ||
                        state.companyEmployees.length <= 0
                      }
                      onClick={() => HandleShow('employees')}
                      sx={{
                        color:
                          state.companyEmployees.length > 0 &&
                          state.products.length === 0
                            ? 'success.lighter'
                            : 'primary.light',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <PeopleAltRoundedIcon sx={{ fontSize: 35, m: 0, p: 0 }} />
                    </IconButton>

                    <IconButton
                      disabled={
                        state.products.length > 0 ||
                        state.companyEmployees.length <= 0 ||
                        !isFirstEmployeeSaved
                      }
                      onClick={() => HandleShow('resources')}
                      sx={{
                        color:
                          state.companyEmployees.length > 0
                            ? 'success.lighter'
                            : 'primary.light',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <MapsHomeWorkRoundedIcon
                        sx={{ fontSize: 35, m: 0, p: 0 }}
                      />
                    </IconButton>
                  </Stack>
                } */
                />
                <List
                  sx={{
                    height: Math.ceil(height - 274 - (expanded ? 384 : 0)),
                    width: '100%',
                    mb: 0,
                    p: 1,
                    backgroundColor: 'primary.back',
                    overflow: 'auto',
                    borderBottomLeftRadius: 2,
                    borderBottomRightRadius: 2,
                  }}
                >
                  {state.rightSideView === ShowRightSideEnum.companies &&
                    state.companies.length > 0 &&
                    state.companies?.map(
                      (company: ICompanyAddViewModel, index: number) => (
                        <Grid
                          item
                          xs={12}
                          sx={{ cursor: 'pointer' }}
                          key={index}
                          onClick={() => handleCompanySelect(company)}
                        >
                          <SingleCompanyView index={index} company={company} />
                        </Grid>
                      )
                    )}

                  {state.rightSideView === ShowRightSideEnum.products &&
                    state.products &&
                    state.products.map(
                      (product: IImportProductAddViewModel, index: number) => (
                        <Grid
                          item
                          xs={12}
                          sx={{ cursor: 'pointer' }}
                          key={index}
                          onClick={() => handleClickProduct(product)}
                        >
                          <CloudFactoryProductView products={product} />
                        </Grid>
                      )
                    )}

                  {(state.rightSideView === ShowRightSideEnum.employees ||
                    state.rightSideView === ShowRightSideEnum.resources) &&
                    state.companyEmployees &&
                    state.companyEmployees.length > 0 &&
                    state.companyEmployees.map(
                      (
                        employee: IImportEmployeeAddViewModel,
                        index: number
                      ) => (
                        <Grid
                          item
                          xs={12}
                          sx={{ cursor: 'pointer' }}
                          key={index}
                          onClick={() => handleClickEmployee(employee)}
                        >
                          <SingleEmployeeView
                            index={index}
                            employee={employee}
                          />
                        </Grid>
                      )
                    )}
                </List>
              </Grid>

              <Grid item xs={12} sx={{ p: 0, m: 0 }}>
                <Paper
                  variant='elevation3'
                  sx={{
                    p: 1,
                    height: 64,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                >
                  <Grid
                    container
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Grid item xs={9} sx={{}}>
                      <Pagination
                        showFirstButton
                        showLastButton
                        size='small'
                        count={state.totalPages}
                        page={state.currentPage}
                        onChange={(event, value) =>
                          dispatch({ type: 'SET_CURRENT_PAGE', payload: value })
                        }
                        sx={{ margin: 'auto', padding: '10px' }}
                      />
                    </Grid>

                    {/*  <Button
                    disabled={state.products.length >= 0 ? false : true}
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={() =>
                      dispatch({ type: 'SET_NEXT_COMPANY', payload: true })
                    }
                  >
                    {t('modules.cloudFactory.nextCompany')}
                  </Button>
                  <Button
                    disabled={state.nextCompany ? false : true}
                    size='small'
                    variant='contained'
                    color='secondary'
                    onClick={() => HandleShow('nextCompany')}
                  >
                    {t('modules.cloudFactory.confirm')}
                  </Button> */}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid item xs={7}>
              {state.rightSideView === ShowRightSideEnum.companies && (
                <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
                  <ImportCompanyForm
                    expanded={expanded}
                    company={
                      state.selectedAddCompany || ({} as ICompanyAddViewModel)
                    }
                    handleMergedCompanySelect={handleMergedCompanySelect}
                  />
                </Grid>
              )}

              {state.rightSideView === ShowRightSideEnum.employees &&
                state.companyEmployees &&
                state.departments &&
                state.selectedCompany && (
                  <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
                    <ImportEmployeesList
                      expanded={expanded}
                      employees={state.companyEmployees}
                      company={state.selectedCompany}
                      departments={state.departments}
                      handleRemoveEmployees={handleRemoveEmployees}
                    />
                  </Grid>
                )}
              {state.rightSideView === ShowRightSideEnum.resources &&
                state.companyEmployees && (
                  <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
                    <ImportResourcesList
                      expanded={expanded}
                      employees={state.companyEmployees}
                      company={state.selectedCompany}
                      departments={state.departments}
                      handleRemoveResources={handleRemoveResources}
                    />
                  </Grid>
                )}
              {state.rightSideView === ShowRightSideEnum.products &&
                state.selectedCompany &&
                state.selectedCompany.id &&
                state.products && (
                  <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
                    <ImportProductList
                      expanded={expanded}
                      products={state.products}
                      company={state.selectedCompany}
                      departments={state.departments}
                      handleRemoveProducts={handleRemoveProducts}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}
      </List>
    </Grid>
  );
};
