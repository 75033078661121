import { useState, useReducer, useEffect, useRef } from 'react';
import useWindowsDimension from 'functions/useWindowsDimension';
import 'moment-timezone';
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Grid,
  List,
  ListItem,
  IconButton,
  TextField,
  Accordion,
  AccordionDetails,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  Select,
  MenuItem,
} from '@mui/material';

import { Navigate, useParams } from 'react-router';
import { SearchFilter } from 'components/ui/SearchFilter';
import { ticketCommunicationStatus } from 'data/enum';
import { useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
//@ts-ignore
import DocumentUploader from 'components/ui/DocumentUploader';
//@ts-ignore
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import userAtom from 'data/atoms/userAtom';
import { SingleMessage } from 'components/ui/tickets/SingleMessage';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import {
  ITicketPostViewModel,
  IEmployeeWithCompanyViewModel,
} from '../../Interfaces/ITicketConfig';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  EnumObjectType,
  objectArray,
  EnumScheduleType,
  SubButtonInterface,
  EnumSubButtonInterface,
  IDeliveryAddressViewModel,
  IAddressViewModel,
  EnumPriority,
} from 'Interfaces/IGlobalTypeConfig';
import { ScheduleView } from 'components/ui/ScheduleView';
import { useGetObjectList } from 'functions/useGetObjectList';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  ProductFormStyle,
  TextStyle,
  boxStyle,
  gridStyle,
} from 'data/FontStyle';
import { SwitchType } from 'components/ui/SwitchType';
import { GetWorkRole } from 'functions/GetWorkRole';
import { ConvertToEmployee } from 'functions/ConverToEmployee';
import { FormAccordionSummary, FormBox } from 'components/form';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { EconomyView } from 'functions/EconomySimpleView';
import CalculateTotalTime from 'functions/CalculateTotalTime';
import { SingleTicketEmployeeView } from 'components/ui/SingleTicketEmployeeView';
import { ConvertToTicketPost } from 'functions/ConvertToTicketPost';
import _ from 'lodash';
import { ConvertToPutTicketPost } from 'functions/ConvertToPutTicketPost';
import { checkForOvertime } from 'functions/checkForOvertime';
import { RootReducer, initialRootState } from './Components/RotTicketReducer';
import { TicketHeaderButtonDetail } from './Components/TicketStyleConfig';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import { GenerateAddressList } from 'functions/Converters';
import { SingleTicketCompanyView } from 'components/ui/SingleTicketCompanyView';
import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';
import GetPriorityColor from 'functions/GetPriorityColor';
import { IAutoAnswersViewModel } from 'pages/AutoAnswersConfig';
import { SingleAutoAnswerView } from 'components/ui/SingleAutoAnswerView';
import { SingleTicketContactProduct } from 'components/ui/SingleTicketContactProduct';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { ConvertStateToTicketUpdateWithProductViewModel } from './TicketDetails';
import { isGuidEmpty, isValidGuid } from 'functions/GuidChecks';
import { CompaniesDrawer } from 'pages/Companies/Components/CompaniesDrawer';
import { ConvertToAttachment } from 'functions/ConvertToAttachment';
import { EmployeeProductDrawer } from 'pages/Companies/EmployeeProductDrawer';
import { EmployeeDrawer } from 'pages/Companies/Components/EmployeeDrawer';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';

export const TicketCommunications = () => {
  const { t } = useTranslation();
  /* INITIALIZATIONS */
  const [state, dispatch] = useReducer(RootReducer, initialRootState);

  const [owner] = useAtom(ownerAtom);
  const [user] = useAtom(userAtom);
  const { ticketId } = useParams<{ ticketId: string }>();
  const { height } = useWindowsDimension();

  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
  const [isListDisabled, setisListDisabled] = useState(false);
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  const [consumable, setConsumable] = useState<any[]>([]);
  const [companyProducts, setCompanyProducts] = useState<any[]>([]);

  let companyId = 'new';
  let employeeId = 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );

  // API CALLS

  let isEnabled = ticketId !== 'new' ? true : true;
  const { data: ticketData } = useGet(
    rq.ticket(ticketId || '', isEnabled, '0'),
    isEnabled
  );
  const { data: ticketProductsData } = useGet(
    rq.ticketProducts(ticketId || '', isEnabled),
    isEnabled
  );
  useEffect(() => {
    if (ticketProductsData) {
      setConsumable(ticketProductsData.products);
      setCompanyProducts(ticketProductsData.companyProducts);
      console.log('ticketProductsData', ticketProductsData);
    }
  }, [ticketProductsData]);
  const postTicketPost = usePost(rqPost.ticketPost(ticketId || '', '0'));
  const putTicketPost = usePut(
    rqPost.ticketPostPut(ticketId || '', state.ticketPost.ticketPost.id || '')
  );
  const postEmail = usePost(rqPost.sendEmail(true));

  const putTicketResponsible = usePut(
    rqPost.ticketResponsible(ticketId || '', isEnabled),
    isEnabled
  );

  const putTicket = usePut(rqPost.ticketPut(ticketId || '', '0', isEnabled));
  const { data: ticketPostDataList } = useGet(
    rq.ticketPosts(ticketId || '', true)
  );
  isEnabled = owner.id ? true : false;

  const { data: workRoleData } = useGet(
    rq.workRoles(owner.id, isEnabled),
    isEnabled
  );

  isEnabled = state.ticketPost.rates.length <= 0 ? true : false;
  const { data: ratesData } = useGet(rq.rates(isEnabled));

  type FormOrMouseEvent =
    | React.FormEvent<HTMLFormElement>
    | React.MouseEvent<HTMLDivElement, MouseEvent>;

  // HANDLES
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleAssigneMe = () => {
    let ticketResponsible = {
      ticketId: state.ticketPost.ticket.id,
      responsibleId: user.id,
    };
    putTicketResponsible.mutate({
      ticketId: state.ticketPost.ticket.id,
      responsibleId: '',
    });
    let ticket = _.cloneDeep(state.ticketPost.ticket);
    ticket.responsible = ConvertToEmployee(user);
    dispatch({
      type: 'SET_TICKET',
      payload: ticket,
    });
  };

  const handleChangeResponsable = (
    e: any,
    employee: IEmployeeWithCompanyViewModel
  ) => {
    e.preventDefault();

    let ticketResponsible = {
      ticketId: state.ticketPost.ticket.id,
      responsibleId: employee.id,
    };
    putTicketResponsible.mutate(ticketResponsible);
    let ticket = _.cloneDeep(state.ticketPost.ticket);
    ticket.responsible = employee;
    dispatch({
      type: 'SET_TICKET',
      payload: ticket,
    });
  };
  const handleChange = (
    event: FormOrMouseEvent,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    if (!state.ticketPost.isEditable) return;
    switch (objectType) {
      case EnumObjectType.WorkRole:
        let object = selectedObject as IWorkRoleViewModel;
        dispatch({ type: 'SET_WORK_ROLE', payload: object });

        break;
      default:
        break;
    }
  };
  const handleChangeSchedule = (changeType: string, e: any) => {
    let scheduleTime = _.cloneDeep(state.ticketPost.scheduleTime);
    switch (changeType.toUpperCase()) {
      case 'DELIVERY_DATE':
        scheduleTime.deliveryDate = e;
        // Check if the deliveryDate is a Monday
        if (dayjs(scheduleTime.deliveryDate).day() === 1) {
          // Day.js treats Sunday as 0, Monday as 1, and so on.
          // Set startDate to the previous Friday at 07:00
          const startDateDayjs = dayjs(scheduleTime.deliveryDate)
            .subtract(3, 'day')
            .set('hour', 7)
            .set('minute', 0);
          scheduleTime.startDate = startDateDayjs;
        }
        break;
      case 'SCHEDULED_DATE':
        scheduleTime.startDate = e;
        scheduleTime.scheduleType = EnumScheduleType.Reminder;
        break;
      case 'SCHEDULE_TYPE':
        scheduleTime.scheduleType = e;
        break;
      case 'HOURS':
        scheduleTime.estimatedTime.hours = e;
        break;
      case 'MINUTES':
        scheduleTime.estimatedTime.minutes = e;
        break;
    }

    dispatch({
      type: 'SET_SCHEDULE_TIME',
      payload: scheduleTime,
    });
  };
  const handleSelectedPost = (ticketPost: ITicketPostViewModel) => {
    console.log('XXX TICKETPOST: ', ticketPost);
    // console.log('...handleSelectedPost, state', state);

    const startTime = dayjs(ticketPost.startTime);
    const endTime = dayjs(ticketPost.endTime);

    ticketPost.startTime = startTime;
    ticketPost.endTime = endTime;
    dispatch({
      type: 'SET_TICKET_POST',
      payload: ticketPost,
    });
    dispatch({
      type: 'UPDATE_TICKET_POST_BILLING',
    });

    // check if SET_TICKET_POST_BILLING
    // calculate new ticketPostBilling
    // sertte new ticketPostBilling

    if (
      state.ticketPost.ticket.fixedHourlyRate === undefined ||
      state.ticketPost.ticket.fixedHourlyRate === null ||
      state.ticketPost.ticket.fixedHourlyRate === 0
    ) {
      if (state.ticketPost.ticket.company.workRole != null) {
        dispatch({
          type: 'SET_WORK_ROLE',
          payload: state.ticketPost.ticket.company.workRole,
        });
      } else if (user.workRole) {
        dispatch({
          type: 'SET_WORK_ROLE',
          payload: user.workRole,
        });
      }
    }
    // check if isBilled
    dispatch({
      type: 'SET_IS_EDITABLE',
      payload: ticketPost.isBilled ? false : true,
    });
    // check if mail is sendt
    dispatch({
      type: 'SET_IS_EMAIL_ALLOWED',
      payload: true,
    });
    const hour = dayjs(ticketPost.startTime).hour();
    const minute = dayjs(ticketPost.startTime).minute();
    checkForOvertime(state.ticketPost.rates, hour, minute);
  };
  const handleSwitchPage = (page: EnumSubButtonInterface) => {
    console.log('handleSwitchPage', page);
    switch (page) {
      case EnumSubButtonInterface.Details:
        dispatch({
          type: 'SET_PAGE',
          payload: page,
        });
        break;
      case EnumSubButtonInterface.Communication:
        dispatch({
          type: 'SET_PAGE',
          payload: page,
        });
        break;
      case EnumSubButtonInterface.CheckList:
        dispatch({
          type: 'SET_PAGE',
          payload: page,
        });
        break;
      case EnumSubButtonInterface.NewPost:
        dispatch({
          type: 'SET_PAGE',
          payload: EnumSubButtonInterface.Communication,
        });
        dispatch({
          type: 'REMOVE_TICKET_POST',
          payload: state.ticketPost.ticketPost.id,
        });
        dispatch({
          type: 'SET_IS_EDITABLE',
          payload: true,
        });
        break;
      default:
        break;
    }
  };
  //
  // HANDLE CHANGE TICKET
  //
  const handleChangeEmployee = (
    e: React.MouseEvent<HTMLElement>,
    employee: IEmployeeViewModel
  ) => {
    e.preventDefault();

    // setter ny contact
    dispatch({
      type: 'SET_CONTACT',
      payload: employee,
    });

    //setter ny contact i ticketpost
    /*   dispatch({
      type: 'SET_TICKET_POST_RECEIVER',
      payload: employee,
    }); */

    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });

    setIsObjectList(false);
  };
  const handleChangeCompany = (
    e: React.MouseEvent<HTMLElement>,
    company: ICompanyViewModel
  ) => {
    e.preventDefault();
    setIsObjectList(true);
    let employee: IEmployeeWithCompanyViewModel = {
      id: 'new',
      name: '',
      department: '',
      phone: '',
      email: '',
      budget: 0,
      title: '',
      url: '',
      workRole: null,
      company: {} as ICompanyViewModel,
      departmentAddress: {} as IAddressViewModel,
      companyAddress: company.mainAddress,
      homeOffice: company?.contact?.address || undefined || null,
    };

    let tmp: IDeliveryAddressViewModel[] = GenerateAddressList(
      state.ticket.addressList,
      owner,
      employee
    );

    dispatch({
      type: 'UPDATE_ADDRESS_LIST',
      payload: tmp,
    });
    dispatch({
      type: 'SET_TICKET_COMPANY',
      payload: company,
    });
    dispatch({
      type: 'SET_COMPANY',
      payload: company,
    });

    dispatch({
      type: 'SET_CONTACT',
      payload: employee,
    });
    dispatch({
      type: 'SET_EMPLOYEE',
      payload: employee,
    });
    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });
  };
  const handlePriorityChange = (e: any, priority: EnumPriority) => {
    e.preventDefault();
    dispatch({ type: 'SET_PRIORITY', payload: priority });
    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });
  };
  const handleChangeAutoAnswer = (
    event: any,
    autoAnswer: IAutoAnswersViewModel
  ) => {
    console.log('handleChangeAutoAnswer', autoAnswer);
    dispatch({
      type: 'SET_AUTO_ANSWER',
      payload: autoAnswer,
    });
    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });
  };
  const handleChangeCompanyProduct = (
    event: any,
    companyProduct: ICompanyProductViewModel
  ) => {
    dispatch({ type: 'SET_COMPANY_PRODUCT', payload: companyProduct });
    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });
  };
  const handleChangeTitle = (e: any) => {
    dispatch({ type: 'EDIT_TITLE', payload: e.target.value });
    dispatch({ type: 'SET_IS_DIRTY', payload: true });
  };
  const handleChangeCC = (e: any) => {
    dispatch({ type: 'EDIT_CC', payload: e.target.value });
    dispatch({ type: 'SET_IS_DIRTY', payload: true });
  };
  useEffect(() => {
    if (workRoleData) {
      dispatch({
        type: 'SET_WORK_ROLES',
        payload: workRoleData.workRoles,
      });
    }
  }, [workRoleData]);
  useEffect(() => {
    // console.log('...selected documents', selectedDocuments);
    if (selectedDocuments.length > 0) {
      dispatch({
        type: 'CHANGE_ATTACHMENTS',
        payload: selectedDocuments,
      });
    }
  }, [selectedDocuments]);
  useEffect(() => {
    if (ratesData && ratesData.rates) {
      let rates = [...ratesData.rates];
      // console.log('ratesData', ratesData);
      // Example: Let's say you want to increase the rate by 10 for each rate
      rates = rates.map((rate: IRateViewModel) => {
        rate.rateValue = 0;
        return rate;
      });
      dispatch({
        type: 'SET_RATES',
        payload: rates,
      });
    }
  }, [ratesData]);
  useEffect(() => {
    if (ticketPostDataList) {
      console.log('ticketPostDataList', ticketPostDataList);
      dispatch({
        type: 'SET_TICKET_POSTS',
        payload: ticketPostDataList.ticketPosts,
      });
      if (ticketPostDataList.workRole) {
        dispatch({
          type: 'SET_WORK_ROLE',
          payload: ticketPostDataList.workRole,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketPostDataList]);
  // triggers on changes to state.ticketPost (the entire screen)
  useEffect(() => {
    if (state.ticketPost.rates && state.ticketPost.rates.length > 0) {
      console.log('XXX TICKETPOST : useeffact', state.ticketPost);
      if (
        state.ticketPost.ticket.fixedHourlyRate === undefined ||
        state.ticketPost.ticket.fixedHourlyRate === null
      ) {
        state.ticketPost.ticket.fixedHourlyRate = 0;
      }

      //check if overtime
      if (state.ticketPost.rates) {
        let isShowOverTime = checkForOvertime(
          state.ticketPost.rates,
          state.ticketPost.ticketPost.endTime.hour(),
          state.ticketPost.ticketPost.endTime.minute()
        );
        dispatch({
          type: 'SET_SHOW_OVERTIME',
          payload: isShowOverTime,
        });
        // console.log('economy - ');
        dispatch({
          type: 'SET_TOTAL_TIME',
          payload: CalculateTotalTime(
            state.ticketPost.ticketPost.startTime,
            state.ticketPost.ticketPost.endTime,
            null,
            null
          ) as string,
        });
      }
      // update tickeptostbilling
      dispatch({
        type: 'UPDATE_TICKET_POST_BILLING',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.ticketPost.ticketPost.workRole,
    state.ticketPost.ticketPost.isBillable,
    state.ticketPost.ticketPost.isOvertime,
    state.ticketPost.ticketPost.isInHouse,
    state.ticketPost.rates,
    state.ticketPost.ticketPost.startTime,
    state.ticketPost.ticketPost.endTime,
    state.ticketPost.ticketPost,
  ]);
  useEffect(() => {
    if (ticketData) {
      dispatch({
        type: 'SET_TICKET',
        payload: ticketData,
      });
    }

    // console.log('GetWorkRole state.ticketPost.ticket', state.ticketPost.ticket);
    let workRole = GetWorkRole(
      state.ticketPost.ticket?.company?.workRole ?? null,
      user.workRole,
      state.ticketPost.ticketPost.workRole
    );
    // console.log('GetWorkRole w', workRole);
    if (workRole) {
      dispatch({
        type: 'SET_WORK_ROLE',
        payload: workRole,
      });
    }
  }, [
    ticketData,
    state.ticketPost.ticket,
    state.ticketPost.ticketPost.workRole,
    user,
  ]);
  useEffect(() => {
    // disable handleSelectTicketPost if it's new and it's being edited
    if (
      !state.ticketPost.ticketPost?.id &&
      state.ticketPost.ticketPost.message.length > 0
    ) {
      // console.log('...list of ticketPosts should now be unclickable');
      setisListDisabled(true);
    } else if (
      state.ticketPost.ticketPost?.id ||
      state.ticketPost.ticketPost.message.length === 0
    ) {
      // console.log('...list of ticketPosts is okay to click again');
      setisListDisabled(false);
    }
  }, [state.ticketPost.ticketPost.id, state.ticketPost.ticketPost.message]);
  const onTicketPostSubmit = async () => {
    // console.log('onSubmit : state', state);
    let _state = _.cloneDeep(state.ticketPost);

    console.log('XXX TICKETPOST ID', _state.ticketPost.id);

    if (
      !isGuidEmpty(_state.ticketPost.id) &&
      isValidGuid(_state.ticketPost.id)
    ) {
      let ticketPost = ConvertToPutTicketPost(_state, user);
      if (selectedDocuments.length > 0) {
        ticketPost.attachments = ConvertToAttachment(selectedDocuments) ?? [];
      } else {
        ticketPost.attachments = [];
      }
      console.log(
        'XXX TICKETPOST PUT',
        ticketPost,
        state.ticketPost.ticketPost.id
      );
      putTicketPost.mutate(ticketPost, {
        onSuccess: (res: any) => {
          console.log('putTicketPost', res);
        },
      });
    } else {
      let ticketPost = ConvertToTicketPost(_state, user);

      console.log(
        'XXX TICKETPOST POST',
        ticketPost,
        state.ticketPost.ticketPost.id
      );
      postTicketPost.mutate(ticketPost, {
        onSuccess: (res: any) => {
          console.log('postTicketPost', res);
        },
      });
    }
  };
  const onTicketSubmit = async () => {
    let newTicket = ConvertStateToTicketUpdateWithProductViewModel(
      state.ticket,
      user
    );
    console.log('NEWTICKET =>', newTicket);
    putTicket.mutate(newTicket, {
      onSuccess: (res: any) => {
        console.log('NEWTICKET => res', res);
      },
    });
  };
  const TicketHeaderLinks = TicketHeaderButtonDetail().map((button) => ({
    ...button,
    name: t(button.name), // Localize the name here
  }));

  const hasNonInternalMessages = state.ticketPost.ticketPosts.some(
    (post) => post.internal === false
  );

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {/* LEFT SIDE */}
      <Grid
        item
        xs={5}
        sx={{
          pl: 0.5,
          pr: 0.5,
        }}
      >
        {/* LEFT HEADER */}
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <ToggleButtonGroup
            sx={{
              width: '100%',
              ml: 0.5,
              mt: -0.3,
              p: 0,
            }}
            color='secondary'
            size='small'
            orientation='horizontal'
            // value={currentView}
            exclusive
            aria-label='text alignment'
          >
            {TicketHeaderLinks.map((obj: SubButtonInterface, index: number) => {
              //console.log('headerlink object', obj);
              return (
                <ToggleButton
                  key={index}
                  name={obj.name}
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleSwitchPage(obj.type);
                  }}
                  // Link={obj.to}
                  sx={{
                    m: 0,
                    p: 0,
                    width: 225,
                    borderRadius: 2,
                    borderLeft: 2,
                    borderRight: 2,
                    borderLeftColor: 'primary.light',
                    borderRightColor: 'primary.light',
                  }}
                  value={index}
                  aria-label='left aligned'
                >
                  {obj.icon && <obj.icon sx={{ mr: 1 }} />}
                  {obj.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Paper>

        {/* LEFT SIDE DETAIL */}

        {state.ticketPost.page === EnumSubButtonInterface.Details && (
          <List
            sx={{
              p: 1,
              height: Math.ceil(height - 260),
              backgroundColor: 'primary.main',
              overflow: 'auto',
            }}
          >
            <Grid
              container
              sx={{
                m: 0,
                p: 0,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {/* TICKET TITLE */}
              <Grid item xs={12} sx={{ mb: 2, pr: 1 }}>
                <TextField
                  name='name'
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Ticket.TicketDetails.title')}
                    </Typography>
                  }
                  value={state.ticket.title}
                  onChange={(e) => handleChangeTitle(e)}
                />
              </Grid>

              {/* ASSIGN CUSTOMER EMPLOYEE HEADER */}
              <Grid item xs={12} sx={{ p: 0, mb: 2 }}>
                <FormBox
                  Icon={PersonSearchRoundedIcon}
                  title={t('Ticket.TicketDetails.assignCustomerEmployee')}
                />
              </Grid>
              {/* ASSIGN CUSTOMER EMPLOYEE */}
              <Grid item xs={6} sx={{ p: 0, mb: 2, position: 'relative' }}>
                {state?.ticket.contact?.id != '' ? (
                  <Stack direction='column'>
                    <SingleTicketEmployeeView
                      employee={state.ticket.contact}
                      priority={state.ticket.priority}
                    />
                    <Grid
                      item
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                      }}
                    >
                      <EmployeeDrawer
                        buttonContext={t('Ticket.TicketDetails.selectEmployee')}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        handleChange={handleChangeEmployee}
                        companyId={state.ticket.company?.id}
                      />
                    </Grid>
                  </Stack>
                ) : (
                  <EmployeeDrawer
                    buttonContext={t('Ticket.TicketDetails.selectEmployee')}
                    buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                    handleChange={handleChangeEmployee}
                    companyId={state.ticket.company?.id}
                  />
                )}
              </Grid>
              <Grid item xs={6} sx={{ p: 0, mb: 2, position: 'relative' }}>
                {state.ticket.company ? (
                  <Stack direction='column'>
                    <SingleTicketCompanyView company={state.ticket.company} />
                    <Grid
                      item
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        pointerEvents: hasNonInternalMessages ? 'none' : 'auto',
                        opacity: hasNonInternalMessages ? 0.5 : 1,
                      }}
                    >
                      <CompaniesDrawer
                        buttonContext={t('Ticket.TicketDetails.selectEmployee')}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        handleChange={handleChangeCompany}
                      />
                    </Grid>
                  </Stack>
                ) : (
                  <CompaniesDrawer
                    buttonContext={t('Ticket.TicketDetails.selectEmployee')}
                    buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                    handleChange={handleChangeCompany}
                  />
                )}
              </Grid>
              {/* CC HEADER */}
              <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1, mt: 2 }}>
                <FormBox
                  Icon={ContactMailRoundedIcon}
                  title={t('Ticket.TicketDetails.ticketTypes.cc')}
                />
              </Grid>
              {/* CC */}
              <Grid item xs={12} sx={{ pr: 2 }}>
                <TextField
                  name='cc'
                  type='email'
                  variant='standard'
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Ticket.TicketDetails.ticketTypes.cc')}
                    </Typography>
                  }
                  value={state.ticket.ccEmail}
                  onChange={(e) => handleChangeCC(e)}
                />
              </Grid>

              {/* PRIORITY HEADER */}
              <Grid item xs={12} sx={{ p: 0, mb: 2, mt: 2 }}>
                <FormBox
                  Icon={PriorityHighRoundedIcon}
                  title={t('Ticket.TicketDetails.priority')}
                />
              </Grid>
              {/* PRIORITY BUTTONS  */}
              <Grid
                item
                xs={12}
                sx={{
                  p: 0,
                  mb: 2,
                  flexGrow: 1,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonGroup
                  variant='contained'
                  aria-label='outlined primary button group'
                  size='small'
                  sx={{ width: '100%' }}
                >
                  <Button
                    key='NONE'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.ticket.priority === EnumPriority.NONE
                          ? GetPriorityColor(state.ticket.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.NONE),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 0);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.none')}
                  </Button>
                  <Button
                    key='LOW'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.ticket.priority === EnumPriority.LOW
                          ? GetPriorityColor(state.ticket.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.LOW),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 1);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.low')}
                  </Button>
                  <Button
                    key='NORMAL'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.ticket.priority === EnumPriority.NORMAL
                          ? GetPriorityColor(state.ticket.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.NORMAL),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 2);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.normal')}
                  </Button>
                  <Button
                    key='HIGH'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.ticket.priority === EnumPriority.HIGH
                          ? GetPriorityColor(state.ticket.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.HIGH),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 3);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.high')}
                  </Button>

                  <Button
                    key='CRITICAL'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.ticket.priority === EnumPriority.CRITICAL
                          ? GetPriorityColor(state.ticket.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(
                          EnumPriority.CRITICAL
                        ),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 4);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.critical')}
                  </Button>
                </ButtonGroup>
              </Grid>
              {/* ISSUE HEADER */}
              <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 2 }}>
                <FormBox
                  Icon={DevicesOtherRoundedIcon}
                  title={t('Ticket.TicketDetails.ticketDescription')}
                />
              </Grid>
              {/* ISSUE TYPE */}
              <Grid item xs={5} sx={{ m: 0, pr: 1, mb: -1 }}>
                {!state?.ticket.supportType ? (
                  <>
                    <ObjectDrawer
                      buttonContext={t('Ticket.TicketDetails.selectIssueType')}
                      buttonIcon={null}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.IssueType]}
                      handleChange={handleChangeAutoAnswer}
                      buttonHeight={80}
                      buttonWidth={'100%'}
                    />
                  </>
                ) : (
                  <>
                    {state.ticket.supportType && (
                      <SingleAutoAnswerView
                        autoAnswer={state.ticket.supportType}
                      />
                    )}
                    <Grid
                      item
                      sx={{
                        position: 'relative',
                        bottom: 78,
                        right: 4,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={t(
                          'Ticket.TicketDetails.selectIssueType'
                        )}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.IssueType]}
                        handleChange={handleChangeAutoAnswer}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {/* ISSUE PRODUCT */}
              <Grid item xs={7} sx={{ m: 0, pl: 1, mb: -1 }}>
                {state.ticket.companyProductWithError ? (
                  <>
                    <SingleTicketContactProduct
                      product={state.ticket.companyProductWithError}
                    />
                    <Grid
                      item
                      sx={{
                        position: 'relative',
                        bottom: 78,
                        right: 8,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={t(
                          'Ticket.TicketDetails.selectCompanyProduct'
                        )}
                        buttonIcon={
                          <ChangeCircleIcon sx={{ fontSize: 26, m: 1.5 }} />
                        }
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.EmployeeProducts]}
                        handleChange={handleChangeCompanyProduct}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Grid>
                  </>
                ) : (
                  state.ticket.companyProducts &&
                  state.ticket.companyProducts.length > 0 && (
                    <ObjectDrawer
                      buttonContext={t(
                        'Ticket.TicketDetails.selectCompanyProduct'
                      )}
                      buttonIcon={null}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.EmployeeProducts]}
                      handleChange={handleChangeCompanyProduct}
                      buttonHeight={80}
                      buttonWidth={'100%'}
                    />
                  )
                )}
              </Grid>

              {/* JOBTYPE HEADER */}
              <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
                <FormBox Icon={DevicesOtherRoundedIcon} title={'Job Type'} />
              </Grid>
              {/* JOB TYPE */}
              <Grid item xs={3} sx={{ pr: 2 }}>
                <Select
                  sx={{ width: '100%', p: 0, mt: -0.5 }}
                  labelId='JobType'
                  id='JobType'
                  variant='standard'
                  value={state.ticket.jobType}
                  label='Job Type'
                  onChange={(e) => {
                    dispatch({ type: 'SET_JOB_TYPE', payload: e.target.value });
                  }}
                >
                  <MenuItem value={0}>None</MenuItem>
                  <MenuItem value={1}>Support</MenuItem>
                  <MenuItem value={2}>OnBoarding</MenuItem>
                  <MenuItem value={3}>OffBoarding</MenuItem>
                  <MenuItem value={4}>ReplaceProduct</MenuItem>
                  <MenuItem value={5}>AssignProduct</MenuItem>
                  <MenuItem value={6}>NewProduct</MenuItem>
                  <MenuItem value={7}>Service</MenuItem>
                </Select>
              </Grid>

              {/* END  */}
            </Grid>
          </List>
        )}
        {/* LEFT SIDE COMMUNICATION */}
        {state.ticketPost.page === EnumSubButtonInterface.Communication && (
          <List
            sx={{
              p: 1,
              height: Math.ceil(height - 260),
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {!!state.ticketPost.ticketPosts &&
              state.ticketPost.ticketPosts?.map(
                (ticketPost: ITicketPostViewModel, index: number) => (
                  <Grid
                    sx={{
                      backgroundColor: 'transparent',
                      border: 0,
                      boxShadow: 'none',
                      filter: `${
                        isListDisabled ? 'brightness(0.8)' : 'brightness(1)'
                      } `,
                    }}
                    key={index}
                    onClick={() => {
                      if (!isListDisabled) {
                        handleSelectedPost(ticketPost);
                      }
                    }}
                  >
                    <SingleMessage ticketPost={ticketPost} />
                  </Grid>
                )
              )}
          </List>
        )}
        {/* LEFT SIDE CHECKLIST */}
        {state.ticketPost.page === EnumSubButtonInterface.CheckList &&
          (console.log('state.ticket', state.ticket),
          (
            <List
              sx={{
                p: 1,
                height: Math.ceil(height - 256),
                backgroundColor: 'primary.back',
                overflow: 'auto',
              }}
            >
              CheckList
            </List>
          ))}
        {/* LEFT SIDE NEW TICKET */}
        {state.ticketPost.page === EnumSubButtonInterface.NewTicket && (
          <List
            sx={{
              p: 1,
              height: Math.ceil(height - 256),
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            New Ticket
          </List>
        )}

        <Paper
          variant='elevation3'
          sx={{
            p: 1,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Stack direction={'column'} sx={{ mb: 1, pr: 1 }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              {state.ticketPost.page === EnumSubButtonInterface.Details && (
                <Button
                  disabled={!state.ticket.isDirty}
                  sx={{
                    width: '50%',
                    // backgroundColor: 'secondary.main',
                  }}
                  type='submit'
                  onClick={(e: any) => {
                    e.preventDefault();
                    onTicketSubmit();
                    dispatch({
                      type: 'SET_IS_EDITABLE',
                      payload: false,
                    });
                  }}
                  id={
                    !state.ticket.id
                      ? `${t('Ticket.TicketCommunications.submit')}`
                      : `${t('Ticket.TicketCommunications.confirmEdit')}`
                  }
                  variant='contained'
                >
                  {!state.ticket.id
                    ? `${t('Ticket.TicketCommunications.submit')}`
                    : `${t('Ticket.TicketCommunications.confirmEdit')}`}
                </Button>
              )}
            </Box>
          </Stack>
        </Paper>
      </Grid>

      {/* RIGHT SIDE */}
      <Grid
        item
        xs={7}
        sx={{
          pl: 0.5,
          pr: 0.5,
        }}
      >
        <Grid
          container
          sx={{
            p: 2,
            borderRadius: 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'primary.main',
          }}
        >
          {/* TICKET TITLE */}
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                ...ProductFormStyle.SubHeader,
                ...{
                  pl: 2,
                  fontSize: '18px',
                  mb: 1,
                },
              }}
            >
              {state.ticket.title}
            </Typography>
          </Grid>
          {/* TICKET NO */}
          <Grid item sx={{}}>
            <Typography
              sx={{
                ...ProductFormStyle.SubHeader,
                ...{
                  pl: 2,
                  fontSize: '18px',
                  mb: 1,
                  pr: 2,
                  letterSpacing: 2,
                  color: 'info.text',
                },
              }}
            >
              {state.ticketPost.ticket.ticketNo}
            </Typography>
          </Grid>
          <List
            sx={{
              height: Math.ceil(height - 242),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.main',
              overflow: 'auto',
            }}
          >
            <Grid container key={'container'} sx={{}}>
              <Stack direction={'column'} key='stack' flex={1} sx={{}}>
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    mb: 1,
                  }}
                >
                  {/* RESPONISBLE */}
                  <Grid item xs={7} sx={{ m: 0, p: 0, mb: 1 }}>
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Grid item xs={7} sx={{ m: 0, p: 0, mb: 1 }}>
                        {state.ticketPost.ticket?.responsible ? (
                          <Grid
                            item
                            xs={12}
                            sx={{ p: 0, mb: 0.5, position: 'relative' }}
                          >
                            <SingleTicketEmployeeView
                              employee={state.ticketPost.ticket.responsible}
                              priority={0}
                            />
                            <Grid
                              item
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: 3,
                              }}
                            >
                              <ObjectDrawer
                                buttonContext={t(
                                  'Ticket.TicketDetails.selectEmployee'
                                )}
                                buttonIcon={
                                  <ChangeCircleIcon sx={{ fontSize: 26 }} />
                                }
                                objectList={
                                  !!objectDrawerList ? objectDrawerList : []
                                }
                                objectType={[EnumObjectType.AppOwnerEmployees]}
                                handleChange={handleChangeResponsable}
                                buttonHeight={30}
                                buttonWidth={30}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Box sx={[boxStyle.base, { height: 60 }]}>
                            <Button
                              sx={{
                                mx: 'auto',
                                width: '100%',
                                height: '100%',
                                borderRadius: 3,
                              }}
                              variant='contained'
                              onClick={(e) => {
                                handleAssigneMe();
                              }}
                            >
                              <Typography sx={[TextStyle.text, {}]}>
                                {t(
                                  'Ticket.TicketCommunications.assignCustomerEmployee'
                                )}
                              </Typography>
                            </Button>
                            <Grid
                              item
                              sx={{
                                position: 'relative',
                                top: 0,
                                right: 3,
                              }}
                            >
                              <ObjectDrawer
                                buttonContext={t(
                                  'Ticket.TicketDetails.selectEmployee'
                                )}
                                buttonIcon={
                                  <ChangeCircleIcon sx={{ fontSize: 26 }} />
                                }
                                objectList={
                                  !!objectDrawerList ? objectDrawerList : []
                                }
                                objectType={[EnumObjectType.AppOwnerEmployees]}
                                handleChange={handleChangeResponsable}
                                buttonHeight={30}
                                buttonWidth={30}
                              />
                            </Grid>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={5} sx={{ m: 0, pl: 1, mb: 1 }}>
                        {state.ticketPost.ticket.fixedHourlyRate > 0 ? (
                          <Grid sx={[gridStyle.base, { height: 60 }]}>
                            <Typography sx={[TextStyle.textCenter, {}]}>
                              {t('Ticket.TicketCommunications.fixedHourlyRate')}
                            </Typography>
                            <Typography
                              sx={[
                                TextStyle.textCenter,
                                {
                                  pt: 1,
                                  fontSize: 20,
                                  color: 'secondary.text',
                                },
                              ]}
                            >
                              {state.ticketPost.ticket.fixedHourlyRate.toFixed(
                                2
                              )}
                            </Typography>
                          </Grid>
                        ) : state.ticketPost.ticketPost?.workRole?.id ? (
                          <Grid sx={[gridStyle.base, { height: 60 }]}>
                            <Stack
                              direction='row'
                              spacing={0}
                              justifyContent={'space-between'}
                            >
                              {state.ticketPost.ticket?.company?.workRole ? (
                                <Typography
                                  sx={[
                                    TextStyle.infoCenter,
                                    {
                                      pt: 0.5,
                                      fontSize: 12,
                                      letterSpacing: 2,
                                      color: 'primary.text',
                                    },
                                  ]}
                                >
                                  {t('Ticket.TicketCommunications.support')}
                                </Typography>
                              ) : (
                                <Typography
                                  sx={[
                                    TextStyle.infoCenter,
                                    {
                                      pt: 0.5,
                                      fontSize: 12,
                                      color: 'primary.text',
                                    },
                                  ]}
                                >
                                  {state.ticketPost.ticketPost?.workRole?.title}
                                </Typography>
                              )}

                              {state.ticketPost.ticket?.company?.workRole ? (
                                <> </>
                              ) : (
                                <ObjectDrawer
                                  buttonContext={t(
                                    'Ticket.TicketCommunications.selectWorkRole'
                                  )}
                                  buttonIcon={<ChangeCircleIcon />}
                                  objectList={
                                    !!objectDrawerList ? objectDrawerList : []
                                  }
                                  objectType={[EnumObjectType.WorkRole]}
                                  handleChange={handleChange}
                                  buttonHeight={30}
                                  buttonWidth={30}
                                />
                              )}
                            </Stack>

                            {state.ticketPost.ticketPost.isInHouse ? (
                              <Typography
                                sx={[
                                  TextStyle.textCenter,
                                  {
                                    pt: 0,
                                    fontSize: 18,
                                    color: 'secondary.text',
                                  },
                                ]}
                              >
                                {state.ticketPost.ticketPost?.workRole?.inHouseHourlyRate.toFixed(
                                  2
                                )}
                              </Typography>
                            ) : (
                              <Typography
                                sx={[
                                  TextStyle.textCenter,
                                  {
                                    pt: 0,
                                    fontSize: 18,
                                    color: 'secondary.text',
                                  },
                                ]}
                              >
                                {state.ticketPost.ticketPost?.workRole?.onSiteHourlyRate.toFixed(
                                  2
                                )}
                              </Typography>
                            )}
                          </Grid>
                        ) : (
                          <ObjectDrawer
                            buttonContext={t(
                              'Ticket.TicketCommunications.selectWorkRole'
                            )}
                            buttonIcon={null}
                            objectList={
                              !!objectDrawerList ? objectDrawerList : []
                            }
                            objectType={[EnumObjectType.WorkRole]}
                            handleChange={handleChange}
                            buttonHeight={60}
                            buttonWidth={'100%'}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 0.5 }}>
                        <SwitchType
                          isChecked={state.ticketPost.ticketPost.isBillable}
                          dispatch={dispatch}
                          type={'SET_BILLABLE'}
                          header={t('Ticket.TicketCommunications.Billable')}
                          showNotification={false}
                          disabled={
                            state.ticketPost.ticketPost.internal ||
                            !state.ticketPost.isEditable
                          }
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ pl: 0.5 }}>
                        <SwitchType
                          isChecked={state.ticketPost.ticketPost.isOvertime}
                          dispatch={dispatch}
                          type={'SET_OVERTIME'}
                          header={t('Ticket.TicketCommunications.overtime')}
                          showNotification={state.ticketPost.isShowOverTime}
                          disabled={false || !state.ticketPost.isEditable}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ m: 0, pl: 1 }}>
                        <SwitchType
                          isChecked={state.ticketPost.ticketPost.isInHouse}
                          dispatch={dispatch}
                          type={'SET_IS_IN_HOUSE'}
                          header={t('Ticket.TicketCommunications.inHouse')}
                          showNotification={false}
                          disabled={false || !state.ticketPost.isEditable}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* BILLABLE TIME BUTTONS */}
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Grid container sx={{ m: 0, pl: 1 }}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          m: 0,
                          p: 0,
                          pl: 1,
                          pr: 1,
                          mt: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          borderRadius: 2,
                          height: 60,
                        }}
                      >
                        <Stack direction='row' spacing={0} flexGrow={1}>
                          <Typography sx={[TextStyle.label, { width: '60%' }]}>
                            {t('Ticket.TicketCommunications.dateTime')}
                          </Typography>
                          <Typography
                            sx={[TextStyle.value, { pr: 2, pt: 0.5 }]}
                          >
                            {state.ticketPost.totalTime}
                          </Typography>
                          {state.ticketPost.isTotalTimeOpen ? (
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'SET_TOTAL_TIME_OPEN',
                                  payload: false,
                                });
                              }}
                            >
                              <KeyboardArrowUpIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'SET_TOTAL_TIME_OPEN',
                                  payload: true,
                                });
                                dispatch({
                                  type: 'SET_TOTAL_VALUE_OPEN',
                                  payload: false,
                                });
                              }}
                            >
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          )}
                        </Stack>
                        <Stack
                          sx={{}}
                          direction={'row'}
                          justifyContent='left'
                          alignItems='left'
                          width={280}
                          spacing={0.5}
                        >
                          {[15, 30, 45, 60].map((interval, index: number) => (
                            <Button
                              disabled={false || !state.ticketPost.isEditable}
                              sx={{ width: '20%', fontSize: 12, height: 26 }}
                              variant='contained'
                              key={index}
                              onClick={() =>
                                dispatch({
                                  type: 'SET_END_DATE',
                                  payload: dayjs(
                                    state.ticketPost.ticketPost.endTime
                                  ).add(interval, 'minute'),
                                })
                              }
                            >
                              {interval === 60 ? '+1:00' : `+0:${interval}`}
                            </Button>
                          ))}

                          {/* Reset Button */}
                          {true && (
                            <Button
                              disabled={false || !state.ticketPost.isEditable}
                              sx={{ width: '20%', fontSize: 12, height: 26 }}
                              variant='contained'
                              color='secondary'
                              onClick={() => {
                                dispatch({
                                  type: 'SET_START_DATE',
                                  payload: dayjs(new Date()),
                                });
                                dispatch({
                                  type: 'SET_END_DATE',
                                  payload: dayjs(new Date()),
                                });
                              }}
                            >
                              {t('Ticket.TicketCommunications.reset')}
                            </Button>
                          )}
                        </Stack>

                        {state.ticketPost.isTotalTimeOpen && (
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              width: '100%',
                              flexDirection: 'column',
                              backgroundColor: 'primary.main',
                              borderRadius: 2,
                              ml: 0,
                              mt: 0,
                              zIndex: 1000,
                              position: 'relative',
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 0,
                                p: 0,
                                pl: 1,
                                pr: 2,
                                mt: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              }}
                            >
                              <Stack direction={'row'} sx={{}}>
                                <Typography
                                  sx={{
                                    pt: 0.7,
                                    width: 100,
                                    letterSpacing: 1,
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    fontSize: 14,
                                  }}
                                >
                                  Start:
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DesktopDateTimePicker
                                    disabled={
                                      false || !state.ticketPost.isEditable
                                    }
                                    format='DD/MM/YYYY HH:mm'
                                    value={
                                      dayjs(
                                        state.ticketPost.ticketPost.startTime
                                      )
                                        ? state.ticketPost.ticketPost.startTime
                                        : dayjs(new Date())
                                    }
                                    onChange={(e) => {
                                      dispatch({
                                        type: 'SET_START_DATE',
                                        payload: dayjs(e),
                                      });
                                    }}
                                    ampm={false}
                                    views={[
                                      'year',
                                      'month',
                                      'day',
                                      'hours',
                                      'minutes',
                                    ]}
                                    slotProps={{
                                      textField: {
                                        sx: {
                                          '& .MuiInputBase-input': {
                                            fontSize: 14,
                                          },
                                          '& .MuiInputLabel-root': {
                                            fontSize: 14,
                                          },
                                        },
                                      } as any,
                                      openPickerIcon: {
                                        sx: {
                                          fontSize: 26,
                                        },
                                      } as any,
                                    }}
                                  />
                                </LocalizationProvider>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 0,
                                p: 0,
                                pl: 1,
                                pr: 2,
                                mt: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              }}
                            >
                              <Stack direction={'row'} sx={{}}>
                                <Typography
                                  sx={{
                                    pt: 0.7,
                                    width: 100,
                                    letterSpacing: 1,
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    fontSize: 14,
                                  }}
                                >
                                  {t('Ticket.TicketCommunications.end')}:
                                </Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DesktopDateTimePicker
                                    disabled={
                                      false || !state.ticketPost.isEditable
                                    }
                                    format='DD/MM/YYYY HH:mm'
                                    value={
                                      dayjs(state.ticketPost.ticketPost.endTime)
                                        ? state.ticketPost.ticketPost.endTime
                                        : null
                                    }
                                    onChange={(e) => {
                                      dispatch({
                                        type: 'SET_END_DATE',
                                        payload: dayjs(e),
                                      });
                                    }}
                                    ampm={false}
                                    views={[
                                      'year',
                                      'month',
                                      'day',
                                      'hours',
                                      'minutes',
                                    ]}
                                    slotProps={{
                                      textField: {
                                        sx: {
                                          '& .MuiInputBase-input': {
                                            fontSize: 14,
                                          },
                                          '& .MuiInputLabel-root': {
                                            fontSize: 14,
                                          },
                                        },
                                      } as any,
                                      openPickerIcon: {
                                        sx: {
                                          fontSize: 26,
                                        },
                                      } as any,
                                    }}
                                  />
                                </LocalizationProvider>
                              </Stack>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>

                      {/* ECONOMY VIEW */}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          m: 0,
                          p: 0,
                          pl: 1,
                          pr: 1,
                          mt: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          borderRadius: 2,
                          height: 60,
                        }}
                      >
                        <Stack direction='row' spacing={0} flexGrow={1}>
                          <Typography sx={[TextStyle.label, { width: '60%' }]}>
                            {t('Ticket.TicketCommunications.economy')}
                          </Typography>
                          <Typography
                            sx={[TextStyle.value, { pr: 2, pt: 0.5 }]}
                          >
                            {(
                              state.ticketPost.ticketPost.totalBillingValue || 0
                            ).toFixed(2)}
                          </Typography>
                          {state.ticketPost.isTotalValueOpen ? (
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'SET_TOTAL_VALUE_OPEN',
                                  payload: false,
                                });
                              }}
                            >
                              <KeyboardArrowUpIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'SET_TOTAL_VALUE_OPEN',
                                  payload: true,
                                });
                              }}
                            >
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          )}
                        </Stack>

                        {state.ticketPost.isTotalValueOpen && (
                          <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                            {
                              <EconomyView
                                ticketPostBilling={
                                  state.ticketPost.ticketPost.ticketPostBilling
                                }
                                state={state.ticketPost}
                              />
                            }
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* MESSAGE */}
                <Grid
                  container
                  spacing={0}
                  flexDirection={'row'}
                  sx={{ m: 0, p: 0, mt: 1 }}
                >
                  {/* NEW COMMUNICATION*/}

                  <Grid
                    item
                    xs={12}
                    sx={{
                      pl: 0.5,
                      pr: 0.5,
                      pt: 2,
                      backgroundColor: 'primary.main',
                    }}
                  >
                    <SearchFilter
                      Icon={TextsmsRoundedIcon}
                      Header={
                        isGuidEmpty(state.ticketPost.ticketPost.id)
                          ? `${t(
                              'Ticket.TicketCommunications.newCommunication'
                            )}`
                          : `${t(
                              'Ticket.TicketCommunications.editCommunication'
                            )}`
                      }
                      gfx={
                        <>
                          {state.ticketPost.ticketPost
                            .isBilled /*  || state.ticketPost.ticketPost.isEmailSendt  */ ? (
                            <Typography
                              sx={{
                                zIndex: 11,
                                position: 'absolute',
                                left: 400,
                                color: 'error.main',
                              }}
                            >
                              {state.ticketPost.ticketPost.isBilled &&
                                t('Ticket.TicketCommunications.isBilled')}
                              {/*  {state.ticketPost.ticketPost.isEmailSendt &&
                      t('Ticket.TicketCommunications.isEmailSendt')} */}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </>
                      }
                      filterValue={state.ticketPost.ticketPost.status}
                      handleFilterChange={(e: any) => {
                        dispatch({
                          type: 'CHANGE_STATUS',
                          payload: e,
                        });
                      }}
                      handleSearch={null}
                      selectList={ticketCommunicationStatus}
                    />
                  </Grid>
                  {/* NEW SCHEDULE TIME*/}
                  {state.ticketPost.scheduleTime &&
                    (state.ticketPost.ticketPost.status === 1 ||
                      state.ticketPost.ticketPost.status === 3 ||
                      state.ticketPost.ticketPost.status === 4) && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          key: 'scheduleTime',
                          border: 1,
                          borderColor: 'primary.back',
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          p: 1,
                          mb: 2,
                        }}
                      >
                        <ScheduleView
                          scheduleTime={state.ticketPost.scheduleTime}
                          handleChange={handleChangeSchedule}
                        />
                      </Grid>
                    )}
                  <Grid item xs={12} sx={{ m: 0, pr: 0 }}>
                    <Grid
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      <Grid
                        sx={{
                          m: 0,
                          pb: 1,
                          flexGrow: 1,
                          backgroundColor: 'primary.back',
                          borderRadius: 2,
                        }}
                      >
                        <TextField
                          sx={{ m: 0, p: 0, backgroundColor: 'primary.main' }}
                          value={state.ticketPost.ticketPost.message}
                          defaultValue={state.ticketPost.ticketPost.message}
                          label={t(
                            'Ticket.TicketCommunications.internalTicket'
                          )}
                          variant='filled'
                          multiline={true}
                          disabled={false || !state.ticketPost.isEditable}
                          minRows={12}
                          maxRows={12}
                          onChange={(e) => {
                            dispatch({
                              type: 'EDIT_COMMENT',
                              payload: e.target.value,
                            });
                          }}
                        />
                        <Stack
                          direction='row'
                          spacing={0}
                          flexGrow={1}
                          sx={{ backgroundColor: 'rgb' }}
                        >
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                              fontSize: 14,
                              flexGrow: 1,
                            }}
                          >
                            {t(
                              'Ticket.TicketCommunications.internalTicketMessage'
                            )}
                          </Typography>

                          <SwitchType
                            isChecked={state.ticketPost.ticketPost.internal}
                            dispatch={dispatch}
                            type={'SET_INTERNAL'}
                            header={null}
                            showNotification={false}
                            disabled={false || !state.ticketPost.isEditable}
                          />
                        </Stack>
                        {/* EMAIL TO */}
                        <Stack
                          direction='row'
                          spacing={0}
                          flexGrow={1}
                          sx={{
                            backgroundColor:
                              state.ticketPost.ticketPost.status === 3 ||
                              state.ticketPost.ticketPost.status === 4 ||
                              state.ticketPost.ticketPost.status === 5 ||
                              state.ticketPost.ticketPost.status === 6
                                ? 'success.dark'
                                : 'transparent',
                            border: 2,
                            borderColor:
                              state.ticketPost.ticketPost.status === 3 ||
                              state.ticketPost.ticketPost.status === 4 ||
                              state.ticketPost.ticketPost.status === 5 ||
                              state.ticketPost.ticketPost.status === 6
                                ? 'success.light'
                                : 'transparent',
                            borderRadius: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              width: 140,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                              fontSize: 14,
                            }}
                          >
                            {t('Ticket.TicketCommunications.sendEmailTo')}:
                          </Typography>

                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'left',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              color: 'secondary.text',
                              flexGrow: 1,
                            }}
                          >
                            {state?.ticketPost.ticket?.contact?.email}
                          </Typography>
                          <SwitchType
                            isChecked={state.ticketPost.ticketPost.isEmailSent}
                            dispatch={dispatch}
                            type={'IS_SEND_EMAIL'}
                            header={null}
                            showNotification={false}
                            disabled={
                              state.ticketPost.ticketPost.internal ||
                              !state.ticketPost.isEditable ||
                              !state.ticketPost.isEmailAllowed
                            }
                          />
                        </Stack>
                        {/* EMAIL TO */}
                        <Stack
                          direction='row'
                          spacing={0}
                          flexGrow={1}
                          sx={{
                            backgroundColor:
                              state.ticketPost.ticketPost.status === 3 ||
                              state.ticketPost.ticketPost.status === 4 ||
                              state.ticketPost.ticketPost.status === 5 ||
                              state.ticketPost.ticketPost.status === 6
                                ? 'success.dark'
                                : 'transparent',
                            border: 2,
                            borderColor:
                              state.ticketPost.ticketPost.status === 3 ||
                              state.ticketPost.ticketPost.status === 4 ||
                              state.ticketPost.ticketPost.status === 5 ||
                              state.ticketPost.ticketPost.status === 6
                                ? 'success.light'
                                : 'transparent',
                            borderRadius: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              width: 180,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                              fontSize: 14,
                            }}
                          >
                            {t('Ticket.TicketCommunications.ccEmailTo')}:
                          </Typography>

                          <TextField
                            name='name'
                            onFocus={handleFocus}
                            sx={{
                              fontSize: 10,
                              color: 'info.text',
                              '& .Mui-focused': {
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              },
                              backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            }}
                            disabled={true}
                            value={state.ticket.ccEmail}
                            onChange={(e) => handleChangeCC(e)}
                          />
                          <SwitchType
                            isChecked={state.ticketPost.ticketPost.isCCSent}
                            dispatch={dispatch}
                            type={'IS_SEND_EMAIL_CC'}
                            header={null}
                            showNotification={false}
                            disabled={
                              state.ticketPost.ticketPost.internal ||
                              !state.ticketPost.isEditable ||
                              !state.ticketPost.isEmailAllowed
                            }
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            {/* ATTATCHMENTS */}
            <Grid item xs={12} sx={{ pr: 0.5, pl: 0.5, direction: 'row' }}>
              <Accordion
                disableGutters
                sx={{
                  backgroundColor: 'transparent',
                  border: 0,
                  m: 1,
                }}
              >
                <FormAccordionSummary
                  Icon={AttachFileIcon}
                  title={t('Ticket.TicketCommunications.attachments')}
                />
                <AccordionDetails>
                  <DocumentUploader
                    setDocuments={(files: any) =>
                      setSelectedDocuments((prevDocs: any) => [
                        ...prevDocs,
                        ...files,
                      ])
                    }
                  />
                  <List>
                    {state.ticketPost.ticketPost.attachments?.map(
                      (document: any, index: number) => {
                        //console.log('document:', document);
                        return (
                          <ListItem key={index}>
                            {document.url ? (
                              <a
                                rel='noopener noreferrer'
                                target='_blank'
                                href={document.url}
                                style={{ color: 'white' }}
                              >
                                {document.fileName}
                              </a>
                            ) : (
                              document.file.name
                            )}
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </List>
          {/* FOTTER */}
          {/* SUBMIT*/}
          <Grid item xs={12} sx={{ pr: 0.5, pl: 0.5, direction: 'row' }}>
            <Stack direction={'column'}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                {isValidGuid(state.ticketPost.ticketPost.id) && (
                  <Button
                    sx={{
                      width: '25%',
                      backgroundColor: 'primary.back',
                    }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      dispatch({
                        type: 'REMOVE_TICKET_POST',
                        payload: state.ticketPost.ticketPost.id,
                      });
                      dispatch({
                        type: 'SET_IS_EDITABLE',
                        payload: true,
                      });
                    }}
                    variant='contained'
                  >
                    {t('Ticket.TicketCommunications.Cancel')}
                  </Button>
                )}
                <Button
                  disabled={!state.ticketPost.isEditable}
                  sx={{
                    width: '50%',
                    // backgroundColor: 'secondary.main',
                  }}
                  type='submit'
                  onClick={(e: any) => {
                    e.preventDefault();
                    onTicketPostSubmit();
                    dispatch({
                      type: 'SET_IS_EDITABLE',
                      payload: false,
                    });
                  }}
                  id={
                    isGuidEmpty(state.ticketPost.ticketPost.id)
                      ? `${t('Ticket.TicketCommunications.submit')}`
                      : `${t('Ticket.TicketCommunications.confirmEdit')}`
                  }
                  variant='contained'
                >
                  {isGuidEmpty(state.ticketPost.ticketPost.id)
                    ? `${t('Ticket.TicketCommunications.submit')}`
                    : `${t('Ticket.TicketCommunications.confirmEdit')}`}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
