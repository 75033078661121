import { ICompanyViewModel, ThirdPartEnum } from 'Interfaces/ICompaniesConfig';
import { IImportEmployeeAddViewModel } from './ICloudFactoryInterfaces';

export const ToAddEmployees = (
  data: any,
  userId: string,
  companyId: string,
  departmentId: string
): IImportEmployeeAddViewModel[] => {
  if (!data) return [] as IImportEmployeeAddViewModel[];
  let newList: IImportEmployeeAddViewModel[] =
    [] as IImportEmployeeAddViewModel[];

  data.forEach((employee: any) => {
    if (employee.username && employee.username.includes('#EXT#')) {
      return;
    }

    let productIds: string[] = employee.products
      ? employee.products.map((product: any) => product.displayName)
      : [];

    let name = employee.displayName;
    if (employee.firstName && employee.lastName)
      name = employee.firstName + ' ' + employee.lastName;

    let newListItem: IImportEmployeeAddViewModel = {
      name: name,
      creatorId: userId,
      companyId: companyId,
      phone: employee.mobilePhone ?? '+47 000 00 000',
      email: employee.username,
      departmentId: departmentId,
      primaryContact: false,
      legacyId: 0,
      thirdPartyId: employee.id,
      thirdParty: ThirdPartEnum.CloudFactory,
      productIds: productIds,
      isEmailFoundInBluu: false,
      isNotEmailInCloudFactory: false,
    };
    newList.push(newListItem);
  });
  return newList;
};
