import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { findHours } from './findHours';
import {
  ITicketPostBillingViewModel,
  ITicketPostInterface,
} from 'Interfaces/ITicketConfig';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { GenerateGuid } from './GuidChecks';
dayjs.extend(utc);

const ticketPostBillingEmpty: ITicketPostBillingViewModel = {
  id: 'new',
  ticketPostId: 'new',
  isBillable: false,
  isBilled: false,
  workCost: 0,
  workHours: 0,
  workBillableValue: 0,
  notBillableHours: 0,
  notBillableCost: 0,
  notBillableBillingValue: 0,
  eveningCost: 0,
  eveningHours: 0,
  eveningBillableValue: 0,
  midDayCost: 0,
  midDayHours: 0,
  midDayBillableValue: 0,
  nightCost: 0,
  nightHours: 0,
  nightBillableValue: 0,
};

const roundUpToNearestQuarter = (hours: number): number => {
  const quarters = Math.ceil(hours * 4) / 4;
  return parseFloat(quarters.toFixed(2)); // Ensure the result is a number with 2 decimal places
};

export const calculateTicketPostBilling = (
  ticketPost: ITicketPostInterface
) => {
  let ticketPostBilling: ITicketPostBillingViewModel =
    ticketPost.ticketPostBilling || ticketPostBillingEmpty;

  console.log('ATP CalculateTotalBillingValue :- BEFORE', ticketPost.ticketPostBilling);
  const rates: IRateViewModel[] = ticketPost.rates;

  const workRole: IWorkRoleViewModel | null = ticketPost.ticketPost.workRole;

  const fixedHourlyRate = 0;

  const startDate1 = dayjs.utc(ticketPost.ticketPost.startTime);
  const startDate = startDate1.local();

  const endDate1 = dayjs.utc(ticketPost.ticketPost.endTime);
  const endDate = endDate1.local();
  /*  console.log(
    'calculateTicketPostBilling - startDate: ',
    startDate,
    'endDate: ',
    endDate
  ); */

  /*  if (ticketPost.ticketPost.ticketPostBilling !== null) {
    ticketPostBilling = ticketPost.ticketPost.ticketPostBilling;
  } */

  const totalNotBillableHours = roundUpToNearestQuarter(
    findHours('notBillable', startDate, endDate, rates)
  );

  let workRate = ticketPost.ticketPost.isInHouse
    ? workRole?.inHouseHourlyRate || 0
    : workRole?.onSiteHourlyRate || 0;

  if (fixedHourlyRate > 0) {
    workRate = fixedHourlyRate;
  }

  let workCost = ticketPost.ticketPost.isInHouse
    ? workRole?.inHouseHourlyRateCost || 0
    : workRole?.onSiteHourlyRateCost || 0;
  if (fixedHourlyRate > 0) {
    workCost = workRole?.onSiteHourlyRateCost || 600;
  }

  // BILLABLE WITH OVERTIME
  if (ticketPost.ticketPost.isBillable && ticketPost.ticketPost.isOvertime) {
    ticketPostBilling.isBillable = true;

    // NIGHT
    ticketPostBilling.nightHours = roundUpToNearestQuarter(
      findHours('night', startDate, endDate, rates)
    );

    let overtimeCost = ticketPost.ticketPost.isOvertime
      ? ticketPostBilling.nightHours * (workCost * 2)
      : workCost;
    ticketPostBilling.nightCost = overtimeCost;

    overtimeCost = ticketPost.ticketPost.isOvertime
      ? ticketPostBilling.nightHours * (workRate * 2)
      : workRate;
    ticketPostBilling.nightBillableValue = overtimeCost;

    // WORK
    ticketPostBilling.workHours = roundUpToNearestQuarter(
      findHours('work', startDate, endDate, rates)
    );
    overtimeCost = ticketPost.ticketPost.isOvertime
      ? ticketPostBilling.workHours * (workCost * 1)
      : workCost;
    ticketPostBilling.workCost = overtimeCost;

    overtimeCost = ticketPost.ticketPost.isOvertime
      ? ticketPostBilling.workHours * (workRate * 1)
      : workRate;
    ticketPostBilling.workBillableValue = overtimeCost;

    // MID DAY
    ticketPostBilling.midDayHours = roundUpToNearestQuarter(
      findHours('midDay', startDate, endDate, rates)
    );

    overtimeCost = ticketPost.ticketPost.isOvertime
      ? ticketPostBilling.midDayHours * (workCost * 1.5)
      : workCost;
    ticketPostBilling.midDayCost = overtimeCost;

    overtimeCost = ticketPost.ticketPost.isOvertime
      ? ticketPostBilling.midDayHours * (workRate * 1.5)
      : workRate;
    ticketPostBilling.midDayBillableValue = overtimeCost;

    // EVENING
    ticketPostBilling.eveningHours = roundUpToNearestQuarter(
      findHours('evening', startDate, endDate, rates)
    );

    overtimeCost = ticketPost.ticketPost.isOvertime
      ? ticketPostBilling.eveningHours * (workCost * 1.5)
      : workCost;
    ticketPostBilling.eveningCost = overtimeCost;

    overtimeCost = ticketPost.ticketPost.isOvertime
      ? ticketPostBilling.eveningHours * (workRate * 1.5)
      : workRate;
    ticketPostBilling.eveningBillableValue = overtimeCost;

    ticketPostBilling.notBillableHours = 0;
    ticketPostBilling.notBillableBillingValue = 0;
    ticketPostBilling.notBillableCost = 0;
  } else if (ticketPost.ticketPost.isBillable) {
    // BILLABLE
    ticketPostBilling.isBillable = true;
    ticketPostBilling.workHours = totalNotBillableHours;
    ticketPostBilling.workBillableValue = totalNotBillableHours * workRate;
    ticketPostBilling.workCost = totalNotBillableHours * workCost;

    ticketPostBilling.eveningCost = 0;
    ticketPostBilling.eveningHours = 0;
    ticketPostBilling.eveningBillableValue = 0;
    ticketPostBilling.midDayCost = 0;
    ticketPostBilling.midDayHours = 0;
    ticketPostBilling.midDayBillableValue = 0;
    ticketPostBilling.nightCost = 0;
    ticketPostBilling.nightHours = 0;
    ticketPostBilling.nightBillableValue = 0;
    ticketPostBilling.notBillableHours = 0;
    ticketPostBilling.notBillableBillingValue = 0;
    ticketPostBilling.notBillableCost = 0;
  } else {
    // NOT BILLABLE
    ticketPostBilling.isBillable = false;
    ticketPostBilling.notBillableHours = totalNotBillableHours;
    ticketPostBilling.notBillableBillingValue =
      totalNotBillableHours * workRate;
    ticketPostBilling.notBillableCost = totalNotBillableHours * workCost;

    ticketPostBilling.workHours = 0;
    ticketPostBilling.workBillableValue = 0;
    ticketPostBilling.workCost = 0;
    ticketPostBilling.eveningCost = 0;
    ticketPostBilling.eveningHours = 0;
    ticketPostBilling.eveningBillableValue = 0;
    ticketPostBilling.midDayCost = 0;
    ticketPostBilling.midDayHours = 0;
    ticketPostBilling.midDayBillableValue = 0;
    ticketPostBilling.nightCost = 0;
    ticketPostBilling.nightHours = 0;
    ticketPostBilling.nightBillableValue = 0;
  }

  if (ticketPostBilling.id === null || ticketPostBilling.id === 'new') {
    ticketPostBilling.id = GenerateGuid();
  }

  if (
    ticketPostBilling.ticketPostId === null ||
    ticketPostBilling.ticketPostId === 'new'
  ) {
    ticketPostBilling.ticketPostId = GenerateGuid();
  }
  console.log('ATP CalculateTotalBillingValue :- AFTER', ticketPostBilling);
  return ticketPostBilling;
};
