import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { IBillingCompanyProductViewModel } from 'Interfaces/BillingConfig';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import React from 'react';
import dayjs from 'dayjs';
import { pdfStyle } from './PDFStyle';

interface Props {
  data: {
    agreementProductList: IBillingCompanyProductViewModel[];
    agreementInvoiceValue: number;
  };
}

// Funksjon for å gruppere produkter etter `product.id` og en fast måned om `lastBilled` er tom
const groupProducts = (
  products: IBillingCompanyProductViewModel[]
): IBillingCompanyProductViewModel[] => {
  const grouped: { [key: string]: IBillingCompanyProductViewModel } = {};

  products.forEach((companyProduct) => {
    const product = companyProduct.product; 
    const month = companyProduct.lastBilled
      ? dayjs(companyProduct.lastBilled).format('YYYY-MMMM')
      : 'N/A'; // Bruker 'N/A' som standardmåned hvis `lastBilled` er tom
    const key = `${month}-${product.id}`; // Kombinasjon av standard måned og produkt-id

    if (!grouped[key]) {
      grouped[key] = { ...companyProduct };
    } else {
      grouped[key].quantity += companyProduct.quantity;
    }
  });

  return Object.values(grouped); // Returnerer grupperte produkter som en liste
};

export const PDFAgreementView = ({ data }: Props) => {
  const productList = Array.isArray(data.agreementProductList) ? data.agreementProductList : [];
  const groupedProducts = groupProducts(productList).sort((a, b) => {
  const nameA = a.product.name;
  const nameB = b.product.name;
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
});


  return (
    <>
      <View style={pdfStyle.column50}>
        <Text style={pdfStyle.textLeft}>AGREEMENTS</Text>
      </View>
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column20}>
          <Text style={pdfStyle.textLeft}>Month</Text>
        </View>
        <View style={pdfStyle.column35}>
          <Text style={pdfStyle.textLeft}>Product Name</Text>
        </View>
        <View style={pdfStyle.column10}>
          <Text style={pdfStyle.textRight}>Quantity</Text>
        </View>
        <View style={pdfStyle.column15}>
          <Text style={pdfStyle.textRight}>Retail Price</Text>
        </View>
        <View style={pdfStyle.column20}>
          <Text style={pdfStyle.textRight}>Line Total</Text>
        </View>
      </View>

      <View style={pdfStyle.divider}></View>

      {groupedProducts.map((productItem, index) => {
        const retailPrice = productItem.retailPrice ?? 0;
        const lineTotal = productItem.quantity * retailPrice;

        return (
          <View key={index} style={pdfStyle.containerView}>
            <View style={pdfStyle.rowView}>
              <View style={pdfStyle.column20}>
                <Text style={pdfStyle.textLeft}>
                  {productItem.lastBilled
                    ? dayjs(productItem.lastBilled).format('YYYY-MMMM')
                    : 'N/A'}
                </Text>
              </View>
              <View style={pdfStyle.column35}>
                <Text style={pdfStyle.textLeft}>{productItem.product.name}</Text>
              </View>
              <View style={pdfStyle.column10}>
                <Text style={pdfStyle.textRight}>{productItem.quantity}</Text>
              </View>
              <View style={pdfStyle.column15}>
                <Text style={pdfStyle.textRight}>
                  {retailPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </View>
              <View style={pdfStyle.column20}>
                <Text style={pdfStyle.textRight}>
                  {lineTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </View>
            </View>
          </View>
        );
      })}

      <View style={pdfStyle.divider} />
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>AGREEMENT TOTAL</Text>
        </View>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textRight}>
            {data.agreementInvoiceValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>
      <View style={pdfStyle.avsnitt} />
      <View style={pdfStyle.avsnitt} />
    </>
  );
};
