import { useEffect, useReducer, useState } from 'react';
import { useGet, usePost } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import GetPriorityColor from 'functions/GetPriorityColor';
import {
  ButtonGroup,
  Grid,
  List,
  Paper,
  Stack,
  Typography,
  Select,
  Button,
  TextField,
  MenuItem,
  ListItem,
  IconButton,
  Box,
  AccordionDetails,
  Accordion,
} from '@mui/material';
import { ticketType } from 'data/enum';
import EditIcon from '@mui/icons-material/Edit';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';

import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import {
  IEmployeeTicketViewModel,
  IEmployeeViewModel,
  IEmployeeWithCompanyViewModel,
  INewTicketAddWithProductInitialState,
  ITicketAddWithProductViewModel,
  ITicketCompanyViewModel,
  ITicketPostAddViewModel,
} from '../../Interfaces/ITicketConfig';
import {
  EnumBillingType,
  EnumBoard,
  EnumJobType,
  EnumObjectType,
  EnumPlacement,
  EnumPriority,
  EnumScheduleType,
  EnumSupportBilling,
  EnumTicketStatus,
  IDeliveryAddressViewModel,
  IMobileAnswersViewModel,
  IScheduleViewModel,
  addressListData,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { ScheduleView } from 'components/ui/ScheduleView';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { IAutoAnswersViewModel } from 'pages/AutoAnswersConfig';
import { SingleAutoAnswerView } from 'components/ui/SingleAutoAnswerView';
import { SingleTicketContact } from 'components/ui/SingleTicketContact';
import { SingleTicketContactProduct } from 'components/ui/SingleTicketContactProduct';
import { ProductCardView } from 'components/ui/ProductCardView';
import { FormAccordionSummary, FormBox } from 'components/form';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import userAtom from 'data/atoms/userAtom';
import { useGetObjectList } from 'functions/useGetObjectList';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  boxStyle,
  gridStyle,
  ProductFormStyle,
  TextStyle,
} from 'data/FontStyle';
import { SwitchType } from 'components/ui/SwitchType';
import { SingleTicketEmployeeView } from 'components/ui/SingleTicketEmployeeView';
import { ConvertToEmployee } from 'functions/ConverToEmployee';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { EconomyView } from 'functions/EconomySimpleView';
import { SearchFilter } from 'components/ui/SearchFilter';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import { isGuidEmpty, isValidGuid } from 'functions/GuidChecks';
import { ticketCommunicationStatus } from 'data/enum';
import AttachFileIcon from '@mui/icons-material/AttachFile';
//@ts-ignore
import DocumentUploader from '../../components/ui/DocumentUploader';
//@ts-ignore
import { RootReducer, initialRootState } from './Components/RotTicketReducer';
import {
  Business,
  Dashboard as DashboardIcon,
  DevicesOther,
  ShoppingCart,
  SupportAgent,
} from '@mui/icons-material';
import { CompanyProductDrawer } from 'pages/Companies/Components/CompanyProductDrawer';
import { ConvertStateToTicketUpdateWithProductViewModel } from './TicketDetails';
import { ConvertToPutTicketPost } from 'functions/ConvertToPutTicketPost';
import { ConvertToAttachment } from 'functions/ConvertToAttachment';
import { GetWorkRole } from 'functions/GetWorkRole';
import { ConvertToAddTicketPost } from 'functions/ConvertToAddTicketPost';
let _ = require('lodash');

function sleep(ms: any) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const ConvertStateToTicketAddWithProductViewModel = (
  state: INewTicketAddWithProductInitialState,
  user: any
) => {
  let deliveryAddressPostalCode = state.deliveryAddress?.postalCode
    ? state.deliveryAddress.postalCode.toString()
    : '';

  let obj: ITicketAddWithProductViewModel = {
    board: state.board,
    priority: state.priority,
    status: state.status,
    ticketNo: state.ticketNo,
    deliveryDate: state.scheduleTime.deliveryDate,
    scheduled: state.scheduleTime.startDate,
    ETCHours: state.scheduleTime.estimatedTime.hours,
    ETCMinutes: state.scheduleTime.estimatedTime.minutes,
    jobType: state.jobType,
    supportTypeId: state.supportType.id,
    errorDescriptionId: state.errorDescription.id,
    companyId: state.companyId,
    contactId: state.contactId,
    creatorId: user.id,
    responsibleId: state.responsibleId || null,

    shippingCost: state.shippingCost,

    deliveryStreet: state.deliveryAddress?.street ?? '',
    deliveryPostalCode: deliveryAddressPostalCode,
    deliveryArea: state.deliveryAddress.area || '',
    deliveryCity: state.deliveryAddress.city || '',
    deliveryCountry: state.deliveryAddress.country || '',
    deliveryPostbox: state.deliveryAddress.postBox || '',

    attention: state.deliveryAddress.attention || '',
    comment: state.comment,
    ccEmail: state.ccEmail,
    fixedHourlyRate: 0,
    fixedTime: 0,
    fixedPrice: 0,
    maxTime: 0,
    maxPrice: 0,

    productBilling: state.productBilling,
    billing: state.billing,
    title: state.title,
    companyProductId: state.companyProduct.id,

    companyProducts: state.companyProducts,
    productCatalog: state.productCatalog,
    storageProducts: state.storageProducts,
    unassignedCompanyProducts: state.unassignedCompanyProducts,
    techniciansId: state.techniciansId,
    supportBilling: EnumSupportBilling.BillOnCompletion,
  };

  return obj;
};

export interface ticketAndPostState {
  ticket: ITicketAddWithProductViewModel;
  ticketPost: ITicketPostAddViewModel;
}
export const ticketAndPost: ticketAndPostState = {
  ticket: {} as ITicketAddWithProductViewModel,
  ticketPost: {} as ITicketPostAddViewModel,
};

const initialState: INewTicketAddWithProductInitialState = {
  ticketNo: '',
  board: EnumBoard.Support,
  priority: EnumPriority.NORMAL,
  status: EnumTicketStatus.New,
  company: {} as ITicketCompanyViewModel,
  contact: {} as IEmployeeViewModel,
  responsible: {} as IEmployeeViewModel,
  shippingCost: 0,
  deliveryAddress: {
    id: '',
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  } as IDeliveryAddressViewModel,
  scheduleTime: {
    id: '0',
    scheduleType: EnumScheduleType.None,
    deliveryDate: dayjs(new Date()),
    startDate: dayjs(new Date()),
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  } as IScheduleViewModel,
  jobType: EnumJobType.Support,
  supportType: {} as IMobileAnswersViewModel,
  errorDescription: {} as IMobileAnswersViewModel,
  companyProduct: {} as ICompanyProductViewModel,
  technicians: [] as IEmployeeTicketViewModel[],
  comment: '',
  ccEmail: '',
  fixedHourlyRate: 0,
  fixedTime: 0,
  fixedPrice: 0,
  maxTime: 0,
  maxPrice: 0,
  productBilling: true,
  updated: dayjs(new Date()),
  billing: EnumBillingType.SystemPeriod,
  title: '',
  companyProducts: [] as ICompanyProductViewModel[],
  companyId: '',
  contactId: '',
  responsibleId: '',
  techniciansId: [],
  addressList: addressListData,
  productCatalog: [] as ICompanyProductViewModel[],
  storageProducts: [] as ICompanyProductViewModel[],
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  isDirty: false,
  supportBilling: EnumSupportBilling.BillOnCompletion,
};

export const ToAddTicket = (ticket: ITicketAddWithProductViewModel) => {
  let obj: ITicketAddWithProductViewModel = {
    board: ticket.board,
    priority: ticket.priority,
    status: ticket.status,
    ticketNo: ticket.ticketNo,
    deliveryDate: ticket.deliveryDate,
    scheduled: ticket.scheduled,
    ETCHours: ticket.ETCHours,
    ETCMinutes: ticket.ETCMinutes,
    jobType: ticket.jobType,
    supportTypeId: ticket.supportTypeId,
    errorDescriptionId: ticket.errorDescriptionId,
    companyId: ticket.companyId,
    contactId: ticket.contactId,
    creatorId: ticket.creatorId,
    responsibleId: ticket.responsibleId || null,
    shippingCost: ticket.shippingCost,
    deliveryStreet: ticket.deliveryStreet,
    deliveryPostalCode: ticket.deliveryPostalCode,
    deliveryArea: ticket.deliveryArea,
    deliveryCity: ticket.deliveryCity,
    deliveryCountry: ticket.deliveryCountry,
    deliveryPostbox: ticket.deliveryPostbox,
    attention: ticket.attention,
    comment: ticket.comment,
    ccEmail: ticket.ccEmail,
    fixedHourlyRate: ticket.fixedHourlyRate,
    fixedTime: ticket.fixedTime,
    fixedPrice: ticket.fixedPrice,
    maxTime: ticket.maxTime,
    maxPrice: ticket.maxPrice,
    productBilling: ticket.productBilling,
    billing: ticket.billing,
    title: ticket.title,
    companyProductId: ticket.companyProductId,
    companyProducts: ticket.companyProducts,
    productCatalog: ticket.productCatalog,
    storageProducts: ticket.storageProducts,
    unassignedCompanyProducts: ticket.unassignedCompanyProducts,
    techniciansId: ticket.techniciansId,
    supportBilling: ticket.supportBilling,
  };

  return obj;
};

export const NewTicket = () => {
  /* INITIALIZE */
  // let navigate = useNavigate();
  const { t } = useTranslation();
  const { height } = useWindowsDimension();
  const [owner, setOwner] = useAtom(ownerAtom);
  const [user, setUser] = useAtom(userAtom);
  //const [state, dispatch] = useReducer(reducer, initialState);
  const [state, dispatch] = useReducer(RootReducer, initialRootState);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);

  // states
  const [options, setOptions] = useState<any>([]);
  const loading = options.length === 0;

  // api
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let companyId = 'new';
  let employeeId = 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );
  useEffect(() => {
    // setResource(objectDrawerList[Number(EnumObjectType.Resource)].objectList);
    console.log('ghg objectDrawerList', objectDrawerList);
    setIsObjectList(false);
  }, [objectDrawerList]);

  const { data: mobileAutoAnswersData } = useGet(
    rq.mobileAutoAnswers('BBB00BBB-6C0C-4C0C-8C0C-BBB001BBB001', true),
    true
  );
  const { data: employeeData } = useGet(rq.allEmployees(true), true);
  let isEnabled =
    state.company?.id !== 'new' && state.employee.employee?.id !== 'new'
      ? true
      : false;
  console.log(
    'ghg isEnabled',
    isEnabled,
    state.company?.id,
    state.employee.employee?.id
  );

  isEnabled = state.company.id !== 'new' ? true : false;
  const postTicketAndPost = usePost(rqPost.ticketAndPost(isEnabled), isEnabled);

  /* FUNCTIONS */
  const isEditDisabled = () => {
    let isDisabled: boolean = false;
    if (state.employee?.employee?.id === 'new') {
      isDisabled = true;
    }
    if (state.ticket.title.length <= 0) {
      isDisabled = true;
    }
    if (
      state.ticketPost.ticketPost.message.length <= 0 &&
      state.ticketPost.ticketPost.status !== 15
    ) {
      isDisabled = true;
    }
    return isDisabled;
  };

  const autoAnswerIsObject = (object: any) => {
    return typeof object === 'object';
  };
  useEffect(() => {
    console.log('FORM STATE', state);
  }, [state.ticket, state]);

  // HANDLES
  const handleChange = (
    event: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {};

  const handleChangeWorkRole = (
    event: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.WorkRole:
        // dispatch({ type: 'SET_TICKET_WORK_ROLE', payload: selectedObject });
        break;
    }
  };
  const handleRemoveProduct = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'REMOVE_PRODUCT', payload: companyProduct });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'REMOVE_UNASSIGNED_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
      case EnumObjectType.CompanyProduct:
        if (companyProduct.resourceId) {
          // Utfører første dispatch-kall
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              resourceId: null,
              resource: null,
              placement: EnumPlacement.NONE,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_DIRTY',
            payload: true,
          });
        } else {
          // Utfører dispatch-kall hvis betingelsen ikke er oppfylt
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              resourceId: state.ticket.id,
              placement: EnumPlacement.RESOURCE,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_DIRTY',
            payload: false,
          });
        }
        break;

      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'REMOVE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
    }

    let isStorageProductEmpty =
      state.ticket.storageProducts.length === 0 &&
      state.ticket.productCatalog.length === 0
        ? 0
        : 1;
    dispatch({
      type: 'SET_BOARD',
      payload: isStorageProductEmpty,
    });
  };
  const handleChangeCompanyProductWidthError = (
    event: any,
    companyProduct: ICompanyProductViewModel
  ) => {
    dispatch({ type: 'SET_COMPANY_PRODUCT', payload: companyProduct });
  };
  const handleResponsibleChange = (
    event: any,
    responsible: IEmployeeViewModel
  ) => {
    dispatch({ type: 'SET_RESPONSIBLE', payload: responsible });
  };
  const handlePriorityChange = (e: any, priority: EnumPriority) => {
    e.preventDefault();
    dispatch({ type: 'SET_PRIORITY', payload: priority });
  };

  const handleChangeEmployee = (
    e: any,
    employee: IEmployeeWithCompanyViewModel
  ) => {
    e.preventDefault();
    /*  let tmp: IDeliveryAddressViewModel[] = GenerateAddressList(
      state.addressList,
      owner,
      employee
    );
    dispatch({
      type: 'UPDATE_ADDRESS_LIST',
      payload: tmp,
    }); */
    console.log('ghg handleChangeEmployee', employee);

    // SETTER COMAPNY PÅ TICKET OG TICKETPOST
    dispatch({
      type: 'SET_COMPANY',
      payload: employee.company,
    });
    dispatch({
      type: 'SET_TICKET_COMPANY',
      payload: employee.company,
    });
    // SETTER EMPLOYEE PÅ TICKET OG TICKETPOST
    dispatch({
      type: 'SET_CONTACT',
      payload: employee,
    });
    dispatch({
      type: 'SET_EMPLOYEE',
      payload: employee,
    });
    dispatch({
      type: 'SET_TICKET_POST_RECEIVER',
      payload: employee,
    });
    // SETTER PRIORITY PÅ TICKET OG TICKETPOST
    dispatch({
      type: 'SET_PRIORITY',
      payload: employee.company.priority,
    });
    // HER SETTER VI WORKROLE FOR TICKET OG TICKET POST
    let workRole = GetWorkRole(
      state.company.workRole,
      state.employee.employee.workRole,
      user.workRole
    );
    console.log('GetWorkRole ute', workRole);
    console.log('GetWorkRole employee', employee);
    dispatch({
      type: 'SET_WORK_ROLE',
      payload: workRole,
    });

    setIsObjectList(true);
  };
  const handleChangeAutoAnswer = (
    event: any,
    autoAnswer: IAutoAnswersViewModel
  ) => {
    console.log('handleChangeAutoAnswer', autoAnswer);
    // console.log('handleChangeAutoAnswer', autoAnswer);
    dispatch({
      type: 'SET_AUTO_ANSWER',
      payload: autoAnswer,
    });
  };
  const handleChangeSchedule = (changeType: string, e: any) => {
    let scheduleTime = { ...state.ticket.scheduleTime };
    switch (changeType.toUpperCase()) {
      case 'HOURS':
        scheduleTime.estimatedTime.hours = e;
        break;
      case 'MINUTES':
        scheduleTime.estimatedTime.minutes = e;
        break;
    }
    dispatch({
      type: 'SET_SCHEDULE_TIME',
      payload: scheduleTime,
    });
  };
  const handleChangeDeliveryAddress = (
    deliveryAddress: IDeliveryAddressViewModel
  ) => {
    // console.log('handleChangeDeliveryAddress', deliveryAddress);
    // console.log('handleChangeDeliveryAddress', deliveryAddress);
    if (deliveryAddress && deliveryAddress.id) {
      dispatch({
        type: 'SET_DELIVERY_ADDRESS',
        payload: { id: deliveryAddress.id },
      });
    }
  };
  const handleTechniciansChange = (
    event: any,
    technician: IEmployeeViewModel
  ) => {
    // console.log('handleTechniciansChange', technician);
    // console.log('handleTechniciansChange', technician);
    dispatch({
      type: 'SET_TECHNICIANS',
      payload: [technician],
    });
  };

  const handleRemoveTechnician = (
    event: React.ChangeEvent<HTMLInputElement>,
    technician: IEmployeeViewModel
  ) => {
    // console.log('handleRemoveTechnician', technician);
    // console.log('handleRemoveTechnician', technician);
    dispatch({ type: 'REMOVE_TECHNICIAN', payload: technician });
  };

  const handleBlur = (e: any) => {
    e.preventDefault();
    // console.log('blur', e.target.value);
    // console.log('blur', e.target.value);
    let comment = e.target.value;
    dispatch({ type: 'EDIT_COMMENT', payload: comment });
  };

  const handleChangeTitle = (e: any) => {
    dispatch({ type: 'EDIT_TITLE', payload: e.target.value });
    dispatch({ type: 'SET_DIRTY', payload: true });
  };

  const handleChangeProductData = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    console.log('handleChangeProductData', objectType, companyProduct);
    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'UPDATE_PRODUCT', payload: companyProduct });
        break;
      case EnumObjectType.CompanyProduct:
        dispatch({ type: 'UPDATE_COMPANY_PRODUCT', payload: companyProduct });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'UPDATE_UNASSIGNED_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        break;
      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'UPDATE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        break;
    }
    console.log('handleChangeProductData - x', state.ticket.productCatalog);
  };
  const handleChangeResponsable = (e: any, employee: IEmployeeViewModel) => {
    e.preventDefault();

    /* let ticketResponsible = {
      ticketId: state.ticketPost.ticket.id,
      responsibleId: employee.id,
    };
    //putTicketResponsible.mutate(ticketResponsible);
    let ticket = _.cloneDeep(state.ticketPost.ticket);
    ticket.responsible = employee; */
    console.log('yyy handleChangeResponsable', employee);
    dispatch({
      type: 'SET_TICKET_POST_RESPONSIBLE',
      payload: employee,
    });
    dispatch({
      type: 'SET_RESPONSIBLE',
      payload: employee,
    });
  };
  const handleAssigneMe = () => {
    let ticketResponsible = {
      ticketId: state.ticketPost.ticket.id,
      responsibleId: user.id,
    };
    //putTicketResponsible.mutate(ticketResponsible);
    let ticket = _.cloneDeep(state.ticketPost.ticket);
    ticket.responsible = ConvertToEmployee(user);
    /*  dispatch({
        type: 'SET_TICKET',
        payload: ticket,
      }); */
  };
  const toggleDisabled = () => {
    setIsDisabled(!isDisabled);
  };
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleChangeCC = (e: any) => {
    dispatch({ type: 'EDIT_CC', payload: e.target.value });
  };
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(3e3); // For demo purposes.

      if (active) {
        setOptions([...ticketType]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!employeeData) return;
    console.log('GetWorkRole employeeData', employeeData);
    let _allEmployees: IEmployeeViewModel[] = _.cloneDeep(
      employeeData?.allEmployees
    );
    dispatch({
      type: 'SET_EMPLOYEES',
      payload: _allEmployees,
    });
  }, [employeeData]);

  useEffect(() => {
    if (mobileAutoAnswersData) {
      // console.log('mobileAutoAnswersData', mobileAutoAnswersData);
      let autoAnswers = mobileAutoAnswersData.mobileAutoAnswers;
      dispatch({ type: 'SET_AUTO_ANSWERS', payload: autoAnswers });
      dispatch({ type: 'SET_AUTO_ANSWER', payload: autoAnswers[5] });
    }
  }, [mobileAutoAnswersData]);

  const onSubmit = async () => {
    let _state = _.cloneDeep(state.ticketPost);
    let newTicket = ConvertStateToTicketUpdateWithProductViewModel(
      state.ticket,
      user
    );

    let ticketPost = ConvertToAddTicketPost(_state, user);
    if (selectedDocuments.length > 0) {
      ticketPost.attachments = ConvertToAttachment(selectedDocuments) ?? [];
    } else {
      ticketPost.attachments = [];
    }

    let ticketAndPost = {
      ticket: newTicket,
      ticketPost: ticketPost,
    };

    console.log('papa TICKETPOST POST', ticketAndPost);

      postTicketAndPost.mutate(ticketAndPost, {
        onSuccess: (res: any) => {
          console.log('papa postTicketPost', res);
        },
      }); 
  };
  useEffect(() => {
    // console.log('...selected documents', selectedDocuments);
    if (selectedDocuments.length > 0) {
      dispatch({
        type: 'CHANGE_ATTACHMENTS',
        payload: selectedDocuments,
      });
    }
  }, [selectedDocuments]);
  // RENDER
  return (
    <Grid container sx={{ m: 0, p: 0, display: 'flex', flexDirection: 'row' }}>
      <Grid
        item
        xs={12}
        sx={{
          mb: 1,
        }}
      >
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            borderRadius: 2,
            backgroundColor: 'primary.main',
          }}
        >
          {/* HEADER  */}
          <Grid container display={'flex'} direction='row' flexGrow={1}>
            <Grid item xs={4} display={'flex'} flexDirection={'row'}>
              <SupportAgent sx={{ fontSize: 32, m: 0 }} />
              <Typography
                sx={{
                  m: 0,
                  ml: 0.5,
                  pt: 1,
                  flexGrow: 1,
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold',
                  fontSize: 14,
                  letterSpacing: 2,
                }}
              >
                {t('Ticket.newTicket')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                flexGrow: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Grid item xs={3} sx={{ pr: 2 }}>
                <Select
                  sx={{ width: '100%', p: 0, mt: -0.5 }}
                  labelId='Board'
                  id='Board'
                  variant='standard'
                  value={state.ticket.board}
                  label='Board'
                  onChange={(e) => {
                    dispatch({ type: 'SET_BOARD', payload: e.target.value });
                  }}
                >
                  <MenuItem value={0}>{t('Ticket.sale')}</MenuItem>
                  <MenuItem value={1}>{t('Ticket.support')}</MenuItem>
                  <MenuItem value={2}>{t('Ticket.project')}</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* LEFT SIDE  */}
      <Grid
        item
        xs={5}
        sx={{
          m: 0,
          pr: 0.5,
        }}
      >
        <Paper
          variant='elevation3'
          sx={{
            height: Math.ceil(height - 102),
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'primary.main',
            p: 1,
          }}
        >
          <Grid
            container
            sx={{
              m: 0,
              p: 0,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {/* TICKET HEADER */}
            <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
              <FormBox Icon={SupportAgent} title={t('Ticket.ticketTitle')} />
            </Grid>
            {/* TICKET TITLE */}
            <Grid
              item
              xs={12}
              sx={{ mb: 1 }}
              display={'flex'}
              flexDirection={'row'}
            >
              <TextField
                autoFocus
                id='name'
                label='Title'
                type='text'
                fullWidth
                variant='standard'
                value={state.ticket?.title || ''}
                onChange={(e) => handleChangeTitle(e)}
                onFocus={handleFocus}
                sx={{ mr: 2, backgroundColor: 'rgba(0,0,0,0.1)' }}
              />
            </Grid>
            {/* ASSIGN CUSTOMER EMPLOYEE HEADER */}
            <Grid item xs={12} sx={{ p: 0, mb: 1 }}>
              <FormBox
                Icon={PersonSearchRoundedIcon}
                title={t('Ticket.assignCompanyEmployee')}
              />
            </Grid>
            <Typography
              sx={{
                m: 0,
                pt: 1,
                flexGrow: 1,
                textTransform: 'uppercase',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'normal',
                fontSize: 12,
                letterSpacing: 2,
                width: '43%',
              }}
            >
              {t('Ticket.selectCompany')}
            </Typography>
            <Typography
              sx={{
                m: 0,
                pt: 1,
                flexGrow: 1,
                textTransform: 'uppercase',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'normal',
                fontSize: 12,
                letterSpacing: 2,
                width: '40%',
              }}
            >
              {t('Ticket.selectEmployee')}
            </Typography>
            {/* ASSIGN CUSTOMER EMPLOYEE */}
            <Grid item xs={12} sx={{ p: 0, mb: 0 }}>
              {state.ticket.contactId !== null ? (
                <Stack direction='column'>
                  <SingleTicketContact
                    employee={state.ticket.contact}
                    company={state.company}
                    priority={state.ticket.priority}
                  />
                  <Grid
                    item
                    sx={{
                      position: 'relative',
                      bottom: 78,
                      right: 10,
                      // left: 0,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ObjectDrawer
                      buttonContext={'Select Employee'}
                      buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.allEmployees]}
                      handleChange={handleChangeEmployee}
                      buttonHeight={30}
                      buttonWidth={30}
                    />
                  </Grid>
                </Stack>
              ) : (
                <ObjectDrawer
                  buttonContext={'Select Employee'}
                  buttonIcon={null}
                  objectList={!!objectDrawerList ? objectDrawerList : []}
                  objectType={[EnumObjectType.allEmployees]}
                  handleChange={handleChangeEmployee}
                  buttonHeight={82}
                  buttonWidth={'100%'}
                />
              )}
            </Grid>
            {/* CC HEADER */}
            <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 0, mt: -4 }}>
              <FormBox
                Icon={ContactMailRoundedIcon}
                title={t('Ticket.TicketDetails.ticketTypes.cc')}
              />
            </Grid>
            {/* CC */}
            <Grid item xs={12} sx={{ pr: 2, mb: 1 }}>
              <TextField
                name='cc'
                type='email'
                variant='standard'
                onFocus={handleFocus}
                sx={{ ...ProductFormStyle.textField }}
                label={
                  <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                    {t('Ticket.TicketDetails.ticketTypes.cc')}
                  </Typography>
                }
                value={state.ticket.ccEmail}
                onChange={(e) => handleChangeCC(e)}
              />
            </Grid>
            {/* PRIORITY HEADER */}
            <Grid item xs={12} sx={{ p: 0, mb: 1, mt: 0 }}>
              <FormBox
                Icon={PriorityHighRoundedIcon}
                title={t('Priority.header')}
              />
            </Grid>
            {/* PRIORITY BUTTONS  */}
            <Grid
              item
              xs={12}
              sx={{
                p: 0,
                mb: 2,
                flexGrow: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ButtonGroup
                variant='contained'
                aria-label='outlined primary button group'
                size='small'
                sx={{ width: '100%', backgroundColor: 'red' }}
              >
                <Button
                  key='NONE'
                  sx={{
                    width: '20%',
                    backgroundColor:
                      state.ticket.priority === EnumPriority.NONE
                        ? GetPriorityColor(state.ticket.priority)
                        : 'primary.back',
                    '&:hover': {
                      backgroundColor: GetPriorityColor(EnumPriority.NONE),
                    },
                  }}
                  onClick={(e) => {
                    handlePriorityChange(e, 0);
                  }}
                >
                  {t('Priority.none')}
                </Button>
                <Button
                  key='LOW'
                  sx={{
                    width: '20%',
                    backgroundColor:
                      state.ticket.priority === EnumPriority.LOW
                        ? GetPriorityColor(state.ticket.priority)
                        : 'primary.back',
                    '&:hover': {
                      backgroundColor: GetPriorityColor(EnumPriority.LOW),
                    },
                  }}
                  onClick={(e) => {
                    handlePriorityChange(e, 1);
                  }}
                >
                  {t('Priority.low')}
                </Button>
                <Button
                  key='NORMAL'
                  sx={{
                    width: '20%',
                    backgroundColor:
                      state.ticket.priority === EnumPriority.NORMAL
                        ? GetPriorityColor(state.ticket.priority)
                        : 'primary.back',
                    '&:hover': {
                      backgroundColor: GetPriorityColor(EnumPriority.NORMAL),
                    },
                  }}
                  onClick={(e) => {
                    handlePriorityChange(e, 2);
                  }}
                >
                  {t('Priority.normal')}
                </Button>
                <Button
                  key='HIGH'
                  sx={{
                    width: '20%',
                    backgroundColor:
                      state.ticket.priority === EnumPriority.HIGH
                        ? GetPriorityColor(state.ticket.priority)
                        : 'primary.back',
                    '&:hover': {
                      backgroundColor: GetPriorityColor(EnumPriority.HIGH),
                    },
                  }}
                  onClick={(e) => {
                    handlePriorityChange(e, 3);
                  }}
                >
                  {t('Priority.high')}
                </Button>

                <Button
                  key='CRITICAL'
                  sx={{
                    width: '20%',
                    backgroundColor:
                      state.ticket.priority === EnumPriority.CRITICAL
                        ? GetPriorityColor(state.ticket.priority)
                        : 'primary.back',
                    '&:hover': {
                      backgroundColor: GetPriorityColor(EnumPriority.CRITICAL),
                    },
                  }}
                  onClick={(e) => {
                    handlePriorityChange(e, 4);
                  }}
                >
                  {t('Priority.critical')}
                </Button>
              </ButtonGroup>
            </Grid>
            {/* ISSUE HEADER */}
            <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
              <FormBox
                Icon={DevicesOtherRoundedIcon}
                title={t('Ticket.type')}
              />
            </Grid>
            {/* ISSUE TYPE */}
            <Grid item xs={5} sx={{ m: 0, pr: 1, mb: -1 }}>
              {!autoAnswerIsObject(state.ticket?.supportType) ? (
                <>
                  <ObjectDrawer
                    buttonContext={'Select Issue Type'}
                    buttonIcon={null}
                    objectList={!!objectDrawerList ? objectDrawerList : []}
                    objectType={[EnumObjectType.IssueType]}
                    handleChange={handleChangeAutoAnswer}
                    buttonHeight={80}
                    buttonWidth={'100%'}
                  />
                </>
              ) : (
                <>
                  <SingleAutoAnswerView
                    autoAnswer={state.ticket?.supportType}
                  />
                  <Grid
                    item
                    sx={{
                      position: 'relative',
                      bottom: 78,
                      right: 4,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ObjectDrawer
                      buttonContext={'Select Issue Type'}
                      buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.IssueType]}
                      handleChange={handleChangeAutoAnswer}
                      buttonHeight={30}
                      buttonWidth={30}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={7} sx={{ m: 0, pr: 1, mb: -1 }}>
              <Typography
                sx={{
                  m: 0,
                  pt: 1,
                  pl: 1,
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'normal',
                  fontSize: 12,
                  letterSpacing: 2,
                  width: '100%',
                }}
              >
                {t('Ticket.typeInfo')}
              </Typography>
            </Grid>
            {/* ISSUE PRODUCT */}
            <Grid item xs={5} sx={{ m: 0, pl: 1, mb: -1 }}>
              {state.ticket?.companyProductWithError ? (
                <>
                  <SingleTicketContactProduct
                    product={state.ticket.companyProductWithError}
                  />
                  <Grid
                    item
                    sx={{
                      position: 'relative',
                      bottom: 78,
                      right: 8,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {state.employee.employee.id !== 'new' &&
                      state.company.id !== 'new' && (
                        <CompanyProductDrawer
                          buttonIcon={
                            <ChangeCircleIcon sx={{ fontSize: 26 }} />
                          }
                          buttonContext={'Select Company Product'}
                          companyId={state.company?.id ?? 'new'}
                          employeeId={state.employee.employee.id ?? 'new'}
                          handleChange={handleChangeCompanyProductWidthError}
                        />
                      )}
                  </Grid>
                </>
              ) : (
                <CompanyProductDrawer
                  buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                  buttonContext={'Select Company Product'}
                  companyId={state.company?.id ?? 'new'}
                  employeeId={state.employee.employee.id ?? 'new'}
                  handleChange={handleChangeCompanyProductWidthError}
                />
              )}
            </Grid>
            <Grid item xs={7} sx={{ m: 0, pr: 1, mb: -1 }}>
              <Typography
                sx={{
                  m: 0,
                  pt: 1,
                  pl: 1,
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'normal',
                  fontSize: 12,
                  letterSpacing: 2,
                  width: '100%',
                }}
              >
                {t('Ticket.tickerProduct')}
              </Typography>
            </Grid>

            {/* JOBTYPE HEADER */}
             <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
              <FormBox Icon={DevicesOtherRoundedIcon} title={'Job Type'} />
            </Grid> 
            {/* JOB TYPE */}
             <Grid item xs={3} sx={{ pr: 2 }}>
              <Select
                sx={{ width: '100%', p: 0, mt: -0.5 }}
                labelId='JobType'
                id='JobType'
                variant='standard'
                value={state.ticket.jobType}
                label='Job Type'
                onChange={(e) => {
                  dispatch({ type: 'SET_JOB_TYPE', payload: e.target.value });
                }}
              >
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={1}>Support</MenuItem>
                <MenuItem value={2}>OnBoarding</MenuItem>
                <MenuItem value={3}>OffBoarding</MenuItem>
                <MenuItem value={4}>ReplaceProduct</MenuItem>
                <MenuItem value={5}>AssignProduct</MenuItem>
                <MenuItem value={6}>NewProduct</MenuItem>
                <MenuItem value={7}>Service</MenuItem>
              </Select>
            </Grid>

            {/* PRODUCT HEADER */}
            <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
              <FormBox
                Icon={DevicesOtherRoundedIcon}
                title='Sales products'
                FormBTN={
                  <ObjectDrawer
                    buttonContext={'Add Product'}
                    buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
                    objectList={!!objectDrawerList ? objectDrawerList : []}
                    objectType={[
                      EnumObjectType.Product,
                      EnumObjectType.StorageProduct,
                      EnumObjectType.unassignedCompanyProduct,
                      // EnumObjectType.Package,
                    ]}
                    handleChange={handleChange}
                    buttonHeight={26}
                    buttonWidth={26}
                  />
                }
              />
            </Grid>
            {/* PRODUCTS */}
            <Grid item xs={12} sx={{ m: 0, p: 0 }}>
              <List
                sx={{
                  width: '100%',
                  height: Math.ceil(100),
                  p: 2,
                  overflow: 'auto',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                }}
              >
                {state.ticket?.unassignedCompanyProducts?.map(
                  (companyProduct: ICompanyProductViewModel, index: number) => {
                    return !!companyProduct ? (
                      <ListItem sx={{ mb: 1, p: 0 }} key={index * 100}>
                        <ProductCardView
                          companyProduct={companyProduct}
                          location={EnumObjectType.Resource}
                          objectType={EnumObjectType.unassignedCompanyProduct}
                          handleRemoveProduct={handleRemoveProduct}
                          handleChange={handleChangeProductData}
                        />
                      </ListItem>
                    ) : null;
                  }
                )}
                {state.ticket?.storageProducts?.map(
                  (companyProduct: ICompanyProductViewModel, index: number) => {
                    return !!companyProduct ? (
                      <ListItem key={index * 1000} sx={{ mb: 1, p: 0 }}>
                        <ProductCardView
                          companyProduct={companyProduct}
                          location={EnumObjectType.Resource}
                          objectType={EnumObjectType.StorageProduct}
                          handleRemoveProduct={handleRemoveProduct}
                          handleChange={handleChangeProductData}
                        />
                      </ListItem>
                    ) : null;
                  }
                )}
                {state.ticket?.productCatalog?.map(
                  (companyProduct: ICompanyProductViewModel, index: number) => {
                    return !!companyProduct ? (
                      <ListItem sx={{ mb: 1, p: 0 }} key={index * 10000}>
                        <ProductCardView
                          companyProduct={companyProduct}
                          location={EnumObjectType.Resource}
                          objectType={EnumObjectType.Product}
                          handleRemoveProduct={handleRemoveProduct}
                          handleChange={handleChangeProductData}
                        />
                      </ListItem>
                    ) : null;
                  }
                )}
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* RIGHT SIDE */}
      <Grid
        item
        xs={7}
        sx={{
          pl: 0.5,
          pr: 0.5,
        }}
      >
        <Grid
          container
          sx={{
            p: 2,
            borderRadius: 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'primary.main',
            height: Math.ceil(height - 102),
          }}
        >
          {/* TICKET TITLE */}
          <Grid item sx={{ flexGrow: 1, height: 20 }}>
            <Typography
              sx={{
                ...ProductFormStyle.SubHeader,
                ...{
                  pl: 2,
                  fontSize: '18px',
                  mb: 1,
                },
              }}
            >
              {state?.ticket?.title || ''}
            </Typography>
          </Grid>
          {/* TICKET NO */}
          <Grid item sx={{}}>
            <Typography
              sx={{
                ...ProductFormStyle.SubHeader,
                ...{
                  pl: 2,
                  fontSize: '18px',
                  mb: 1,
                  pr: 2,
                  letterSpacing: 2,
                  color: 'info.text',
                },
              }}
            >
              {state.ticketPost?.ticket?.ticketNo || ''}
            </Typography>
          </Grid>
          <List
            sx={{
              height: Math.ceil(height - 242),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.main',
              overflow: 'auto',
            }}
          >
            <Grid container key={'container'} sx={{}}>
              <Stack direction={'column'} key='stack' flex={1} sx={{}}>
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    mb: 1,
                  }}
                >
                  {/* RESPONISBLE */}
                  <Grid item xs={7} sx={{ m: 0, p: 0, mb: 1 }}>
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Grid item xs={7} sx={{ m: 0, p: 0, mb: 1 }}>
                        {state.ticket?.responsible ? (
                          <Grid
                            item
                            xs={12}
                            sx={{ p: 0, mb: 0.5, position: 'relative' }}
                          >
                            <SingleTicketEmployeeView
                              employee={state.ticket.responsible}
                              priority={0}
                            />
                            <Grid
                              item
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: 3,
                              }}
                            >
                              <ObjectDrawer
                                buttonContext={t(
                                  'Ticket.TicketDetails.selectEmployee'
                                )}
                                buttonIcon={
                                  <ChangeCircleIcon sx={{ fontSize: 26 }} />
                                }
                                objectList={
                                  !!objectDrawerList ? objectDrawerList : []
                                }
                                objectType={[EnumObjectType.AppOwnerEmployees]}
                                handleChange={handleChangeResponsable}
                                buttonHeight={30}
                                buttonWidth={30}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Box sx={[boxStyle.base, { height: 60 }]}>
                            <Button
                              sx={{
                                mx: 'auto',
                                width: '100%',
                                height: '100%',
                                borderRadius: 3,
                              }}
                              variant='contained'
                              onClick={(e) => {
                                handleAssigneMe();
                              }}
                            >
                              <Typography sx={[TextStyle.text, {}]}>
                                {t(
                                  'Ticket.TicketCommunications.assignCustomerEmployee'
                                )}
                              </Typography>
                            </Button>
                            <Grid
                              item
                              sx={{
                                position: 'relative',
                                top: 0,
                                right: 3,
                              }}
                            >
                              <ObjectDrawer
                                buttonContext={t(
                                  'Ticket.TicketDetails.selectEmployee'
                                )}
                                buttonIcon={
                                  <ChangeCircleIcon sx={{ fontSize: 26 }} />
                                }
                                objectList={
                                  !!objectDrawerList ? objectDrawerList : []
                                }
                                objectType={[EnumObjectType.AppOwnerEmployees]}
                                handleChange={handleChangeResponsable}
                                buttonHeight={30}
                                buttonWidth={30}
                              />
                            </Grid>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={5} sx={{ m: 0, pl: 1, mb: 1 }}>
                        {state?.ticketPost?.ticket?.fixedHourlyRate > 0 ? (
                          <Grid sx={[gridStyle.base, { height: 60 }]}>
                            <Typography sx={[TextStyle.textCenter, {}]}>
                              {t('Ticket.TicketCommunications.fixedHourlyRate')}
                            </Typography>
                            <Typography
                              sx={[
                                TextStyle.textCenter,
                                {
                                  pt: 1,
                                  fontSize: 20,
                                  color: 'secondary.text',
                                },
                              ]}
                            >
                              {state.ticketPost.ticket.fixedHourlyRate.toFixed(
                                2
                              )}
                            </Typography>
                          </Grid>
                        ) : state?.ticketPost?.ticketPost?.workRole?.id ? (
                          <Grid sx={[gridStyle.base, { height: 60 }]}>
                            <Stack
                              direction='row'
                              spacing={0}
                              justifyContent={'space-between'}
                            >
                              {state?.ticketPost.ticket?.company?.workRole ? (
                                <Typography
                                  sx={[
                                    TextStyle.infoCenter,
                                    {
                                      pt: 0.5,
                                      fontSize: 12,
                                      letterSpacing: 2,
                                      color: 'primary.text',
                                    },
                                  ]}
                                >
                                  {t('Ticket.TicketCommunications.support')}
                                </Typography>
                              ) : (
                                <Typography
                                  sx={[
                                    TextStyle.infoCenter,
                                    {
                                      pt: 0.5,
                                      fontSize: 12,
                                      color: 'primary.text',
                                    },
                                  ]}
                                >
                                  {state?.ticketPost.ticketPost?.workRole
                                    ?.title || ''}
                                </Typography>
                              )}

                              {state?.ticketPost?.ticket?.company?.workRole ? (
                                <> </>
                              ) : (
                                <ObjectDrawer
                                  buttonContext={t(
                                    'Ticket.TicketCommunications.selectWorkRole'
                                  )}
                                  buttonIcon={<ChangeCircleIcon />}
                                  objectList={
                                    !!objectDrawerList ? objectDrawerList : []
                                  }
                                  objectType={[EnumObjectType.WorkRole]}
                                  handleChange={() => handleChangeWorkRole}
                                  buttonHeight={30}
                                  buttonWidth={30}
                                />
                              )}
                            </Stack>

                            {state?.ticketPost?.ticketPost?.isInHouse ? (
                              <Typography
                                sx={[
                                  TextStyle.textCenter,
                                  {
                                    pt: 0,
                                    fontSize: 18,
                                    color: 'secondary.text',
                                  },
                                ]}
                              >
                                {state.ticketPost.ticketPost?.workRole?.inHouseHourlyRate.toFixed(
                                  2
                                )}
                              </Typography>
                            ) : (
                              <Typography
                                sx={[
                                  TextStyle.textCenter,
                                  {
                                    pt: 0,
                                    fontSize: 18,
                                    color: 'secondary.text',
                                  },
                                ]}
                              >
                                {state?.ticketPost?.ticketPost?.workRole?.onSiteHourlyRate.toFixed(
                                  2
                                ) || 0}
                              </Typography>
                            )}
                          </Grid>
                        ) : (
                          <ObjectDrawer
                            buttonContext={t(
                              'Ticket.TicketCommunications.selectWorkRole'
                            )}
                            buttonIcon={null}
                            objectList={
                              !!objectDrawerList ? objectDrawerList : []
                            }
                            objectType={[EnumObjectType.WorkRole]}
                            handleChange={handleChangeWorkRole}
                            buttonHeight={60}
                            buttonWidth={'100%'}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 0.5 }}>
                        <SwitchType
                          isChecked={state?.ticketPost?.ticketPost?.isBillable}
                          dispatch={dispatch}
                          type={'SET_BILLABLE'}
                          header={t('Ticket.TicketCommunications.Billable')}
                          showNotification={false}
                          disabled={
                            state.ticketPost.ticketPost.internal ||
                            !state.ticketPost.isEditable
                          }
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ pl: 0.5 }}>
                        <SwitchType
                          isChecked={state.ticketPost.ticketPost.isOvertime}
                          dispatch={dispatch}
                          type={'SET_OVERTIME'}
                          header={t('Ticket.TicketCommunications.overtime')}
                          showNotification={state.ticketPost.isShowOverTime}
                          disabled={false || !state.ticketPost.isEditable}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ m: 0, pl: 1 }}>
                        <SwitchType
                          isChecked={state.ticketPost.ticketPost.isInHouse}
                          dispatch={dispatch}
                          type={'SET_IS_IN_HOUSE'}
                          header={t('Ticket.TicketCommunications.inHouse')}
                          showNotification={false}
                          disabled={false || !state.ticketPost.isEditable}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* BILLABLE TIME BUTTONS */}
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Grid container sx={{ m: 0, pl: 1 }}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          m: 0,
                          p: 0,
                          pl: 1,
                          pr: 1,
                          mt: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          borderRadius: 2,
                          height: 60,
                        }}
                      >
                        <Stack direction='row' spacing={0} flexGrow={1}>
                          <Typography sx={[TextStyle.label, { width: '60%' }]}>
                            {t('Ticket.TicketCommunications.dateTime')}
                          </Typography>
                          <Typography
                            sx={[TextStyle.value, { pr: 2, pt: 0.5 }]}
                          >
                            {state.ticketPost.totalTime}
                          </Typography>
                          {state.ticketPost.isTotalTimeOpen ? (
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'SET_TOTAL_TIME_OPEN',
                                  payload: false,
                                });
                              }}
                            >
                              <KeyboardArrowUpIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'SET_TOTAL_TIME_OPEN',
                                  payload: true,
                                });
                                dispatch({
                                  type: 'SET_TOTAL_VALUE_OPEN',
                                  payload: false,
                                });
                              }}
                            >
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          )}
                        </Stack>
                        <Stack
                          sx={{}}
                          direction={'row'}
                          justifyContent='left'
                          alignItems='left'
                          width={280}
                          spacing={0.5}
                        >
                          {[15, 30, 45, 60].map((interval, index: number) => (
                            <Button
                              disabled={false || !state.ticketPost.isEditable}
                              sx={{ width: '20%', fontSize: 12, height: 26 }}
                              variant='contained'
                              key={index}
                              onClick={() => {
                                dispatch({
                                  type: 'SET_END_DATE',
                                  payload: dayjs(
                                    state.ticketPost.ticketPost.endTime
                                  ).add(interval, 'minute'),
                                });
                              }}
                            >
                              {interval === 60 ? '+1:00' : `+0:${interval}`}
                            </Button>
                          ))}

                          {/* Reset Button */}
                          {true && (
                            <Button
                              disabled={false || !state.ticketPost.isEditable}
                              sx={{ width: '20%', fontSize: 12, height: 26 }}
                              variant='contained'
                              color='secondary'
                              onClick={() => {
                                dispatch({
                                  type: 'SET_START_DATE',
                                  payload: dayjs(new Date()),
                                });
                                dispatch({
                                  type: 'SET_END_DATE',
                                  payload: dayjs(new Date()),
                                });
                              }}
                            >
                              {t('Ticket.TicketCommunications.reset')}
                            </Button>
                          )}
                        </Stack>

                        {state.ticketPost.isTotalTimeOpen && (
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              width: '100%',
                              flexDirection: 'column',
                              backgroundColor: 'primary.main',
                              borderRadius: 2,
                              ml: 0,
                              mt: 0,
                              zIndex: 1000,
                              position: 'relative',
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 0,
                                p: 0,
                                pl: 1,
                                pr: 2,
                                mt: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              }}
                            >
                              <Stack direction={'row'} sx={{}}>
                                <Typography
                                  sx={{
                                    pt: 0.7,
                                    width: 100,
                                    letterSpacing: 1,
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    fontSize: 14,
                                  }}
                                >
                                  Start:
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DesktopDateTimePicker
                                    disabled={
                                      false || !state.ticketPost.isEditable
                                    }
                                    format='DD/MM/YYYY HH:mm'
                                    value={
                                      dayjs(
                                        state.ticketPost.ticketPost.startTime
                                      )
                                        ? state.ticketPost.ticketPost.startTime
                                        : dayjs(new Date())
                                    }
                                    onChange={(e) => {
                                      /* dispatch({
                                             type: 'SET_START_DATE',
                                             payload: dayjs(e),
                                           }); */
                                    }}
                                    ampm={false}
                                    views={[
                                      'year',
                                      'month',
                                      'day',
                                      'hours',
                                      'minutes',
                                    ]}
                                    slotProps={{
                                      textField: {
                                        sx: {
                                          '& .MuiInputBase-input': {
                                            fontSize: 14,
                                          },
                                          '& .MuiInputLabel-root': {
                                            fontSize: 14,
                                          },
                                        },
                                      } as any,
                                      openPickerIcon: {
                                        sx: {
                                          fontSize: 26,
                                        },
                                      } as any,
                                    }}
                                  />
                                </LocalizationProvider>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 0,
                                p: 0,
                                pl: 1,
                                pr: 2,
                                mt: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              }}
                            >
                              <Stack direction={'row'} sx={{}}>
                                <Typography
                                  sx={{
                                    pt: 0.7,
                                    width: 100,
                                    letterSpacing: 1,
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    fontSize: 14,
                                  }}
                                >
                                  {t('Ticket.TicketCommunications.end')}:
                                </Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DesktopDateTimePicker
                                    disabled={
                                      false || !state.ticketPost.isEditable
                                    }
                                    format='DD/MM/YYYY HH:mm'
                                    value={
                                      dayjs(state.ticketPost.ticketPost.endTime)
                                        ? state.ticketPost.ticketPost.endTime
                                        : null
                                    }
                                    onChange={(e) => {
                                      /*  dispatch({
                                             type: 'SET_END_DATE',
                                             payload: dayjs(e),
                                           }); */
                                    }}
                                    ampm={false}
                                    views={[
                                      'year',
                                      'month',
                                      'day',
                                      'hours',
                                      'minutes',
                                    ]}
                                    slotProps={{
                                      textField: {
                                        sx: {
                                          '& .MuiInputBase-input': {
                                            fontSize: 14,
                                          },
                                          '& .MuiInputLabel-root': {
                                            fontSize: 14,
                                          },
                                        },
                                      } as any,
                                      openPickerIcon: {
                                        sx: {
                                          fontSize: 26,
                                        },
                                      } as any,
                                    }}
                                  />
                                </LocalizationProvider>
                              </Stack>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>

                      {/* ECONOMY VIEW */}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          m: 0,
                          p: 0,
                          pl: 1,
                          pr: 1,
                          mt: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          borderRadius: 2,
                          height: 60,
                        }}
                      >
                        <Stack direction='row' spacing={0} flexGrow={1}>
                          <Typography sx={[TextStyle.label, { width: '60%' }]}>
                            {t('Ticket.TicketCommunications.economy')}
                          </Typography>
                          <Typography
                            sx={[TextStyle.value, { pr: 2, pt: 0.5 }]}
                          >
                            {(
                              state.ticketPost.ticketPost.totalBillingValue || 0
                            ).toFixed(2)}
                          </Typography>
                          {state.ticketPost.isTotalValueOpen ? (
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'SET_TOTAL_VALUE_OPEN',
                                  payload: false,
                                });
                              }}
                            >
                              <KeyboardArrowUpIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'SET_TOTAL_VALUE_OPEN',
                                  payload: true,
                                });
                              }}
                            >
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          )}
                        </Stack>

                        {state.ticketPost.isTotalValueOpen && (
                          <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                            {
                              <EconomyView
                                ticketPostBilling={
                                  state.ticketPost.ticketPost.ticketPostBilling
                                }
                                state={state.ticketPost}
                              />
                            }
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* MESSAGE */}
                <Grid
                  container
                  spacing={0}
                  flexDirection={'row'}
                  sx={{ m: 0, p: 0, mt: 1 }}
                >
                  {/* NEW COMMUNICATION*/}

                  <Grid
                    item
                    xs={12}
                    sx={{
                      pl: 0.5,
                      pr: 0.5,
                      pt: 2,
                      backgroundColor: 'primary.main',
                    }}
                  >
                    <SearchFilter
                      Icon={TextsmsRoundedIcon}
                      Header={
                        isGuidEmpty(state.ticketPost.ticketPost.id)
                          ? `${t(
                              'Ticket.TicketCommunications.newCommunication'
                            )}`
                          : `${t(
                              'Ticket.TicketCommunications.editCommunication'
                            )}`
                      }
                      gfx={
                        <>
                          {state.ticketPost.ticketPost
                            .isBilled /*  || state.ticketPost.ticketPost.isEmailSendt  */ ? (
                            <Typography
                              sx={{
                                zIndex: 11,
                                position: 'absolute',
                                left: 400,
                                color: 'error.main',
                              }}
                            >
                              {state.ticketPost.ticketPost.isBilled &&
                                t('Ticket.TicketCommunications.isBilled')}
                              {/*  {state.ticketPost.ticketPost.isEmailSendt &&
                           t('Ticket.TicketCommunications.isEmailSendt')} */}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </>
                      }
                      filterValue={state.ticketPost.ticketPost.status}
                      handleFilterChange={(e: any) => {
                        dispatch({
                          type: 'CHANGE_STATUS',
                          payload: e,
                        });
                      }}
                      handleSearch={null}
                      selectList={ticketCommunicationStatus}
                    />
                  </Grid>
                  {/* NEW SCHEDULE TIME*/}
                  {state.ticketPost.scheduleTime &&
                    (state.ticketPost.ticketPost.status === 1 ||
                      state.ticketPost.ticketPost.status === 3 ||
                      state.ticketPost.ticketPost.status === 4) && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          key: 'scheduleTime',
                          border: 1,
                          borderColor: 'primary.back',
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          p: 1,
                          mb: 2,
                        }}
                      >
                        <ScheduleView
                          scheduleTime={state.ticketPost.scheduleTime}
                          handleChange={handleChangeSchedule}
                        />
                      </Grid>
                    )}
                  <Grid item xs={12} sx={{ m: 0, pr: 0 }}>
                    <Grid
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      <Grid
                        sx={{
                          m: 0,
                          pb: 1,
                          flexGrow: 1,
                          backgroundColor: 'primary.back',
                          borderRadius: 2,
                        }}
                      >
                        <TextField
                          sx={{ m: 0, p: 0, backgroundColor: 'primary.main' }}
                          value={state.ticketPost.ticketPost.message}
                          defaultValue={state.ticketPost.ticketPost.message}
                          label={t(
                            'Ticket.TicketCommunications.internalTicket'
                          )}
                          variant='filled'
                          multiline={true}
                          disabled={false || !state.ticketPost.isEditable}
                          minRows={12}
                          maxRows={12}
                          onChange={(e) => {
                            dispatch({
                              type: 'EDIT_COMMENT',
                              payload: e.target.value,
                            });
                          }}
                        />
                        <Stack
                          direction='row'
                          spacing={0}
                          flexGrow={1}
                          sx={{ backgroundColor: 'rgb' }}
                        >
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                              fontSize: 14,
                              flexGrow: 1,
                            }}
                          >
                            {t(
                              'Ticket.TicketCommunications.internalTicketMessage'
                            )}
                          </Typography>

                          <SwitchType
                            isChecked={state.ticketPost.ticketPost.internal}
                            dispatch={dispatch}
                            type={'SET_INTERNAL'}
                            header={null}
                            showNotification={false}
                            disabled={false || !state.ticketPost.isEditable}
                          />
                        </Stack>
                        {/* EMAIL TO */}
                        <Stack
                          direction='row'
                          spacing={0}
                          flexGrow={1}
                          sx={{
                            backgroundColor:
                              state.ticketPost.ticketPost.status === 3 ||
                              state.ticketPost.ticketPost.status === 4 ||
                              state.ticketPost.ticketPost.status === 5 ||
                              state.ticketPost.ticketPost.status === 6
                                ? 'success.dark'
                                : 'transparent',
                            border: 2,
                            borderColor:
                              state.ticketPost.ticketPost.status === 3 ||
                              state.ticketPost.ticketPost.status === 4 ||
                              state.ticketPost.ticketPost.status === 5 ||
                              state.ticketPost.ticketPost.status === 6
                                ? 'success.light'
                                : 'transparent',
                            borderRadius: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              width: 140,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                              fontSize: 14,
                            }}
                          >
                            {t('Ticket.TicketCommunications.sendEmailTo')}:
                          </Typography>

                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'left',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              color: 'secondary.text',
                              flexGrow: 1,
                            }}
                          >
                            {state?.ticketPost?.ticketPost?.receiver?.email ||
                              ''}
                          </Typography>
                          <SwitchType
                            isChecked={state.ticketPost.ticketPost.isEmailSent}
                            dispatch={dispatch}
                            type={'IS_SEND_EMAIL'}
                            header={null}
                            showNotification={false}
                            disabled={
                              state.ticketPost.ticketPost.internal ||
                              !state.ticketPost.isEditable ||
                              !state.ticketPost.isEmailAllowed
                            }
                          />
                        </Stack>
                        {/* EMAIL TO */}
                        <Stack
                          direction='row'
                          spacing={0}
                          flexGrow={1}
                          sx={{
                            backgroundColor:
                              state.ticketPost.ticketPost.status === 3 ||
                              state.ticketPost.ticketPost.status === 4 ||
                              state.ticketPost.ticketPost.status === 5 ||
                              state.ticketPost.ticketPost.status === 6
                                ? 'success.dark'
                                : 'transparent',
                            border: 2,
                            borderColor:
                              state.ticketPost.ticketPost.status === 3 ||
                              state.ticketPost.ticketPost.status === 4 ||
                              state.ticketPost.ticketPost.status === 5 ||
                              state.ticketPost.ticketPost.status === 6
                                ? 'success.light'
                                : 'transparent',
                            borderRadius: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              width: 180,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                              fontSize: 14,
                            }}
                          >
                            {t('Ticket.TicketCommunications.ccEmailTo')}:
                          </Typography>

                          <TextField
                            name='name'
                            onFocus={handleFocus}
                            sx={{
                              fontSize: 10,
                              color: 'info.text',
                              '& .Mui-focused': {
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              },
                              backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            }}
                            disabled={true}
                            value={state.ticket.ccEmail}
                            onChange={(e) => handleChangeCC(e)}
                          />
                          <SwitchType
                            isChecked={state.ticketPost.ticketPost.isCCSent}
                            dispatch={dispatch}
                            type={'IS_SEND_EMAIL_CC'}
                            header={null}
                            showNotification={false}
                            disabled={
                              state.ticketPost.ticketPost.internal ||
                              !state.ticketPost.isEditable ||
                              !state.ticketPost.isEmailAllowed
                            }
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            {/* ATTATCHMENTS */}
            <Grid item xs={12} sx={{ pr: 0.5, pl: 0.5, direction: 'row' }}>
              <Accordion
                disableGutters
                sx={{
                  backgroundColor: 'transparent',
                  border: 0,
                  m: 1,
                }}
              >
                <FormAccordionSummary
                  Icon={AttachFileIcon}
                  title={t('Ticket.TicketCommunications.attachments')}
                />
                <AccordionDetails>
                  <DocumentUploader
                    setDocuments={(files: any) =>
                      setSelectedDocuments((prevDocs: any) => [
                        ...prevDocs,
                        ...files,
                      ])
                    }
                  />
                  <List>
                    {state.ticketPost.ticketPost.attachments?.map(
                      (document: any, index: number) => {
                        //console.log('document:', document);
                        return (
                          <ListItem key={index}>
                            {document.url ? (
                              <a
                                rel='noopener noreferrer'
                                target='_blank'
                                href={document.url}
                                style={{ color: 'white' }}
                              >
                                {document.fileName}
                              </a>
                            ) : (
                              document.file.name
                            )}
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </List>
          {/* FOTTER */}
          {/* SUBMIT*/}
          <Grid item xs={12} sx={{ pr: 0.5, pl: 0.5, direction: 'row' }}>
            <Stack direction={'column'}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                {isValidGuid(state.ticketPost.ticketPost.id) && (
                  <Button
                    sx={{
                      width: '25%',
                      backgroundColor: 'primary.back',
                    }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      /* dispatch({
                             type: 'REMOVE_TICKET_POST',
                             payload: state.ticketPost.ticketPost.id,
                           });
                           dispatch({
                             type: 'SET_IS_EDITABLE',
                             payload: true,
                           }); */
                    }}
                    variant='contained'
                  >
                    {t('Ticket.TicketCommunications.Cancel')}
                  </Button>
                )}
                <Button
                  disabled={isEditDisabled()}
                  sx={{
                    width: '50%',
                    // backgroundColor: 'secondary.main',
                  }}
                  type='submit'
                  onClick={(e: any) => {
                    e.preventDefault();
                    onSubmit();
                    /*  dispatch({
                           type: 'SET_IS_EDITABLE',
                           payload: false,
                         }); */
                  }}
                  id={
                    isGuidEmpty(state.ticketPost.ticketPost.id)
                      ? `${t('Ticket.TicketCommunications.submit')}`
                      : `${t('Ticket.TicketCommunications.confirmEdit')}`
                  }
                  variant='contained'
                >
                  {isGuidEmpty(state.ticketPost.ticketPost.id)
                    ? `${t('Ticket.TicketCommunications.submit')}`
                    : `${t('Ticket.TicketCommunications.confirmEdit')}`}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
