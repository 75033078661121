import React, { useState } from 'react';
import { Grid, Typography, IconButton, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  ITicketViewModel,
  ITicketPostViewModel,
  ITicketBillingViewModel,
} from 'Interfaces/ITicketConfig';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import dayjs from 'dayjs';
import { IProductViewModel } from 'Interfaces/IProductConfig';
import { SingleProductView } from './SingleProductView';
import { useTranslation } from 'react-i18next';

interface SingleBillingTicketViewProps {
  ticket: ITicketBillingViewModel;
}

const SingleBillingTicketView: React.FC<SingleBillingTicketViewProps> = ({
  ticket,
}) => {
  const [expanded, setExpanded] = useState(false);
  console.log('SingleBillingTicketView.ts - ticket: ', ticket);
  const { t } = useTranslation();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const totalBillingValue =
    ticket.fixedPrice > 0
      ? ticket.fixedPrice
      : ticket.maxPrice > 0
      ? Math.min(
          ticket.maxPrice,
          ticket.ticketPosts.reduce(
            (sum: number, post: ITicketPostViewModel) =>
              sum + post.totalBillingValue,
            0
          )
        )
      : ticket.ticketPosts.reduce(
          (sum: number, post: ITicketPostViewModel) =>
            sum + post.totalBillingValue,
          0
        );

  return (
    <Grid
      container
      alignItems='center'
      sx={{ mb: 1, padding: 1, border: '1px solid lightgray', borderRadius: 3 }}
    >
      {/* Ticket Icon */}
      <Grid
        item
        xs={1}
        sx={{ width: 48, height: 48, backgroundColor: 'primary.light' }}
      >
        <img
          src={MissingPhoto.BILLING_SUPPORT_URL}
          style={{
            marginLeft: 5,
            width: 48,
            height: 48,
            borderRadius: 3,
            objectFit: 'scale-down',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
          }}
          alt={'logo'}
        />
      </Grid>

      {/* Ticket Number and Calculated Total */}
      <Grid item xs={8}>
        <Box display='flex' alignItems='center'>
          <Typography variant='body1' mr='5px'>
            {ticket.title}
          </Typography>
        </Box>

        <Typography variant='body2'>
          Total:{' '}
          {totalBillingValue.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>

      {/* Expand Button */}
      <Grid item xs={1}>
        <IconButton onClick={handleExpandClick} aria-expanded={expanded}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Grid>

      {/* Expanded Ticket Posts */}
      {expanded && (
        <Grid item xs={12} sx={{ p: 1 }}>
          {ticket.fixedPrice > 0 ? (
            <Grid
              container
              key={'fixedPrice'}
              direction='column'
              alignItems='flex-start'
              sx={{
                mb: 1,
                p: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Date and Icons Row */}
              <Box display='flex' alignItems='center' gap={1} sx={{ mb: 1 }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: 1.5,
                    fontWeight: 'medium',
                    color: 'text.secondary',
                  }}
                >
                  {new Date().toLocaleDateString()}{' '}
                  {/* .format('DD-MM-YYYY HH:mm')} */}
                </Typography>
              </Box>

              {/* Author Image, Message, and Billing Value */}
              <Box
                display='flex'
                alignItems='center'
                gap={2}
                sx={{ width: '100%' }}
              >
                {/* Author Image */}
                <img
                  src={MissingPhoto.EMPLOYEE_URL}
                  alt='Author'
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
                <Grid item xs={8}>
                  {/* Message */}
                  <Typography
                    variant='body2'
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 3, // Maks 3 linjer
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis', // Legger til "..." for indikasjon
                    }}
                  >
                     {t('Billing.billingView.fixedPrice')}
                  </Typography>
                </Grid>
                {/* Billing Value */}
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: 'secondary.text',
                    fontSize: 14,
                  }}
                >
                  {ticket.fixedPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
            </Grid>
          ) : (
            ticket.ticketPosts.map(
              (post: ITicketPostViewModel, index: number) => {
                const dateTime = dayjs(post.startTime);
                return (
                  <Grid
                    container
                    key={index}
                    direction='column'
                    alignItems='flex-start'
                    sx={{
                      mb: 1,
                      p: 1,
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    {/* Date and Icons Row */}
                    <Box
                      display='flex'
                      alignItems='center'
                      gap={1}
                      sx={{ mb: 1 }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          letterSpacing: 1.5,
                          fontWeight: 'medium',
                          color: 'text.secondary',
                        }}
                      >
                        {dateTime.format('DD-MM-YYYY HH:mm')}
                      </Typography>

                      {/* Conditional Icons */}
                      {post.isEmailSent && (
                        <IconButton aria-label='isEmailSent' size='small'>
                          <MarkEmailReadRoundedIcon
                            sx={{ fontSize: 18, color: 'success.text' }}
                          />
                        </IconButton>
                      )}
                      {post.isCCSent && (
                        <IconButton aria-label='isCCSent' size='small'>
                          <ContactMailIcon
                            sx={{ fontSize: 18, color: 'success.text' }}
                          />
                        </IconButton>
                      )}
                      {post.isOvertime && (
                        <IconButton aria-label='overtime' size='small'>
                          <MoreTimeRoundedIcon
                            sx={{ fontSize: 18, color: 'success.text' }}
                          />
                        </IconButton>
                      )}
                      {post.isBillable && (
                        <IconButton aria-label='isBillable' size='small'>
                          <AttachMoneyRoundedIcon
                            sx={{ fontSize: 18, color: 'success.text' }}
                          />
                        </IconButton>
                      )}
                      {post?.attachments && post.attachments.length > 0 && (
                        <IconButton aria-label='attachment' size='small'>
                          <AttachFileRoundedIcon
                            sx={{ fontSize: 18, color: 'info.main' }}
                          />
                        </IconButton>
                      )}
                    </Box>

                    {/* Author Image, Message, and Billing Value */}
                    <Box
                      display='flex'
                      alignItems='center'
                      gap={2}
                      sx={{ width: '100%' }}
                    >
                      {/* Author Image */}
                      <img
                        src={post.author?.url || MissingPhoto.EMPLOYEE_URL}
                        alt='Author'
                        style={{
                          width: 32,
                          height: 32,
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                      <Grid item xs={8}>
                        {/* Message */}
                        <Typography
                          variant='body2'
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 3, // Maks 3 linjer
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis', // Legger til "..." for indikasjon
                          }}
                        >
                          {post.message}
                        </Typography>
                      </Grid>
                      {/* Billing Value */}
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          color: 'secondary.text',
                          fontSize: 14,
                        }}
                      >
                        {post.totalBillingValue.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                );
              }
            )
          )}
          {ticket.consumableProducts.map(
            (post: IProductViewModel, index: number) => {
              return (
                <Box
                  sx={{
                    transform: `scale(${0.8})`,
                    transformOrigin: 'left',
                    m: 0,
                    p: 0,
                  }}
                >
                  <SingleProductView product={post} />
                </Box>
              );
            }
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SingleBillingTicketView;
