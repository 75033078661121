import useWindowsDimension from 'functions/useWindowsDimension';
import { rq } from 'http/apiRoutes';
import { useGet } from 'http/useInnovit';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Drawer, Box } from '@mui/material';
import { CompaniesList } from '../CompaniesList';
import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { EmployeeList } from '../EmployeeList';

type EmployeeDrawerProps = {
  buttonIcon: React.ReactNode; // or 'any' if you prefer
  buttonContext: string; // or 'any', but string is typically used
  handleChange: (
    event: React.MouseEvent<HTMLElement>,
    employee: IEmployeeViewModel
  ) => void;
  companyId: string;
};

export const EmployeeDrawer = ({
  buttonIcon,
  buttonContext,
  handleChange,
  companyId,
}: EmployeeDrawerProps) => {
  const { width } = useWindowsDimension();
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState<IEmployeeViewModel[]>([]);

  const { data: employeeData } = useGet(
    rq.employees(companyId, true)
  );

  const handleLocalChange = (
    e: React.MouseEvent<HTMLElement>,
    employee: IEmployeeViewModel
  ) => {
    // 1) Call the "external" handleChange that you received as a prop
    handleChange(e, employee);

    // 2) Close the drawer
    setOpen(false);
  };

  useEffect(() => {
    if (employeeData) {
      console.log('employeeData', employeeData);
      setEmployees(employeeData.allEmployees);
    }
  }, [employeeData]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%',
          height: '100%',
        }}
      >
        <Button
          sx={{ p: 0, m: 0 }}
          size='small'
          variant='contained'
          onClick={() => setOpen(!open)}
        >
          {buttonIcon}
        </Button>
      </Box>
      <Drawer anchor={'left'} open={open} onClose={() => setOpen(!open)}>
        <Box sx={{ width: width / 2, backgroundColor: 'primary.main' }}>
          <EmployeeList
            handleChange={handleLocalChange}
            employees={employees}
            // companies={companies}
            //   setOpen={setOpen}
            //   handleEmployeeSubmit={handleEmployeeSubmit}
            //   handleRemoveEmployeeSubmit={handleRemoveEmployeeSubmit}
          />
        </Box>
      </Drawer>
    </>
  );
};
