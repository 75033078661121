import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';
import { BillingCompanyViewModel } from '../BillingConfig';
import { IBillingCompanyProductViewModel } from 'Interfaces/BillingConfig';
import { ITicketBillingViewModel } from 'Interfaces/ITicketConfig';

export interface IBillingState {
  billingPosts: BillingCompanyViewModel[];
  selectedBillingPost: BillingCompanyViewModel;
  company: ICompanyViewModel;
  agreementProductList: IBillingCompanyProductViewModel[];
  investmentProductList: IBillingCompanyProductViewModel[];
  ticketBillingList: ITicketBillingViewModel[];
  otherProductList: IBillingCompanyProductViewModel[];
  totalInvoice: number;
}

export const selectedBillingPostInitialState: BillingCompanyViewModel = {
  id: '',
  company: {} as ICompanyViewModel,
  cost: 0,
  agreementCost: 0,
  agreementInvoiceValue: 0,
  supportCost: 0,
  supportInvoiceValue: 0,
  investmentCost: 0,
  investmentInvoiceValue: 0,
  otherCost: 0,
  otherInvoiceValue: 0,
  agreementProductList: [],
  investmentProductList: [],
  ticketBillingList: [],
  otherProductList: [],
  isBilled: false,
  invoiceNo: '',
  selectedBillingPost: undefined,
  totalInvoice: 0,
};

export const BillingState: IBillingState = {
  billingPosts: [],
  selectedBillingPost: selectedBillingPostInitialState,
  company: {} as ICompanyViewModel,
  agreementProductList: [],
  investmentProductList: [],
  ticketBillingList: [],
  otherProductList: [],
  totalInvoice: 0,
};

export type Action =
    | { type: 'RESET_BILLING_STATE' }
    | { type: 'SET_BILLING_POSTS'; payload: BillingCompanyViewModel[] }
    | { type: 'SET_SELECTED_BILLING_POST'; payload: BillingCompanyViewModel }
    // RIGHT SIDE OF THE VIEW
    | { type: 'SET_COMPANY'; payload: ICompanyViewModel }
    | {
        type: 'SET_AGREEMENT_PRODUCT_LIST';
        payload: IBillingCompanyProductViewModel[];
        }
    | {
        type: 'SET_INVESTMENT_PRODUCT_LIST';
        payload: IBillingCompanyProductViewModel[];
        }
    | { type: 'SET_TICKET_BILLING_LIST'; payload: ITicketBillingViewModel[] }
    | {
        type: 'SET_OTHER_PRODUCT_LIST';
        payload: IBillingCompanyProductViewModel[];
        }
    | { type: 'SET_TOTAL_INVOICE'; payload: number };

export const BillingReducer = (
  state: IBillingState = BillingState,
  action: Action
): IBillingState => {
  switch (action.type) {
    case 'RESET_BILLING_STATE':
      return BillingState;
    case 'SET_BILLING_POSTS':
      return {
        ...state,
        billingPosts: action.payload,
      };
    case 'SET_SELECTED_BILLING_POST':
        console.log('BILLING: SET_SELECTED_BILLING_POST: ', action.payload);
      return {
        ...state,
        selectedBillingPost: action.payload,
      };
    case 'SET_COMPANY':
        return {
            ...state,
            company: action.payload,
        };
    case 'SET_AGREEMENT_PRODUCT_LIST':
        return {
            ...state,
            agreementProductList: action.payload,
        };
    case 'SET_INVESTMENT_PRODUCT_LIST':
        return {
            ...state,
            investmentProductList: action.payload,
        };
    case 'SET_TICKET_BILLING_LIST':
      console.log('BILLING: SET_TICKET_BILLING_LIST: ', action.payload);
        return {
            ...state,
            ticketBillingList: action.payload,
        };
    case 'SET_OTHER_PRODUCT_LIST':
        return {
            ...state,
            otherProductList: action.payload
        };
    case 'SET_TOTAL_INVOICE':
        return {
            ...state,
            totalInvoice: action.payload,
        };
    default:
      return state;
  }
};
