import { ConvertToAttachment } from './ConvertToAttachment';
import dayjs from 'dayjs';
import {
  ITicketPostAddViewModel,
  ITicketPostInterface,
  ITicketPostUpdateViewModel,
} from 'Interfaces/ITicketConfig';
import { calculateTicketPostBilling } from './calculateTicketPostBilling';
import { GenerateGuid } from './GuidChecks';

export const ConvertToAddTicketPost = (
  ticketPost: ITicketPostInterface,
  user: any
) => {
  console.log('ticketpost: ticketPost', ticketPost);

  let _ticketPost: ITicketPostAddViewModel = {
    ticketId: GenerateGuid(),
    status: ticketPost.ticketPost.status,
    startTime: dayjs(ticketPost.ticketPost.startTime), // Send local time, no UTC conversion
    endTime: dayjs(ticketPost.ticketPost.endTime), // Send local time, no UTC conversion
    startDeduction: null, // Send local time
    endDeduction: null, // Send local time
    isBillable: ticketPost.ticketPost.isBillable,
    isOvertime: ticketPost.ticketPost.isOvertime, // Send Overtime, no UTC conversion conversionoverTime,
    isBilled: false,
    internal: ticketPost.ticketPost.internal,
    isInHouse: ticketPost.ticketPost.isInHouse,
    isEmailSent: ticketPost.ticketPost.isEmailSent,
    isCCSent: ticketPost.ticketPost.isCCSent,
    direction: 1,
    authorId: user.id,
    receiverId: ticketPost?.ticketPost?.receiverId ?? 'new',
    message: ticketPost.ticketPost.message || '',
    attachments: null,
    workRoleId: ticketPost.ticketPost.workRole?.id
      ? ticketPost.ticketPost.workRole?.id
      : null,
    ticketPostBilling: calculateTicketPostBilling(ticketPost),
    isApp: false,
  };
  return _ticketPost;
};
