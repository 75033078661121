import {
  IEmployeeViewModel,
  ITicketBillingViewModel,
  ITicketPostBillingViewModel,
  ITicketPostViewModel,
  ITicketViewModel,
} from 'Interfaces/ITicketConfig';
import { IOrderProductFeaturesViewModel } from 'Interfaces/IOrderConfig';
import { ICompanyViewModel } from './ICompaniesConfig';
import { IBrandViewModel } from 'pages/Setup/setupConfig';
import {
  IProductFeatureViewModel,
  iProductSimpleViewModel,
  IProductViewModel,
} from './IProductConfig';

// STYLE
export const sxHeader = {
  pl: 1,
  pt: 0,
  width: '50&',
  letterSpacing: 2,
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: 16,
  m: 0,
};
export const sxSubHeader = {
  pl: 1,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  fontSize: 14,
  color: 'primary.text',
};
export const sxSubValue = {
  pl: 1,
  pr: 1,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: 16,
  color: 'secondary.text',
};

export interface IInvoiceViewModel {
  id: string | null;
  companyId: string;
  InvoiceNo: string;
  agreementCost: number;
  agreementInvoiceValue: number;
  investmentCost: number;
  investmentInvoiceValue: number;
  otherCost: number;
  otherInvoiceValue: number;
  supportCost: number;
  supportInvoiceValue: number;
  isPayed: boolean;
  agreementProductList: IBillingCompanyProductViewModel[];
  investmentProductList: IBillingCompanyProductViewModel[];
  ticketBillingList: ITicketBillingViewModel[];
  otherProductList: IBillingCompanyProductViewModel[];
}


export interface IInvoiceAddViewModel {
  companyId: string;
  agreementCost: number;
  agreementInvoiceValue: number;
  investmentCost: number;
  investmentInvoiceValue: number;
  otherCost: number;
  otherInvoiceValue: number;
  supportCost: number;
  supportInvoiceValue: number;
  agreementProductList: IBillingCompanyProductViewModel[];
  investmentProductList: IBillingCompanyProductViewModel[];
  ticketBillingList: ITicketBillingViewModel[];
  otherProductList: IBillingCompanyProductViewModel[];
}
export interface IInvoicePostViewModel {
  id: string | null;
  InvoiceNo: string;
  companyId: string | null;
  agreementProductId: string | null;
  companyProductId: string | null;
  ticketPostId: string | null;
  isInvoiced: boolean;
  consumableId: string;
  consumable: IProductViewModel;
  quantity: number;
  unitPrice: number;
  retailPrice: number;
}
// src/pages/Billing/BillingView.tsx

export type BillingPostState = {
  id: string | null;
  BillingNo: string;
  company: ICompanyViewModel;
  cost: number;
  isBilled: boolean;

  // Agreement-related costs and products
  agreementCost: number;
  agreementInvoiceValue: number;
  agreementProductList: IBillingCompanyProductViewModel[];

  // Investment-related costs and products
  investmentCost: number;
  investmentInvoiceValue: number;
  investmentProductList: IBillingCompanyProductViewModel[];

  // Other-related costs and products
  otherCost: number;
  otherInvoiceValue: number;
  otherProductList: IBillingCompanyProductViewModel[];

  // Support-related costs and tickets
  supportCost: number;
  supportInvoiceValue: number;
  supportList: ITicketViewModel[];

  // Invoice metadata
  invoiceNo: string | null;
  selectedFilters: string[];
};


export interface IBillingPostViewModel {
  id: string | null;
  BillingNo: string;
  agreementCost: number;
  agreementInvoiceValue: number;
  billingViewModels: IBillingDetailViewModel[];
  company: ICompanyViewModel;
  cost: number;
  investmentInvoiceValue: number;
  investmentCost: number;
  isBilled: boolean;
  otherInvoiceValue: number;
  otherCost: number;
  supportInvoiceValue: number;
  supportCost: number;
  invoiceNo: string | null;
  product: iProductSimpleViewModel;
}

export interface IBillingDetailViewModel {
  id: string;
  agreement: number;
  agreementCost: number;
  agreementProduct: IBillingCompanyProductViewModel | null;
  company: ICompanyViewModel | null;
  companyId: string | null;
  companyProduct: IBillingCompanyProductViewModel | null;
  cost: number;
  investment: number;
  investmentCost: number;
  isBilled: boolean;
  other: number;
  otherCost: number;
  support: ITicketPostBillingViewModel | null;
  ticketPost: ITicketPostViewModel;
  product: iProductSimpleViewModel;

}

export interface IBillingCompanyProductViewModel {
  id: string;
  productId: string;
  product: IBillingProductViewModel;
  companyId: string;
  purchaserId: string;
  owner: IEmployeeViewModel | null;
  ownerId: string;
  comment: string;
  unitPrice: number;
  retailPrice: number;
  discount: number;
  lifetime: number;
  lastBilled: Date | null;
  security: boolean;
  status: number;
  productWarning: string;
  url: string | null;
  quantity: number | 1;
}
export interface IBillingProductViewModel {
  id: string;
  name: string;
  url?: string | null;
  serialNumber: string | null;
  unitPrice: number;
  retailPrice: number;
  productCategory: string;
  productType: IProductTypeViewModel;
  brandId: string;
  brand: IBrandViewModel;
  quantity: number;
  warning: boolean;
  warningNote?: string | null;
  paymentCycle: number;
  cycleAlignment: number;
  bindingPeriod: number;
  features?: IOrderProductFeaturesViewModel | null;
}

export interface IProductAddViewModel {
  id: string;
  name: string;
  url?: string | null;
  serialNumber: string | null;
  comment: string;
  unitPrice: number;
  retailPrice: number;
  productCategory: string;
  productType: IProductTypeViewModel;
  brand: string;
  quantity: number;
  warning: boolean;
  warningNote?: string | null;
  paymentCycle: number;
  cycleAlignment: number;
  bindingPeriod: number;
  active: boolean;
  ean: string | null;
  security: number;
  features?: IOrderProductFeaturesViewModel | null;
}
export interface IProductTypeViewModel {
  id: string;
  name: string;
  productCategoryName: string;
  LifeExpectancy: number;
  productFeatures: IProductFeatureViewModel[];
}

export interface IBillingViewModel {
  agreementProduct: {
    id: string | null;
    companyId: string | null;
    quantity: number;
    unitPrice: number;
    retailPrice: number;
  };
  companyProduct: {
    id: string | null;
    companyId: string | null;
    quantity: number;
    unitPrice: number;
    retailPrice: number;
  };
  ticketPost: {
    ticketPostId: string | null;
    companyId: string | null;
    quantity: number;
    unitPrice: number;
    retailPrice: number;
  };
}
