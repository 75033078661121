import { useReducer, useState, useEffect } from 'react';
import {
  Button,
  Grid,
  List,
  Paper,
  Drawer,
  Box,
  Typography,
  Pagination,
  Select,
  MenuItem,
  TextField,
  Stack,
} from '@mui/material';
import { EnumCompanyType, EnumObjectType } from 'Interfaces/IGlobalTypeConfig';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { t } from 'i18next';
import useWindowsDimension from 'functions/useWindowsDimension';

import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import { ICompanyViewModel, ThirdPartEnum } from 'Interfaces/ICompaniesConfig';
import { StackedBarChart } from '@mui/icons-material';

// Types
interface State {
  bluuCompany: ICompanyViewModel;
  cloudCompany: ICompanyViewModel;
  companies: ICompanyViewModel[];
}

export type Action =
  | { type: 'CLOSE' }
  | { type: 'SET_COMPANIES'; companies: ICompanyViewModel[] }
  | { type: 'SET_BLUU_COMPANY'; company: ICompanyViewModel }
  | { type: 'SET_CLOUD_COMPANY'; company: ICompanyViewModel };

const initialState: State = {
  bluuCompany: {} as ICompanyViewModel,
  cloudCompany: {} as ICompanyViewModel,
  companies: [],
};

const companyMergeReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CLOSE':
      return { ...state };
    case 'SET_COMPANIES':
      return { ...state, companies: action.companies };
    case 'SET_BLUU_COMPANY':
      return { ...state, bluuCompany: action.company };
    case 'SET_CLOUD_COMPANY':
      console.log('SET_CLOUD_COMPANY:', action.company);
      return { ...state, cloudCompany: action.company };
    default:
      return state;
  }
};

interface Props {
  handleMergeCompany: (e: any, company: ICompanyViewModel) => void;
  cloudCompany: ICompanyViewModel;
}

export const CompanyMergeDrawer = ({
  handleMergeCompany,
  cloudCompany,
}: Props) => {
  const [state, dispatch] = useReducer(companyMergeReducer, initialState);
  const { height } = useWindowsDimension();
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState<number>(1);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [objectType] = useState<EnumObjectType>(EnumObjectType.Product);

  // mergeSelections holder valg for hvert felt: 'cloud' eller 'bluu'
  const [mergeSelections, setMergeSelections] = useState<{
    [field: string]: 'cloud' | 'bluu';
  }>({});

  // Sett cloudCompany fra props
  useEffect(() => {
    dispatch({
      type: 'SET_CLOUD_COMPANY',
      company: cloudCompany,
    });
  }, [cloudCompany]);

  // Hent lokale selskaper
  const { data: companiesData } = useGet(rq.companies('2', true));
  useEffect(() => {
    if (companiesData) {
      console.log('local company data:', companiesData.companies);
      dispatch({
        type: 'SET_COMPANIES',
        companies: companiesData.companies,
      });
    }
  }, [companiesData]);

  // Når bluuCompany og cloudCompany er satt, initieres mergeSelections for feltene der de er forskjellige.
  useEffect(() => {
    if (
      state.cloudCompany &&
      Object.keys(state.cloudCompany).length &&
      state.bluuCompany &&
      Object.keys(state.bluuCompany).length
    ) {
      const diff: { [field: string]: 'cloud' | 'bluu' } = {};

      // Hjelpefunksjon for å sjekke om en verdi er "tom"
      const isEmpty = (value: any) => {
        return (
          value === null ||
          value === undefined ||
          (typeof value === 'string' && value.trim() === '')
        );
      };

      // Liste over felter som alltid skal overføres automatisk (og ikke vises i merge-differansen)
      const autoTransferFields = [
        'id',
        'addresses',
        'legacyId',
        'thirdPartyId',
        'mainAddress',
        'deliveryAddress',
        'invoiceAddress',
      ];

      Object.keys(state.cloudCompany).forEach((field) => {
        // Felter som skal auto-overføres, hoppes over i diff
        if (autoTransferFields.includes(field)) return;
        if (!state.bluuCompany.hasOwnProperty(field)) return;

        const cloudVal = state.cloudCompany[field as keyof ICompanyViewModel];
        const bluuVal = state.bluuCompany[field as keyof ICompanyViewModel];

        // Spesiell regel for "name": alltid anbefale cloudCompany-versjonen dersom de er ulike
        if (field === 'name') {
          if (cloudVal !== bluuVal) {
            diff[field] = 'cloud';
          }
          return;
        }

        // Generell logikk for øvrige felt
        if (isEmpty(bluuVal) && !isEmpty(cloudVal)) {
          diff[field] = 'cloud';
        } else if (isEmpty(cloudVal)) {
          // Cloud er tom, behold bluuCompany-verdi (ingen endring)
        } else if (cloudVal !== bluuVal) {
          diff[field] = 'bluu';
        }
      });
      setMergeSelections(diff);
    }
  }, [state.cloudCompany, state.bluuCompany]);

  // Endre side for paginering
  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  // Velg et selskap fra listen (bluuCompany)
  const handleSelectCompany = (e: any, company: ICompanyViewModel) => {
    e.preventDefault();
    console.log('Merge company Selected:', company);
    dispatch({
      type: 'SET_BLUU_COMPANY',
      company: company,
    });
  };

  // Paginering av selskapene
  const paginateCompanies = (companies: ICompanyViewModel[]) => {
    const startIndex = (page - 1) * itemsPerPage;
    return companies.slice(startIndex, startIndex + itemsPerPage);
  };

  // Render selskapliste
  const renderCompanyList = (companies: ICompanyViewModel[]) => {
    return (
      <>
        <List
          sx={{
            width: '100%',
            p: 2,
            height: height - 160,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {paginateCompanies(companies).map((company, index) => (
            <Paper
              key={index}
              sx={{
                m: 1,
                backgroundColor: 'primary.main',
                ':hover': {
                  cursor: 'pointer',
                  filter: 'brightness(120%)',
                  transition: 'all 0.2s ease-in-out',
                },
              }}
              onClick={(e) => handleSelectCompany(e, company)}
            >
              <SingleCompanyView company={company} isOnboarding={false} />
            </Paper>
          ))}
        </List>

        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
          <Pagination
            count={Math.ceil(companies.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
          />
          <Typography sx={{ mr: 2 }}>Items per page:</Typography>
          <Select
            size='small'
            variant='outlined'
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            sx={{ width: 85, mt: -1 }}
          >
            {[5, 10, 15, 20, 25, 50, 100].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </>
    );
  };

  // Renderer forskjellene for de feltene som er ulike
  const renderMergeDifferences = () => {
    const diffFields = Object.keys(mergeSelections);
    if (diffFields.length === 0) {
      return <Typography>Ingen forskjeller funnet.</Typography>;
    }
    return diffFields.map((field) => (
      <Box key={field} sx={{ mb: 1 }}>
        <Typography variant='subtitle1'>{field}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            sx={{ width: '50%', color: 'white' }}
            variant={
              mergeSelections[field] === 'cloud' ? 'contained' : 'outlined'
            }
            onClick={() =>
              setMergeSelections((prev) => ({ ...prev, [field]: 'cloud' }))
            }
          >
            {String(state.cloudCompany[field as keyof ICompanyViewModel])}
          </Button>
          <Button
            sx={{ width: '50%', color: 'white' }}
            variant={
              mergeSelections[field] === 'bluu' ? 'contained' : 'outlined'
            }
            onClick={() =>
              setMergeSelections((prev) => ({ ...prev, [field]: 'bluu' }))
            }
          >
            {String(state.bluuCompany[field as keyof ICompanyViewModel])}
          </Button>
        </Box>
      </Box>
    ));
  };

  // Når alle valg er gjort, oppdateres bluuCompany med de valgte verdiene og merge sendes
  const handleConfirmMerge = (e: any) => {
    const updatedCompany: ICompanyViewModel = { ...state.bluuCompany };
    Object.keys(mergeSelections).forEach((field) => {
      if (mergeSelections[field] === 'cloud') {
        // Kaster til any for å kunne tildele verdier dynamisk
        (updatedCompany as any)[field] = (state.cloudCompany as any)[field];
      }
    });
    updatedCompany.companyType = EnumCompanyType.CLIENT;
    updatedCompany.legacyId = state.cloudCompany.legacyId;
    updatedCompany.thirdPartyId = state.cloudCompany.thirdPartyId;
    updatedCompany.thirdParty = ThirdPartEnum.CloudFactory;

    handleMergeCompany(e, updatedCompany);
    setDrawerOpen(false);
  };

  // Filtrering av selskap basert på søkestreng
  const filteredCompanies = state.companies.filter((company) => {
    if (!searchQuery) return true;
    const searchableFields: (keyof ICompanyViewModel)[] = [
      'name',
      'email',
      'phone',
    ];
    return searchableFields.some((field) => {
      const value = company[field];
      return (
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  });

  return (
    <>
      <Button
        variant='contained'
        sx={{ p: 1, mt: -1 }}
        onClick={() => setDrawerOpen(true)}
      >
        {t('button.merge')}
      </Button>

      <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ width: '100%', flexShrink: 0 }}
        PaperProps={{ style: { width: '100%' } }}
      >
        <>
          <Box sx={{ display: 'flex', p: 2 }}>
            {/* Venstre side: Selskapsliste */}
            <Box sx={{ width: '40%' }}>
              <Grid container sx={{ display: 'flex', flexDirection: 'row' }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <Typography variant='h6'>Selskapsliste</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  placeholder='Søk etter selskap'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ width: 200 }}
                />
              </Box>
              {renderCompanyList(filteredCompanies)}
            </Box>
            {/* Høyre side: Sammenligning og merge */}
            <Box sx={{ width: '60%', pl: 2 }}>
              <Typography variant='h6'>Sammenlign selskaper</Typography>
              <Typography variant='subtitle2' sx={{ mb: 2 }}>
                Velg hvilke felt du vil beholde:
              </Typography>
              <Stack direction='row' spacing={2} sx={{ display: 'flex', m: 0 }}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    width: '50%',
                    height: 40,
                    textAlign: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',

                    backgroundColor: 'primary.dark',
                  }}
                >
                  Clound Factort Company:
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{
                    width: '50%',
                    height: 40,
                    textAlign: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',

                    backgroundColor: 'primary.light',
                  }}
                >
                  Bluu Company:
                </Typography>
              </Stack>
              <List
                sx={{
                  height: height - 220,
                  p: 2,
                  backgroundColor: 'primary.back',
                }}
              >
                {renderMergeDifferences()}
              </List>

              <Grid item xs={12}>
                <Paper
                  variant='elevation3'
                  sx={{
                    width: '100%',
                    p: 0,
                    height: 64,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor: 'primary.main',
                  }}
                >
                  <Grid
                    container
                    sx={{
                      m: 0,
                      p: 2,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexGrow: 1,
                    }}
                  >
                    <Button
                      variant='contained'
                      sx={{ mt: -1 }}
                      onClick={() => setDrawerOpen(false)}
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      variant='contained'
                      sx={{ mt: -1 }}
                      onClick={handleConfirmMerge}
                    >
                      {t('button.merge')}
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            </Box>
          </Box>
        </>
      </Drawer>
    </>
  );
};
