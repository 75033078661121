import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { pdfStyle } from './PDFStyle';
import { BillingCompanyViewModel } from 'pages/Billing/BillingConfig';


// Define the data type for the prop
interface Props {
  data: BillingCompanyViewModel;
}

// Create Document Component
export const PDFFooterView = ({ data }: Props) => {
  return (
    <>
      <View style={pdfStyle.containerView}>
        <View style={pdfStyle.column100}>
          <Text style={pdfStyle.textLeft}>{'DEFINITIONS'}</Text>
        </View>
        <View style={pdfStyle.divider}></View>
        <View style={pdfStyle.avsnitt} />
        <View style={pdfStyle.rowView}>
          <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textCenter}>{'TIME'}</Text>
          </View>
          <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textLeft}>{'n: nighttime'}</Text>
          </View>
          <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textLeft}>{'w: worktime'}</Text>
          </View>
          <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textLeft}>{'m: midday'}</Text>
          </View>
          <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textLeft}>{'e: evening'}</Text>
          </View>
        </View>
      </View>
    </>
  );
};
