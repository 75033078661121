import { Grid, Typography } from '@mui/material';
import {
  ITicketPostBillingViewModel,
  ITicketPostInterface,
} from 'Interfaces/ITicketConfig';
import { TextStyle } from 'data/FontStyle';
import { useTranslation } from 'react-i18next';

interface EconomyViewProps {
  ticketPostBilling: ITicketPostBillingViewModel | null;
  state: ITicketPostInterface;
}
export const EconomyView: React.FC<EconomyViewProps> = ({
  ticketPostBilling,
  state,
}) => {
  const { t } = useTranslation();
  let ticketPostBillingViewModel = ticketPostBilling;
  if (ticketPostBilling) ticketPostBillingViewModel = ticketPostBilling;
  console.log(
    'EconomyView - ticketPostBillingViewModel',
    ticketPostBillingViewModel
  ); // xxx
  // console.log('EconomyView - ticketPostBilling', ticketPostBilling); // xxx
  console.log('EconomyView - state', state); // xxx

  // Calculate the total work value and hours
  const workHours = ticketPostBillingViewModel?.workHours || 0;
  const workBillableValue = ticketPostBillingViewModel?.workBillableValue || 0;

  // Calculate free work value and hours
  const freeWorkHours = ticketPostBillingViewModel?.notBillableHours || 0;
  const freeWorkValue =
    ticketPostBillingViewModel?.notBillableBillingValue || 0;

  const totalOvertimeHours =
    (ticketPostBillingViewModel?.nightHours ?? 0) +
    (ticketPostBillingViewModel?.midDayHours ?? 0) +
    (ticketPostBillingViewModel?.eveningHours ?? 0) +
    0;

  const totalOvertimeBillableValue =
    (ticketPostBillingViewModel?.nightBillableValue ?? 0) +
    (ticketPostBillingViewModel?.midDayBillableValue ?? 0) +
    (ticketPostBillingViewModel?.eveningBillableValue ?? 0) +
    0;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'primary.back',
        borderRadius: 2,
        ml: 0,
        mt: 3,
        position: 'relative',
        zIndex: 1000,
      }}
    >
      {/* Arbeidstid og verdi */}
      <Grid item xs={12}>
        <Typography sx={[TextStyle.label, { width: '100%' }]}>
          {t('Ticket.TicketCommunications.normalWorkTime')}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography sx={[TextStyle.label, { width: '100%' }]}>
            {t('Ticket.TicketCommunications.time')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={[TextStyle.label, { width: '100%' }]}>
            {t('Ticket.TicketCommunications.value')}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: -2 }}>
          <Typography
            sx={[
              TextStyle.value,
              {
                width: '100%',
                textAlign: 'center',
                alignItems: 'center',
                // border: 'red 1px solid',
              },
            ]}
          >
            {workHours.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: -2 }}>
          <Typography
            sx={[
              TextStyle.value,
              { width: '100%', textAlign: 'center', alignItems: 'center' },
            ]}
          >
            {workBillableValue.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>

      {/* Gratis arbeidstid og verdi */}
      <Grid item xs={12}>
        <Typography sx={[TextStyle.label, { width: '100%' }]}>
          {t('Ticket.TicketCommunications.freeWorkTime')}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography sx={[TextStyle.label, { width: '100%' }]}>
            {t('Ticket.TicketCommunications.time')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={[TextStyle.label, { width: '100%' }]}>
            {t('Ticket.TicketCommunications.value')}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: -2 }}>
          <Typography
            sx={[
              TextStyle.value,
              { width: '100%', textAlign: 'center', alignItems: 'center' },
            ]}
          >
            {freeWorkHours.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: -2 }}>
          <Typography
            sx={[
              TextStyle.value,
              { width: '100%', textAlign: 'center', alignItems: 'center' },
            ]}
          >
            {freeWorkValue.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>

      {/* Overtid og verdi */}
      <Grid item xs={12} sx={{ mt: -1 }}>
        <Typography sx={[TextStyle.label, { width: '100%' }]}>
          {t('Ticket.TicketCommunications.overTime')}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography sx={[TextStyle.label, { width: '100%' }]}>
            {t('Ticket.TicketCommunications.time')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={[TextStyle.label, { width: '100%' }]}>
            {t('Ticket.TicketCommunications.value')}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: -2 }}>
          <Typography
            sx={[
              TextStyle.value,
              { width: '100%', textAlign: 'center', alignItems: 'center' },
            ]}
          >
            {totalOvertimeHours.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: -2 }}>
          <Typography
            sx={[TextStyle.value, { width: '100%', textAlign: 'center' }]}
          >
            {totalOvertimeBillableValue.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
