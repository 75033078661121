import { useEffect, useReducer } from 'react';
import { usePost, useGet } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
import {
  Grid,
  Box,
  List,
  ListItem,
  Button,
  Switch,
  Typography,
  Checkbox,
  ListItemText,
} from '@mui/material';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import { useTranslation } from 'react-i18next';
import { SearchFilter } from 'components/ui/SearchFilter';

import _ from 'lodash';
import {
  IEmployeeAddListViewModel,
  IEmployeeAddViewModel,
  IEmployeeViewModel,
} from 'Interfaces/IEmployeeConfig';
import {
  ICompanyViewModel,
  IDepartmentViewModel,
} from 'Interfaces/ICompaniesConfig';
import {
  ICloudFactoryEmployeeAddListViewModel,
  IImportEmployeeAddListViewModel,
  IImportEmployeeAddViewModel,
} from './Converts/ICloudFactoryInterfaces';

export interface IImportEmployeesInterface {
  employees: IImportEmployeeAddViewModel[];
  selectedEmployees: string[]; // Using email as identifier
  primaryContacts: string[]; // Using email as identifier
}

const initialState: IImportEmployeesInterface = {
  employees: [],
  selectedEmployees: [],
  primaryContacts: [],
};

type Action =
  | { type: 'RESET_FORM' }
  | { type: 'SET_EMPLOYEES'; payload: IImportEmployeeAddViewModel[] }
  | { type: 'REMOVE_EMPLOYEE' }
  | { type: 'TOGGLE_EMPLOYEE_SELECTION'; payload: string }
  | { type: 'TOGGLE_PRIMARY_CONTACT'; payload: string };

const reducer = (
  state: IImportEmployeesInterface,
  action: Action
): IImportEmployeesInterface => {
  switch (action.type) {
    case 'RESET_FORM':
      return { ...initialState };
    case 'SET_EMPLOYEES':
      return { ...state, employees: action.payload };
    case 'REMOVE_EMPLOYEE':
      // loop igjennom alle employees og fjern de som er selected
      state.selectedEmployees.forEach((email) => {
        let employee = state.employees.find(
          (emp: IImportEmployeeAddViewModel) => emp.email === email
        );
        if (employee) {
          state.employees = state.employees.filter(
            (emp: IImportEmployeeAddViewModel) => emp.email !== email
          );
        }
      });

      return {
        ...state,
        employees: state.employees,
        selectedEmployees: [],
        primaryContacts: [],
      };
    case 'TOGGLE_EMPLOYEE_SELECTION':
      return {
        ...state,
        selectedEmployees: state.selectedEmployees.includes(action.payload)
          ? state.selectedEmployees.filter((email) => email !== action.payload)
          : [...state.selectedEmployees, action.payload],
      };
    case 'TOGGLE_PRIMARY_CONTACT':
      return {
        ...state,
        primaryContacts: state.primaryContacts.includes(action.payload)
          ? state.primaryContacts.filter((email) => email !== action.payload)
          : [...state.primaryContacts, action.payload],
      };
    default:
      return state;
  }
};

type Props = {
  expanded: boolean;
  employees: IImportEmployeeAddViewModel[];
  company: ICompanyViewModel | null;
  departments: IDepartmentViewModel[];
  handleRemoveEmployees: (employees: IEmployeeViewModel[]) => void;
};

export const ImportEmployeesList = ({
  expanded,
  employees,
  departments,
  company,
  handleRemoveEmployees,
}: Props) => {
  const { t } = useTranslation();
  const { height } = useWindowsDimension();
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [user] = useAtom(userAtom);
  console.log('bbb ', employees, company, departments);
  // API CALLS
  const postEmployee = usePost(
    rqPost.importEmployees(company?.id || '', true),
    true
  );
  useEffect(() => {
    if (employees.length > 0) {
      let tmp = _.cloneDeep(employees || []);
      dispatch({ type: 'RESET_FORM' });
      dispatch({ type: 'SET_EMPLOYEES', payload: tmp });
    }
  }, [employees]);

  const listHeight = Math.ceil(height - 272 - (expanded ? 386 : 0));

  // ON SUBMIT
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!company?.id) return;
    let employeeList: ICloudFactoryEmployeeAddListViewModel = {
      employees: [],
    };
    console.log('x user', user);
    console.log('x departments', departments);
    console.log('x company', company);
    state.selectedEmployees.forEach((email) => {
      let employee = state.employees.find(
        (emp: IImportEmployeeAddViewModel) => emp.email === email
      );
      dispatch({ type: 'REMOVE_EMPLOYEE' });
      if (employee) {
        employeeList.employees.push({
          name: employee.name,
          companyId: company.id,
          creatorId: user.id,
          phone: '+47 000 00 000',
          email: employee.email,
          departmentId: departments[0].id,
          primaryContact: state.primaryContacts.includes(employee.email),
          legacyId: employee.legacyId,
          thirdPartyId: employee.thirdPartyId,
          thirdParty: employee.thirdParty,
          productIds: employee.productIds || [],
        } as IImportEmployeeAddViewModel);
      }
    });

    console.log('uuu employeeList', employeeList);
    postEmployee.mutate(employeeList, {
      onSuccess: (res: any) => {
        if (res) {
          let tmp = _.cloneDeep(res.allEmployees || []);
          handleRemoveEmployees(tmp);
        }
      },
      onError: (error: any) => {},
    });
  };

  return (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
      }}
    >
      {/*   HEADER */}
      <Grid item xs={12}>
        <SearchFilter
          Icon={FeedRoundedIcon}
          Header={t('company.employees.detailsHeader')}
          filterValue={null}
          handleFilterChange={null}
          handleSearch={null}
          handleNew={null}
          selectList={null}
        />
      </Grid>

      <List
        sx={{
          width: '100%',
          height: listHeight,
          p: 1,
          borderRadius: 0,
          overflow: 'auto',
          backgroundColor: 'primary.main',
        }}
      >
        {state.employees.length > 0 &&
          state.employees.map(
            (employee) =>
              !employee.isEmailFoundInBluu && (
                <ListItem
                  key={employee.email}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box sx={{ flex: 1 }}>
                    <ListItemText
                      primary={employee.name}
                      secondary={employee.email}
                      primaryTypographyProps={{ color: 'white' }}
                      secondaryTypographyProps={{ color: 'white' }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mx: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        m: 0,
                        p: 0,
                        pr: 0,
                        textAlign: 'left',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                        fontSize: 14,
                        color: 'primary.text',
                      }}
                    >
                      {t('company.employees.primaryContact').toUpperCase()}
                    </Typography>
                    <Switch
                      checked={state.primaryContacts.includes(employee.email)}
                      color={
                        state.primaryContacts.includes(employee.email)
                          ? 'secondary'
                          : 'primary'
                      }
                      onChange={() =>
                        dispatch({
                          type: 'TOGGLE_PRIMARY_CONTACT',
                          payload: employee.email,
                        })
                      }
                    />
                  </Box>
                  <Checkbox
                    checked={state.selectedEmployees.includes(employee.email)}
                    color={
                      state.selectedEmployees.includes(employee.email)
                        ? 'secondary'
                        : 'primary'
                    }
                    onChange={() =>
                      dispatch({
                        type: 'TOGGLE_EMPLOYEE_SELECTION',
                        payload: employee.email,
                      })
                    }
                  />
                </ListItem>
              )
          )}
      </List>
      {/*   Submit Button */}

      <Grid
        item
        xs={12}
        sx={{
          m: 0,
          p: 1,
          height: 64,
          display: 'flex',
          flexDirection: 'row-reverse',
          backgroundColor: 'primary.main',
          borderRadius: 2,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Button
          onClick={(e) => {
            onSubmit(e);
          }}
          variant='contained'
        >
          {postEmployee.isLoading ? t('button.creating') : t('button.create')}
        </Button>
      </Grid>
    </Grid>
  );
};
