import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import CompanyView from 'components/ui/CompanyView';
import { SearchFilter } from 'components/ui/SearchFilter';
import { emptyCompany } from 'data/templates';
import { companyTypes } from 'data/enum';
import { getFilteredList } from 'functions/misc';
import useWindowsDimension from 'functions/useWindowsDimension';
import { Grid, List, Paper } from '@mui/material';
import { PageHeader } from 'components/ui/Global/PageHeader';
import Business from '@mui/icons-material/Business';
import { useTranslation } from 'react-i18next';
import { ICompanyViewModel, ThirdPartEnum } from 'Interfaces/ICompaniesConfig';
import _ from 'lodash';
import {
  EnumCompanyProductStatus,
  EnumPlacement,
  IAddressViewModel,
} from 'Interfaces/IGlobalTypeConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { IProductViewModel } from 'Interfaces/IProductConfig';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import { SingleCompanyProductView } from 'components/ui/SingleCompanyProductView';

export const initialCompanyProductState: ICompanyProductViewModel = {
  serviceTag: '',
  id: null,
  product: {} as unknown as IBillingProductViewModel,
  companyId: '',
  guaranteeEndDate: null,
  comment: '',
  unitPrice: 0,
  retailPrice: 0,
  discount: 0,
  lifetime: 0,
  security: false,
  lastBilled: null,
  status: EnumCompanyProductStatus.NONE,
  productWarning: '',
  quantity: 0,
  productQuantity: 0,
  purchaserId: '',
  ownerId: null,
  owner: null,
  resourceId: null,
  resource: null,
  placement: EnumPlacement.NONE,
  isActive: false,
  isBillable: false,
  serialNumber: null,
  productId: '',
  toggleEdit: false,
  isDateEditable: false,
};

export type CompanyProductListProps = {
  companyId: string;
  employeeId: string;
  handleChange?: (
    event: React.MouseEvent<HTMLElement>,
    companyProduct: ICompanyProductViewModel
  ) => void;
  isRemoveShowCompanyView?: boolean;
};

export const CompanyProductList = ({
  companyId,
  employeeId,
  handleChange,
  isRemoveShowCompanyView,
}: CompanyProductListProps) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { height } = useWindowsDimension();

  console.log('papa companyId', companyId);
  console.log('papa employeeId', employeeId);

  let isCompanyShowView = isRemoveShowCompanyView ? false : true;
  // API
  let isEnabled = companyId !== 'new' && employeeId !== 'new' ? true : false;
  const { data } = useGet(
    rq.employeeWithProducts(companyId, employeeId, isEnabled),
    isEnabled
  );

  // State
  const [companyProduct, setCompanyProduct] =
    useState<ICompanyProductViewModel>(initialCompanyProductState);
  const [companyProducts, setCompanyProducts] = useState<
    ICompanyProductViewModel[]
  >([]);

  // Handlers
  const handleNew = () => {};

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!data?.companyProducts) return;
    const filtered = getFilteredList(data.companyProducts, e.target.value);
    setCompanyProducts(filtered);
  };

  const handleFilterChange = (val: number) => {};

  // On data load/update, clone to local list
  useEffect(() => {
    if (!data?.companyProducts) return;
    console.log('papa data?.companyProducts ', data?.companyProducts);

    setCompanyProducts(_.cloneDeep(data.companyProducts));
  }, [data]);
  console.log('papa products ', companyProducts);
  // Whenever 'company' changes, call the parent's 'handleChange'

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Grid item xs={12}>
        <PageHeader
          Icon={Business}
          title={t('company.header')}
          FormJSX={null}
          HeaderButtons={[]}
        />
      </Grid>

      <Grid item xs={isCompanyShowView ? 5 : 12} sx={{ pr: 1 }}>
        <SearchFilter
          Icon={Business}
          Header={t('company.header')}
          filterValue={null}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
          handleNew={null}
          selectList={null}
        />

        <List
          sx={{
            height: Math.ceil(height - 244),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {companyProducts.length === 0 && <div>No data</div>}
          {companyProducts.map((cmp, index) => (
            <Grid
              key={index}
              sx={{ cursor: 'pointer', marginBottom: 1 }}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                // 1) Set the chosen company in your local state
                setCompanyProduct(cmp);
                // 2) Call the parent's handleChange
                handleChange?.(e, cmp);
              }}
            >
              <SingleCompanyProductView companyProduct={cmp} />
            </Grid>
          ))}
        </List>

        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        />
      </Grid>

      <Grid item xs={isCompanyShowView ? 7 : 0} sx={{ pl: 1 }}>
        <Paper variant='elevation3'></Paper>
      </Grid>
    </Grid>
  );
};
