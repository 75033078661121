import {
  EnumTicketStatus,
  EnumSubButtonInterface,
  IScheduleViewModel,
} from 'Interfaces/IGlobalTypeConfig';
import {
  IEmployeeViewModel,
  ITicketPostBillingViewModel,
  ITicketPostInterface,
  ITicketPostViewModel,
  ITicketViewModel,
} from 'Interfaces/ITicketConfig';
import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import dayjs from 'dayjs';
import CalculateTotalTime from 'functions/CalculateTotalTime';
import { returnGuidEmpty } from 'functions/GuidChecks';
import { calculateTicketPostBilling } from 'functions/calculateTicketPostBilling';
import { clone } from 'lodash';

export const TicketPostState: ITicketPostInterface = {
  ticketPosts: [],
  ticketPost: {
    id: returnGuidEmpty(),
    startTime: dayjs(new Date()),
    endTime: dayjs(new Date()),
    startDeduction: null,
    endDeduction: null,
    isBillable: true,
    isOvertime: false,
    isBilled: false,
    internal: false,
    isInHouse: true,
    isEmailSent: false,
    isCCSent: false,
    direction: 1,
    authorId: '',
    author: {} as IEmployeeViewModel,
    receiverId: '',
    message: '',
    attachments: [],
    workRole: null,
    ticketPostBilling: {
      id: returnGuidEmpty(),
      ticketPostId: returnGuidEmpty(),
      isBillable: true,
      workCost: 0,
      workHours: 0,
      workBillableValue: 0,
      notBillableHours: 0,
      notBillableCost: 0,
      notBillableBillingValue: 0,
      eveningCost: 0,
      eveningHours: 0,
      eveningBillableValue: 0,
      midDayCost: 0,
      midDayHours: 0,
      midDayBillableValue: 0,
      nightCost: 0,
      nightHours: 0,
      nightBillableValue: 0,
    } as unknown as ITicketPostBillingViewModel,
  } as unknown as ITicketPostViewModel,
  scheduleTime: {
    id: returnGuidEmpty(),
    deliveryDate: dayjs(new Date()),
    scheduleType: 0,
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  } as IScheduleViewModel,
  ticket: {} as unknown as ITicketViewModel,
  workRoles: [] as IWorkRoleViewModel[],
  totalTime: '00:00',
  totalValue: 0,
  isTotalValueOpen: false,
  isTotalTimeOpen: false,
  selectedDocuments: null,
  rates: [],
  isShowOverTime: false,
  isEditable: true,
  isEmailAllowed: true,
  page: EnumSubButtonInterface.Communication,
  ticketPostBilling: null,
};

export const CalculateTotalBillingValue = (
  ticketPostBilling: ITicketPostBillingViewModel | null
) => {
  if (!ticketPostBilling) {
    return 0;
  }
  let totalValue = 0;
  if (ticketPostBilling) {
    totalValue =
      ticketPostBilling.nightBillableValue +
      ticketPostBilling.workBillableValue +
      ticketPostBilling.midDayBillableValue +
      ticketPostBilling.eveningBillableValue;
  }
  console.log('CalculateTotalBillingValue - totalValue', totalValue);
  return totalValue;
};

// Definer handlingstyper
export type Action =
  | { type: 'SET_TICKET_POST_RESPONSIBLE'; payload: IEmployeeViewModel }
  | { type: 'SET_TICKET_POST_RECEIVER'; payload: IEmployeeViewModel }
  | { type: 'IS_SEND_EMAIL'; payload: boolean }
  | { type: 'IS_SEND_EMAIL_CC'; payload: boolean }
  | { type: 'UPDATE_TICKET_POST_BILLING' }
  | { type: 'SET_TICKET_POSTS'; payload: ITicketPostViewModel[] }
  | { type: 'SET_TICKET_POST'; payload: ITicketPostViewModel }
  | { type: 'SET_TICKET_POST_TOTAL_VALUE'; payload: number }
  | { type: 'REMOVE_TICKET_POST'; payload: ITicketPostInterface }
  | { type: 'SET_TICKET_POST_BILLING'; payload: ITicketPostBillingViewModel }
  | { type: 'SET_STATUS'; payload: EnumTicketStatus }
  | { type: 'SET_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | { type: 'SET_TICKET'; payload: ITicketViewModel }
  | { type: 'SET_WORK_ROLE'; payload: IWorkRoleViewModel }
  | { type: 'SET_WORK_ROLES'; payload: IWorkRoleViewModel[] }
  | { type: 'SET_IS_IN_HOUSE'; payload: boolean }
  | { type: 'SET_BILLABLE'; payload: boolean }
  | { type: 'SET_OVERTIME'; payload: boolean }
  | { type: 'SET_INTERNAL'; payload: boolean }
  | { type: 'SET_TOTAL_TIME'; payload: string }
  | { type: 'SET_TOTAL_TIME_OPEN'; payload: boolean }
  | { type: 'SET_TOTAL_VALUE'; payload: number }
  | { type: 'SET_TOTAL_VALUE_OPEN'; payload: boolean }
  | { type: 'SET_START_DATE'; payload: dayjs.Dayjs }
  | { type: 'SET_END_DATE'; payload: dayjs.Dayjs }
  | { type: 'SET_COMMENT'; payload: string }
  | { type: 'CHANGE_ATTACHMENTS'; payload: any }
  | { type: 'SET_SELECTED_DOCUMENTS'; payload: any }
  | { type: 'CHANGE_DIRECTION'; payload: number }
  | { type: 'SET_RATES'; payload: IRateViewModel[] }
  | { type: 'SET_SHOW_OVERTIME'; payload: boolean }
  | { type: 'EDIT_COMMENT'; payload: string }
  | { type: 'CHANGE_STATUS'; payload: any }
  | { type: 'SET_IS_EDITABLE'; payload: boolean }
  | { type: 'SET_IS_EMAIL_ALLOWED'; payload: boolean }
  | { type: 'SET_PAGE'; payload: EnumSubButtonInterface };

// Reducer-funksjonen
export const TicketPostReducer = (
  state: ITicketPostInterface = TicketPostState,
  action: Action
): ITicketPostInterface => {
  switch (action.type) {
    case 'SET_TICKET_POST_RESPONSIBLE':
      console.log('yyy SET_TICKET_POST_RESPONSIBLE', action.payload);
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          authorId: action.payload?.id ?? '',
          author: action.payload ?? null,
        },
      };
    case 'SET_TICKET_POST_RECEIVER':
      console.log('yyy SET_TICKET_POST_RECEIVER', action.payload);
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          receiverId: action.payload?.id ?? '',
          receiver: action.payload ?? null,
        },
      };
    case 'SET_IS_EMAIL_ALLOWED':
      return { ...state, isEmailAllowed: action.payload };
    case 'IS_SEND_EMAIL':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          isEmailSent: action.payload,
        },
      };
    case 'IS_SEND_EMAIL_CC':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          isCCSent: action.payload,
        },
      };
    case 'SET_TICKET_POSTS':
      return { ...state, ticketPosts: action.payload };
    case 'SET_TICKET_POST':
      console.log('ATP', action.payload);
      return {
        ...state,
        ticketPost: {
          ...action.payload,
        },
      };
    case 'SET_TICKET_POST_TOTAL_VALUE':
      return {
        ...state,
        totalValue: action.payload,
      };
    case 'SET_TICKET_POST_BILLING':
      return {
        ...state,
        ticketPostBilling: action.payload,
      };
    case 'REMOVE_TICKET_POST':
      let _ticketPost = {
        id: returnGuidEmpty(),
        startTime: dayjs(new Date()),
        endTime: dayjs(new Date()),
        startDeduction: null,
        endDeduction: null,
        isBillable: true,
        isOvertime: false,
        isBilled: false,
        internal: false,
        isInHouse: true,
        isEmailSent: false,
        isCCSent: false,
        direction: 1,
        authorId: '',
        receiverId: '',
        message: '',
        attachments: [],
        workRole: null,
        ticketPostBilling: {
          id: returnGuidEmpty(),
          ticketPostId: returnGuidEmpty(),
          isBillable: true,
          workCost: 0,
          workHours: 0,
          workBillableValue: 0,
          notBillableHours: 0,
          notBillableCost: 0,
          notBillableBillingValue: 0,
          eveningCost: 0,
          eveningHours: 0,
          eveningBillableValue: 0,
          midDayCost: 0,
          midDayHours: 0,
          midDayBillableValue: 0,
          nightCost: 0,
          nightHours: 0,
          nightBillableValue: 0,
        } as unknown as ITicketPostBillingViewModel,
      } as unknown as ITicketPostViewModel;

      return {
        ...state,
        ticketPost: _ticketPost,
        isEditable: true,
      };
    case 'SET_STATUS':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          status: action.payload,
        },
      };

    case 'SET_SCHEDULE_TIME':
      return { ...state, scheduleTime: action.payload };
    case 'SET_TICKET':
      return { ...state, ticket: action.payload };
    case 'SET_WORK_ROLE':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          workRole: action.payload,
        },
      };
    case 'SET_WORK_ROLES':
      return { ...state, workRoles: action.payload };
    case 'UPDATE_TICKET_POST_BILLING': {
      const cloned = clone(state);
      const newBilling = calculateTicketPostBilling(cloned);
      const newTotal = CalculateTotalBillingValue(newBilling);
      return {
        ...cloned,
        ticketPost: {
          ...cloned.ticketPost,
          ticketPostBilling: newBilling,
          totalBillingValue: newTotal,
        },
        ticketPostBilling: newBilling,
        totalValue: newTotal,
      };
    }

    case 'SET_IS_IN_HOUSE':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          isInHouse: action.payload,
        },
      };

    case 'SET_BILLABLE':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          isBillable: action.payload,
        },
      };

    case 'SET_OVERTIME':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          isOvertime: action.payload,
        },
      };
    case 'SET_INTERNAL':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          internal: action.payload,
          isBillable: false,
          isEmailSent: false,
        },
      };
    case 'SET_TOTAL_TIME':
      return { ...state, totalTime: action.payload };
    case 'SET_TOTAL_TIME_OPEN':
      return { ...state, isTotalTimeOpen: action.payload };
    case 'SET_TOTAL_VALUE':
      return { ...state, totalValue: action.payload };
    case 'SET_TOTAL_VALUE_OPEN':
      return { ...state, isTotalValueOpen: action.payload };
    case 'SET_START_DATE':
      let _state1 = clone(state);
      return {
        ...state,
        totalTime:
          CalculateTotalTime(
            action.payload,
            _state1.ticketPost.endTime,
            _state1.ticketPost.startDeduction,
            _state1.ticketPost.endDeduction
          )?.toString() ?? '00:00',
        ticketPost: {
          ...state.ticketPost,
          startTime: action.payload,
        },
      };
    case 'SET_END_DATE':
      let _state5 = clone(state);
      return {
        ...state,
        totalTime:
          CalculateTotalTime(
            _state5.ticketPost.startTime,
            action.payload,
            _state5.ticketPost.startDeduction,
            _state5.ticketPost.endDeduction
          )?.toString() ?? '00:00',
        ticketPost: {
          ...state.ticketPost,
          endTime: action.payload,
        },
      };

    case 'SET_COMMENT':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          message: action.payload,
        },
      };
    case 'CHANGE_ATTACHMENTS':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          attachments: action.payload,
        },
      };
    case 'SET_SELECTED_DOCUMENTS':
      return { ...state, selectedDocuments: action.payload };
    case 'CHANGE_DIRECTION':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          direction: action.payload,
        },
      };

    case 'SET_RATES':
      return { ...state, rates: action.payload };
    case 'SET_SHOW_OVERTIME':
      return { ...state, isShowOverTime: action.payload };
    case 'EDIT_COMMENT':
      if (state.ticketPost.status === EnumTicketStatus.New) {
        return {
          ...state,
          ticketPost: {
            ...state.ticketPost,
            message: state.ticketPost.message + action.payload,
            status: EnumTicketStatus.InProgress,
          },
        };
      } else {
        return {
          ...state,
          ticketPost: {
            ...state.ticketPost,
            message: action.payload,
            status: EnumTicketStatus.InProgress,
          },
        };
      }
    case 'CHANGE_STATUS':
      return {
        ...state,
        ticketPost: {
          ...state.ticketPost,
          status: action.payload,
        },
      };
    case 'SET_IS_EDITABLE':
      return { ...state, isEditable: action.payload };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    default:
      return state;
  }
};
