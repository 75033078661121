import useWindowsDimension from 'functions/useWindowsDimension';
import { rq } from 'http/apiRoutes';
import { useGet } from 'http/useInnovit';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Drawer, Box } from '@mui/material';
import { CompaniesList } from '../CompaniesList';
import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { CompanyProductList } from '../CompanyProductList';

type CompanyProductDrawerProps = {
  buttonIcon: React.ReactNode; // or 'any' if you prefer
  buttonContext: string; // or 'any', but string is typically used
  companyId: string;
  employeeId: string;
  handleChange: (
    event: React.MouseEvent<HTMLElement>,
    companyProduct: ICompanyProductViewModel
  ) => void;
};

export const CompanyProductDrawer = ({
  buttonIcon,
  buttonContext,
  companyId,
  employeeId,
  handleChange,
}: CompanyProductDrawerProps) => {
  const { width } = useWindowsDimension();
  const [open, setOpen] = useState(false);
  const [companyProducts, setCompanyProducts] = useState([]);

  let isEnabled = companyId !== 'new' && employeeId !== 'new' ? true : false;
  const { data: employeeWithProductsData } = useGet(
    rq.employeeWithProducts(companyId || '', employeeId || '', isEnabled),
    isEnabled
  );

  const handleLocalChange = (
    e: React.MouseEvent<HTMLElement>,
    companyProduct: ICompanyProductViewModel
  ) => {
    // 1) Call the "external" handleChange that you received as a prop
    handleChange(e, companyProduct);

    // 2) Close the drawer
    setOpen(false);
  };

  useEffect(() => {
    if (employeeWithProductsData) {
      console.log('data', employeeWithProductsData);
      setCompanyProducts(employeeWithProductsData.companyProducts);
    }
  }, [employeeWithProductsData]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%',
          height: '100%',
        }}
      >
        <Button
          sx={{ p: 0, m: 0 }}
          size='small'
          variant='contained'
          onClick={() => setOpen(!open)}
        >
          {buttonIcon}
        </Button>
      </Box>
      <Drawer anchor={'left'} open={open} onClose={() => setOpen(!open)}>
        <Box sx={{ width: width / 2, backgroundColor: 'primary.main' }}>
          <CompanyProductList
            companyId={companyId}
            employeeId={employeeId}
            handleChange={handleLocalChange}
            isRemoveShowCompanyView={true}
          />
        </Box>
      </Drawer>
    </>
  );
};
