import { memo, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  InputBase,
  List,
  Pagination,
  Paper,
  Typography,
  Button,
  Drawer,
  Box,
  Modal,
  TextField,
} from '@mui/material';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import { SingleProductView } from 'components/ui/SingleProductView';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { SearchFilter } from 'components/ui/SearchFilter';
import Business from '@mui/icons-material/Business';
import { SingleWorkRoleView } from 'components/ui/SingleWorkRoleView';
import { SingleAutoAnswerView } from 'components/ui/SingleAutoAnswerView';
import {
  EnumObjectType,
  enumToStringMapping,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { SingleStorageProductView } from 'components/ui/SingleStorageProductView';
import { SinglePackageView } from 'components/ui/SinglePackageView';
import { SingleCompanyProductView } from 'components/ui/SingleCompanyProductView';
import { SingleBrandView } from 'components/ui/SingleBrandView';
import { SingleResourceTypeView } from 'components/ui/SingleResourceTypeView';
import { TextStyle } from 'data/FontStyle';
import ConvertBase64StringToFileModel from 'functions/ConvertBase64StringToFileModel';
import { usePost } from 'http/useInnovit';
import { rqPost } from 'http/apiRoutes';
import { IProductFormInitialState } from 'Interfaces/IProductConfig';
import { useLocation, useNavigate, useParams } from 'react-router-dom'; // Import useNavigate hook
import { ObjectDrawerProductForm } from 'pages/Products/ObjectDrawerProductForm';

const checkTempType = (productState: any) => {
  if (
    !productState ||
    !productState.productType ||
    !productState.productType.category
  ) {
    // Return a default value or handle the case where these properties are not defined
    return 'defaultType'; // Replace with an appropriate default value
  }

  const category = productState.productType.category;
  // Implement your logic based on the category
  if (category === 'someCategory') {
    return 'someType';
  } else if (category === 'anotherCategory') {
    return 'anotherType';
  } else {
    return 'defaultType'; // Replace with an appropriate default value
  }
};

// Your existing ConvertToAddProduct function
const ConvertToAddProduct = (
  productState: any,
  image: any,
  base64String: any
) => {
  let productFeatureDataIds = [];

  if (productState.productFeatureList) {
    productFeatureDataIds = productState.productFeatureList
      .map((feature: any) => {
        const selectedFeature = feature.productFeatureData.find(
          (f: any) => f.description === feature.selectedDescription
        );

        return selectedFeature ? selectedFeature.id : undefined;
      })
      .filter((id: any) => id !== undefined);
  }

  let fileModel = null;
  if (image) {
    fileModel = ConvertBase64StringToFileModel(base64String, image.name);
  }
  // console.log('productState', productState);
  let productToAdd = {
    name: productState.name,
    comment: productState.comment,
    serialNumber: '',
    unitPrice: productState.unitPrice,
    retailPrice: productState.retailPrice,
    security: parseInt(productState.security.id),
    fileModel: fileModel,
    imageFile: productState.imageFile,
    productTypeId: productState.productType.id,
    EAN: productState.EAN ?? '',
    brandId: productState.brandId,
    quantity: productState.quantity,
    paymentCycle: parseInt(productState.paymentCycle.id),
    cycleAlignment: parseInt(productState.cycleAlignment.id),
    bindingPeriod: parseInt(productState.bindingPeriod.id),
    productFeatureDataIds: productFeatureDataIds,
    tempType: checkTempType(productState),
    legacyId: 0,
    thirdPartyId: '',
    thirdParty: 0,
  };

  return productToAdd;
};

type FormOrMouseEvent =
  | React.FormEvent<HTMLFormElement>
  | React.MouseEvent<HTMLDivElement, MouseEvent>;
type Props = {
  buttonIcon?: any | null;
  buttonContext: string;
  objectList: any;
  objectType: EnumObjectType[];
  handleChange: (
    event: FormOrMouseEvent,
    selectedObject: any,
    objectType: EnumObjectType
  ) => void;
  buttonHeight: any;
  buttonWidth: any;
};
const getInitialFormState = (objectType: EnumObjectType) => {
  switch (objectType) {
    case EnumObjectType.Company:
      return { name: '', address: '', phone: '', email: '' };
    case EnumObjectType.Product:
      return {
        name: '',
        description: '',
        price: '',
        productFeatureList: [],
        security: { id: '' },
        productType: { id: '' },
        brandId: '',
        quantity: '',
        paymentCycle: { id: '' },
        cycleAlignment: { id: '' },
        bindingPeriod: { id: '' },
        imageFile: null,
      };
    case EnumObjectType.AppOwnerEmployees:
      return { name: '', position: '', email: '' };
    default:
      return { name: '' };
  }
};

const renderFormFields = (
  formState: any,
  handleInputChange: any,
  objectType: any
) => {
  switch (objectType) {
    case EnumObjectType.Company:
      return (
        <>
          <TextField
            label='Name'
            fullWidth
            name='name'
            value={formState.name}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
          <TextField
            label='Address'
            fullWidth
            name='address'
            value={formState.address}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
          <TextField
            label='Phone'
            fullWidth
            name='phone'
            value={formState.phone}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
          <TextField
            label='Email'
            fullWidth
            name='email'
            value={formState.email}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
        </>
      );
    case EnumObjectType.Product:
      return (
        <>
          <TextField
            label='Name'
            fullWidth
            name='name'
            value={formState.name}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
          <TextField
            label='Description'
            fullWidth
            name='description'
            value={formState.description}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
          <TextField
            label='Price'
            fullWidth
            name='price'
            value={formState.price}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
          {/* Add more fields as required */}
        </>
      );
    case EnumObjectType.AppOwnerEmployees:
      return (
        <>
          <TextField
            label='Name'
            fullWidth
            name='name'
            value={formState.name}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
          <TextField
            label='Position'
            fullWidth
            name='position'
            value={formState.position}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
          <TextField
            label='Email'
            fullWidth
            name='email'
            value={formState.email}
            onChange={handleInputChange}
            sx={{ mt: 2 }}
          />
        </>
      );
    default:
      return (
        <TextField
          label='Name'
          fullWidth
          name='name'
          value={formState.name}
          onChange={handleInputChange}
          sx={{ mt: 2 }}
        />
      );
  }
};

export const ObjectDrawer = ({
  objectList: objectDrawerList,
  objectType,
  buttonContext,
  buttonIcon,
  handleChange,
  buttonHeight,
  buttonWidth,
}: Props) => {
  console.log('object drawer - objectList: ', objectDrawerList);
  // INITIALIZATION
  const { width, height } = useWindowsDimension();
  const { productId, companyId } = useParams();
  const [expandedModal, setExpandedModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedObjectType, setSelectedObjectType] = useState<EnumObjectType>(
    objectType[0]
  );
  const [selectedObject, setSelectedObject] = useState<objectArray>(
    objectDrawerList[
      objectDrawerList.findIndex(
        (item: objectArray) => item.objectType === objectType[0]
      )
    ]
  );
  const [filteredList, setFilteredList] = useState<any>([]);
  const [originalList, setOriginalList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [formState, setFormState] = useState(
    getInitialFormState(selectedObjectType)
  );
  const [image, setImage] = useState(null);
  const [base64String, setBase64String] = useState(null);

  // POST HOOKS
  const postProduct = usePost(rqPost.product(productId || '', true), true);

  // NAVIGATION HOOK
  const navigate = useNavigate();
  const [originalPath, setOriginalPath] = useState(''); // State to store original path

  // SEARCH FUNCTIONALITY
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.toLowerCase());
  };

  // HANDLES
  const handleChangeSelectedObjectType = (e: any, enumType: EnumObjectType) => {
    e.preventDefault();
    setSelectedObjectType(enumType);
    setFormState(getInitialFormState(enumType));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  type HandleNewType = (e: FormOrMouseEvent, object: any) => void;
  const handleNewObject: HandleNewType = (e, object) => {
    console.log('xxx: object', object);
    console.log('xxx: selectedObjectType', selectedObjectType);
    handleChange(e, object, selectedObjectType);
    setExpandedModal(false);
  };

  // HANDLES FOR NAVIGATION
  const handleCreateNewItemClick = () => {
    setExpandedModal(true);
  };

  // TOGGLES
  const toggleObjectType = () => {
    return objectType.map((item: EnumObjectType, index: number) => {
      const itemName = enumToStringMapping[item];
      return (
        <Grid
          item
          xs={3}
          key={index}
          sx={{ pl: 1, mt: 1, mb: -1, pb: 2, width: '100%' }}
        >
          <Button
            variant='contained'
            key={index}
            sx={{ height: 40, width: '90%' }}
            onClick={(e: any) => {
              handleChangeSelectedObjectType(e, item);
            }}
          >
            {itemName}
          </Button>
        </Grid>
      );
    });
  };

  // USE EFFECT
  useEffect(() => {
    const filtered = objectDrawerList.find(
      (item: objectArray) => item.objectType === selectedObjectType
    );
    if (!!filtered) {
      if (filtered) {
        setSelectedObject(filtered);
        setFilteredList(filtered.objectList);
        setOriginalList(filtered.objectList);
      }
    }

    // console.log('...selected object type: ' + selectedObjectType);
  }, [objectDrawerList, objectType, selectedObjectType]);

  useEffect(() => {
    const filtered =
      searchText === ''
        ? selectedObject.objectList
        : selectedObject.objectList.filter((item: any) => {
            return (
              item.name.toLowerCase().includes(searchText) ||
              (item.phone && item.phone.toLowerCase().includes(searchText)) ||
              (item.email && item.email.toLowerCase().includes(searchText))
            );
          });
    setFilteredList(filtered);
  }, [searchText, selectedObject, expandedModal]);

  // PAGINATION
  const itemsPerPage = 16;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredList.length / itemsPerPage);
  const handlePageChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  // console.log("width =>", 400)

  return (
    <>
      {buttonIcon === null ? (
        <Button
          variant='contained'
          sx={{
            borderRadius: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            width: buttonWidth || '100%',
            height: buttonHeight || '100%',
          }}
          onClick={() => setOpen(!open)}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: 2,
              color: 'primary.text',
            }}
          >
            {buttonContext}
          </Typography>
        </Button>
      ) : (
        <Box
          sx={[
            TextStyle.buttonBox,
            {
              width: buttonWidth || 32,
              height: buttonHeight || 32,
            },
          ]}
        >
          <IconButton
            sx={{
              p: 0,
              m: 0,
              width: buttonWidth - 5 || 18,
              height: buttonHeight - 5 || 18,
            }}
            onClick={() => setOpen(!open)}
          >
            {buttonIcon}
          </IconButton>
        </Box>
      )}

      <Drawer
        anchor={'left'}
        open={open}
        onClose={() => setOpen(!open)}
        sx={{ backgroundColor: 'transparent' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: expandedModal === true ? width : width / 2,
            backgroundColor: 'primary.back',
            borderRadius: 2,
          }}
        >
          <Grid
            item
            sx={{
              width: width / 2,
              pr: 1,
            }}
          >
            {selectedObject && (
              <Grid
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexGrow: 1,
                  backgroundColor: 'transparent',
                }}
              >
                <Grid item xs={12} sx={{ pr: 0, m: 0 }}>
                  <SearchFilter
                    Icon={Business}
                    Header={buttonContext}
                    filterValue={''}
                    handleFilterChange={null}
                    handleSearch={handleSearch}
                    hideAll={false}
                  />
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexGrow: 1,
                      backgroundColor: 'transparent',
                    }}
                  >
                    {toggleObjectType()}
                    <Button
                      variant='contained'
                      sx={{ margin: 2 }}
                      onClick={handleCreateNewItemClick}
                    >
                      Create New Item
                    </Button>
                  </Grid>
                  <List
                    sx={{
                      height: Math.ceil(height - 192),
                      width: '100%',
                      mb: 0,
                      p: 2,
                      backgroundColor: 'primary.back',
                      overflow: 'auto',
                    }}
                  >
                    {currentItems?.length === 0 && (
                      <div>No {objectType} found</div>
                    )}
                    {currentItems?.map((item: any, index: number) => (
                      <Paper
                        key={index}
                        onClick={(e) => {
                          /* console.log(
                            '...clicked item in objectDrawer: ',
                            selectedObjectType
                          ); */
                          handleChange(e, item, selectedObjectType);
                          setOpen(false);
                        }}
                        sx={{
                          ':hover': {
                            cursor: 'pointer',
                            filter: 'brightness(120%)',
                            transition: 'all 0.2s ease-in-out',
                          },
                        }}
                      >
                        {selectedObject?.objectList?.length >= 1 &&
                          selectedObjectType === EnumObjectType.Vendor && (
                            <SingleCompanyView index={index} company={item} />
                          )}
                        {selectedObjectType === EnumObjectType.Company && (
                          <SingleCompanyView index={index} company={item} />
                        )}
                        {selectedObjectType === EnumObjectType.Product && (
                          <SingleProductView index={index} product={item} />
                        )}
                        {selectedObjectType ===
                          EnumObjectType.StorageProduct && (
                          <SingleStorageProductView storageProduct={item} />
                        )}
                        {selectedObjectType ===
                          EnumObjectType.CompanyEmployees && (
                          <SingleEmployeeView employee={item} />
                        )}
                        {selectedObjectType === EnumObjectType.allEmployees && (
                          <SingleEmployeeView employee={item} />
                        )}
                        {selectedObjectType ===
                          EnumObjectType.AppOwnerEmployees && (
                          <SingleEmployeeView employee={item} />
                        )}
                        {selectedObjectType === EnumObjectType.Package && (
                          <SinglePackageView packageData={item} />
                        )}
                        {(selectedObjectType ===
                          EnumObjectType.CompanyProduct ||
                          selectedObjectType ===
                            EnumObjectType.unassignedCompanyProduct) && (
                          <SingleCompanyProductView companyProduct={item} />
                        )}
                        {selectedObject?.objectList?.length >= 1 &&
                          selectedObjectType === EnumObjectType.WorkRole && (
                            <SingleWorkRoleView workRole={item} />
                          )}
                        {selectedObjectType === EnumObjectType.IssueType && (
                          <SingleAutoAnswerView autoAnswer={item} />
                        )}
                        {selectedObjectType ===
                          EnumObjectType.EmployeeProducts && (
                          <SingleCompanyProductView companyProduct={item} />
                        )}
                        {selectedObject?.objectList?.length >= 1 &&
                          selectedObjectType ===
                            EnumObjectType.primaryContacts && (
                            <SingleEmployeeView employee={item} />
                          )}
                        {selectedObject?.objectList?.length >= 1 &&
                          selectedObjectType ===
                            EnumObjectType.ResourceTypes && (
                            <SingleResourceTypeView resourceType={item} />
                          )}
                      </Paper>
                    ))}
                  </List>
                  <Paper
                    variant='elevation3'
                    sx={{
                      p: 2,
                      height: 64,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    {totalPages > 1 && (
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{ margin: 'auto', padding: '10px' }}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Grid>
          {expandedModal === true &&
            selectedObjectType === EnumObjectType.Product && (
              <Grid
                sx={{
                  width: width / 2,
                  borderRadius: 2,
                  pl: 1,
                  pr: 1,
                }}
              >
                <ObjectDrawerProductForm handleNew={handleNewObject} />
              </Grid>
            )}
        </Box>
      </Drawer>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant='h6' component='h2'>
            Create New {enumToStringMapping[selectedObjectType]}
          </Typography>
          {renderFormFields(formState, handleInputChange, selectedObjectType)}
        </Box>
      </Modal>
    </>
  );
};
