export const returnGuidEmpty = () => {
  return '00000000-0000-0000-0000-000000000000';
};

export const isValidGuid = (id: string): boolean => {
  // RegEx for å sjekke om id er en gyldig GUID, og den skal ikke være en tom GUID
  const guidPattern =
    /^[{]?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}[}]?$/;

  // Sjekk om id matcher GUID-format og ikke er en tom GUID
  return guidPattern.test(id);
};

export const isGuidEmpty = (id: string): boolean => {
  return id === '00000000-0000-0000-0000-000000000000';
};

export function GenerateGuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
}

