import { ICloudFactoryProductsViewModel } from './CludFactoryConfig';
import { IImportProductAddViewModel } from './ICloudFactoryInterfaces';

export const ToCloudFactoryTempProduct = (
  productData: any,
  productCatalogData: any,
  price: any,
  companyId: string
): any => {
  let _costPrice =
    productCatalogData.billingTerm === 1 ? price.cost / 12 : price.cost;
  let _unitPrice =
    productCatalogData.billingTerm === 1 ? price.sale / 12 : price.sale;

  let newListItem: ICloudFactoryProductsViewModel = {
    companyId: companyId,
    name: productData.name,
    description: productCatalogData.description,
    sku: productCatalogData.sku,
    costPrice: _costPrice,
    unitPrice: _unitPrice,
    bindingPeriod: productCatalogData.recursionTerm,
    paymentCycle: productCatalogData.billingTerm,
    quantity: productData?.quantity ?? 0,
    commitmentEndDate: productData?.commitmentEndDate ?? '',
  };

  return newListItem;
};
