import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { IEmployeeInterface, ITicketEmployeeInterface } from 'Interfaces/IEmployeeConfig';

import { employeeInitialState } from 'pages/Companies/EmployeeForm';


export const ticketEmployeeInitial: ITicketEmployeeInterface = {
  employee: employeeInitialState,
  employeeProducts: [] as ICompanyProductViewModel[],
}



type EmployeeAction = 
  { type: 'SET_EMPLOYEE'; payload: IEmployeeInterface }
| { type: 'SET_EMPLOYEE_PRODUCTS'; payload: ICompanyProductViewModel[] };
;

export const EmployeeReducer = (
  state: ITicketEmployeeInterface = ticketEmployeeInitial,
  action: EmployeeAction
): ITicketEmployeeInterface => {
  switch (action.type) {
    case 'SET_EMPLOYEE':
      return {
        ...state,
        employee: action.payload,
      };
    case 'SET_EMPLOYEE_PRODUCTS':
      return {
        ...state,
        employeeProducts: action.payload,
      };
    default:
      return state;
  }
};
