import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import VillaRoundedIcon from '@mui/icons-material/VillaRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { HeaderButtonInterface } from 'Interfaces/IGlobalTypeConfig';
import i18n from 'i18next';


export const CompanyHeaderButtonDetails = (
  linkId1: string | null,
  linkId2: string | null, 
): HeaderButtonInterface[] => [
    {
        name: i18n.t('company.links.details'),
        component: { Link },
        to: `/companies/${linkId1}`,
        icon: DashboardIcon,
      },  
      {
        name: i18n.t('company.links.departments'),
        component: { Link },
        to: `/companies/${linkId1}/departments`,
        icon: VillaRoundedIcon,
      },
      {
        name: i18n.t('company.links.resources'),
        component: { Link },
        to: `/companies/${linkId1}/resources`,
        icon: MapsHomeWorkRoundedIcon,
      },
      {
        name: i18n.t('company.links.employees'),
        component: { Link },
        to: `/companies/${linkId1}/employees`,
        icon: PeopleAltRoundedIcon,
      },
      {
        name: i18n.t('company.links.packages'),
        component: { Link },
        to: `/companies/${linkId1}/packages`,
        icon: Inventory2RoundedIcon,
      },
  
      {
        name: i18n.t('company.links.agreements'),
        component: { Link },
        to: `/companies/${linkId1}/agreements`,
        icon: HandshakeRoundedIcon,
      },
  
      {
        name: i18n.t('company.links.products'),
        component: { Link },
        to: `/companies/${linkId1}/products`,
        icon: DevicesOtherRoundedIcon,
      },
  
      {
        name: i18n.t('company.links.tickets'),
        component: { Link },
        to: `/companies/${linkId1}/tickets`,
        icon: FactCheckRoundedIcon,
      },
  
    /*   {
        name: i18n.t('company.links.finance'),
        component: { Link },
        to: `/companies/${linkId1}/finance`,
        icon: AutoGraphIcon,
      }, */
];
