import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import CompanyView from 'components/ui/CompanyView';
import { SearchFilter } from 'components/ui/SearchFilter';
import { emptyCompany } from 'data/templates';
import { companyTypes } from 'data/enum';
import { getFilteredList } from 'functions/misc';
import useWindowsDimension from 'functions/useWindowsDimension';
import { Grid, List, Paper } from '@mui/material';
import { PageHeader } from 'components/ui/Global/PageHeader';
import Business from '@mui/icons-material/Business';
import { useTranslation } from 'react-i18next';
import {
  ICompanyViewModel,
  IDepartmentViewModel,
  ThirdPartEnum,
} from 'Interfaces/ICompaniesConfig';
import _ from 'lodash';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { IAddressViewModel } from 'Interfaces/IGlobalTypeConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { SingleTicketEmployeeView } from 'components/ui/SingleTicketEmployeeView';

export const initialEmployeeState: IEmployeeViewModel = {
  id: 'new',
  name: '',
  phone: '',
  email: '',
  departmentId: '',
  department: {} as unknown as IDepartmentViewModel,
  address: {} as unknown as IAddressViewModel,
  role: 0,
  primaryContact: false,
  globalAdministrator: false,
  budget: 0,
  title: '',
  companyProducts: [] as unknown as ICompanyProductViewModel[],
  workRole: null,
  url: '',
  legacyId: 0,
  thirdPartyId: '',
  thirdParty: ThirdPartEnum.None,
};

type EmployeeListProps = {
  handleChange: (
    event: React.MouseEvent<HTMLElement>,
    employee: IEmployeeViewModel
  ) => void;
  employees: IEmployeeViewModel[]
};

export const EmployeeList = ({ 
  handleChange,
  employees, 
}: EmployeeListProps) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { height } = useWindowsDimension();
  

  // State
  const [employee, setEmployee] =
    useState<IEmployeeViewModel>(initialEmployeeState);
  const [filteredEmployees, setFilteredEmployees] = useState<IEmployeeViewModel[]>([]);

  // Handlers

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!employees) return;
    const filtered = getFilteredList(employees, e.target.value);
    setFilteredEmployees(filtered);
  };

  const handleFilterChange = (val: number) => {
   
  };
 
  // Whenever 'company' changes, call the parent's 'handleChange'

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Grid item xs={12}>
        <PageHeader
          Icon={Business}
          title={t('employee.header')}
          FormJSX={null}
          HeaderButtons={[]}
        />
      </Grid>

      <Grid item xs={12} sx={{ pr: 1 }}>
        <SearchFilter
          Icon={Business}
          Header={t('employee.header')}
          filterValue={null}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
          handleNew={null}
          selectList={null}
        />

        <List
          sx={{
            height: Math.ceil(height - 244),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {employees.length === 0 && <div>No data</div>}
          {employees.map((emp, index) => (
            <Grid
              key={index}
              sx={{ cursor: 'pointer', marginBottom: 1 }}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                // 1) Set the chosen company in your local state
                setEmployee(emp);
                // 2) Call the parent's handleChange
                handleChange(e, emp);
              }}
            >
              <SingleTicketEmployeeView employee={emp} />
            </Grid>
          ))}
        </List>

        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        />
      </Grid>      
    </Grid>
  );
};
