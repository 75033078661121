import { Document, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { pdfStyle } from './PDFStyle';
import { PDFHeaderView } from './PDFHeaderView';
import { PDFFooterView } from './PDFFooterView';
import { PDFInvestmentView } from './PDFInvestmentView';
import { PDFSupportView } from './PDFSupportView';
import { PDFAgreementView } from './PDFAgreementView';
import { PDFOtherView } from './PDFOtherView';
import { PDFTotalView } from './PDFTotalView';
import { BillingCompanyViewModel } from 'pages/Billing/BillingConfig';

interface Props {
  data: BillingCompanyViewModel;
}

export const PDFFile = ({ data }: Props) => {
  // Beregn totalverdi og totalkostnad
  const calculatedData = {
    ...data,
    totalInvoiceValue:
      data.agreementInvoiceValue +
      data.supportInvoiceValue +
      data.investmentInvoiceValue +
      data.otherInvoiceValue,
    totalCost:
      data.agreementCost +
      data.supportCost +
      data.investmentCost +
      data.otherCost,
  };

  return (
    <Document>
      <Page style={pdfStyle.body}>
        <PDFHeaderView data={calculatedData} />
        <Text style={pdfStyle.subText}>OVERVIEW</Text>
        <PDFTotalView data={calculatedData} />
        <Text style={pdfStyle.subText}>DETAILS</Text>
        <View style={pdfStyle.divider} />
        <View style={pdfStyle.avsnitt} />

        {/* Vis seksjoner basert på fakturaverdiene */}
        {calculatedData.agreementInvoiceValue > 0 && (
          <PDFAgreementView data={calculatedData} />
        )}
        {calculatedData.investmentInvoiceValue > 0 && (
          <PDFInvestmentView data={calculatedData} />
        )}
        {calculatedData.supportInvoiceValue > 0 && (
          <PDFSupportView data={calculatedData} />
        )}
        {calculatedData.otherInvoiceValue > 0 && (
          <PDFOtherView data={calculatedData} />
        )}

        <PDFFooterView data={calculatedData} />
      </Page>
    </Document>
  );
};
