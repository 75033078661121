import { useReducer, useState, useEffect } from 'react';
import {
  Button,
  Grid,
  List,
  Paper,
  Drawer,
  Box,
  Typography,
  Pagination,
  IconButton,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  TextField,
  Stack,
} from '@mui/material';
import { EnumObjectType } from 'Interfaces/IGlobalTypeConfig';
import { IProductViewModel } from 'Interfaces/IProductConfig';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { useGet, usePost } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { t } from 'i18next';
import { SingleEmployeeDrawerCompanyProductView } from 'components/ui/SingleEmployeeDrawerCompanyProductView';
import { SingleEmployeeDrawerStorageProductView } from 'components/ui/SingleEmployeeDrawerStorageProductView';
import { SingleEmployeeDrawerProductView } from 'components/ui/SingleEmployeeDrawerProductView';
import { Height } from '@mui/icons-material';
import useWindowsDimension from 'functions/useWindowsDimension';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { SingleProductView } from 'components/ui/SingleProductView';

// Types
interface State {
  consumableProducts: IProductViewModel[];
  selectedProducts: IProductViewModel[];
}

export type Action =
  | { type: 'SET_PRODUCT_CONSUMABLES'; payload: IProductViewModel[] }
  | { type: 'ADD_SELECTED_PRODUCTS'; payload: IProductViewModel }
  | {
      type: 'REMOVE_PRODUCT';
      payload: { product: IProductViewModel; index: number };
    }
  | { type: 'CLEAR_SELECTED_PRODUCTS'; payload?: IProductViewModel[] };

interface Props {
  companyId: string;
  ticketId: string;
}

// Reducer and Initial State
const initialState: State = {
  consumableProducts: [],
  selectedProducts: [],
};

const productReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_PRODUCT_CONSUMABLES':
      console.log('getProducts', action.payload);
      return { ...state, consumableProducts: action.payload };
    case 'ADD_SELECTED_PRODUCTS':
      console.log('getProducts: rducer', action.payload);
      return {
        ...state,
        selectedProducts: [...state.selectedProducts, action.payload],
      };
    case 'REMOVE_PRODUCT': {
      const indexToRemove = action.payload.index;

      // Hvis produktet ikke finnes, returner uendret state
      if (indexToRemove === -1) {
        return state;
      }

      // Lag en kopi av arrayet og fjern én forekomst
      const updatedProducts = [...state.selectedProducts];
      updatedProducts.splice(indexToRemove, 1); // Fjern kun én forekomst

      return {
        ...state,
        selectedProducts: updatedProducts,
      };
    }
    case 'CLEAR_SELECTED_PRODUCTS':
      return {
        ...state,
        selectedProducts: [],
      };

    default:
      return state;
  }
};

export const TicketProductDrawer = ({ ticketId, companyId }: Props) => {
  const [state, dispatch] = useReducer(productReducer, initialState);
  const { height } = useWindowsDimension();
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedConsumableProducts, setSelectedConsumableProducts] = useState<
    any[]
  >([]);

  const [currentProducts, setCurrentProducts] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [objectType, setObjectType] = useState<EnumObjectType>(
    EnumObjectType.Product
  );

  // API Call to fetch products
  let isEnabled = ticketId !== 'new' ? true : false;

  const postConsumables = usePost(
    rqPost.consumableProducts(companyId, ticketId, isEnabled),
    isEnabled
  );
  const { data: getProducts } = useGet(rq.consumables(isEnabled), isEnabled);

  useEffect(() => {
    if (getProducts) {
      // Dispatch data from API to state
      dispatch({
        type: 'SET_PRODUCT_CONSUMABLES',
        payload: getProducts.products,
      });

      console.log('getProducts data: ', getProducts);
    }
  }, [getProducts]);

  // Lagre valgte produkter separat
  const onSubmit = (e: any) => {
    e.preventDefault();
    const selectedProductIds = state.selectedProducts.map(
      (product) => product.id
    );
    console.log(selectedProductIds);

    postConsumables.mutate(selectedProductIds, {
      onSuccess: (res: any) => {
        console.log('onSubmit response: ', res);
      },
    });

    setDrawerOpen(false);
  };

  // Function to handle pagination
  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  const handleSelectConsumable = (
    e: any,
    consumableProduct: IProductViewModel
  ) => {
    e.preventDefault();

    dispatch({ type: 'ADD_SELECTED_PRODUCTS', payload: consumableProduct });
  };

  const handleRemoveProduct = (
    event: any,
    consumableProduct: IProductViewModel,
    index: number
  ) => {
    dispatch({
      type: 'REMOVE_PRODUCT',
      payload: { product: consumableProduct, index: index },
    });
  };

  // Paginate the products
  const paginateProducts = (products: any[]) => {
    const startIndex = (page - 1) * itemsPerPage;
    return products.slice(startIndex, startIndex + itemsPerPage);
  };

  const clearSelection = () => {
    dispatch({
      type: 'CLEAR_SELECTED_PRODUCTS',
    });
    console.log('Selection cleared.');
  };

  // Render the product list with selection toggle
  const renderProductList = (products: IProductViewModel[]) => {
    console.log('getProducts: renderProductList:', products); // Logg produktene som vises
    return (
      <>
        <List
          sx={{
            width: '100%',
            p: 2,
            height: height - 230,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {paginateProducts(products).map(
            (product: IProductViewModel, index: number) => (
              <Paper
                key={index}
                sx={{
                  margin: 1,
                  backgroundColor: state.consumableProducts.some(
                    (p) => p.id === product.id
                  )
                    ? 'primary.main'
                    : 'primary.main',
                  ':hover': {
                    cursor: 'pointer',
                    filter: 'brightness(120%)',
                    transition: 'all 0.2s ease-in-out',
                  },
                }}
                onClick={(e) => {
                  handleSelectConsumable(e, product);
                }}
              >
                {/* Conditionally render based on the product type */}
                {objectType === EnumObjectType.Product && (
                  <SingleEmployeeDrawerProductView
                    product={product as IProductViewModel}
                    isOnboarding={isOnboarding}
                  />
                )}
              </Paper>
            )
          )}
        </List>

        {/* Pagination */}
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
          <Pagination
            count={Math.ceil(products.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
          />
          <Typography sx={{ mr: 2 }}>Items per page:</Typography>
          <Select
            size='small'
            variant='outlined'
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            sx={{ width: 85, mt: -1 }}
          >
            {[5, 10, 15, 20, 25, 50, 100].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </>
    );
  };

  // Render the selected products inside the drawer on the right side
  const renderSelectedProducts = () => {
    return (
      <Box sx={{ width: '100%', p: 2 }}>
        <Typography variant='h6' sx={{ m: 0, p: 2 }}>
          {t('Ticket.TicketDetails.serviceProducts')}
        </Typography>

        {/* Selected Company Products */}
        <Typography variant='subtitle1'>
          {t('Ticket.TicketDetails.consumerProducts').toUpperCase()}
        </Typography>
        <List
          sx={{
            width: '100%',
            m: 0,
            p: 1,
            backgroundColor: 'primary.back',
            height: height - 220,
            overflow: 'auto',
          }}
        >
          {state.selectedProducts.length > 0 &&
            state.selectedProducts.map(
              (product: IProductViewModel, index: number) => (
                <Paper
                  key={index}
                  sx={{ m: 1, cursor: 'pointer' }}
                  onClick={(e) => {
                    handleRemoveProduct(e, product, index);
                  }}
                >
                  <SingleProductView product={product as IProductViewModel} />
                </Paper>
              )
            )}
        </List>

        {/* Selected Product Catalog with Onboarding Switch */}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pt: 2,
          }}
        >
          <Button type='submit' variant='contained' onClick={clearSelection}>
            Clear
          </Button>
          <Button type='submit' variant='contained' onClick={onSubmit}>
            Save
          </Button>
        </Box>
      </Box>
    );
  };

  const renderInfo = () => {
    return (
      <Box sx={{ width: '100%', p: 2 }}>
        <Stack
          display='flex'
          direction='row'
          sx={{ m: 0, justifyContent: 'space-between' }}
        >
          <Typography variant='h6' sx={{ mb: 3 }}>
            Informasjon
          </Typography>
          <Button
            type='submit'
            variant='contained'
            onClick={() => setDrawerOpen(false)}
          >
            X
          </Button>
        </Stack>

        {/* Selected Company Products */}
        <Typography variant='subtitle1' sx={{ height: 30 }}></Typography>
        <List
          sx={{
            width: '100%',
            m: 0,
            p: 1,
            backgroundColor: 'transparent',
            height: height - 220,
            overflow: 'auto',
          }}
        >
          <Typography variant='subtitle1'>
            {t('employeeDrawer.reuseInfo')}
          </Typography>
        </List>
      </Box>
    );
  };

  const filteredProducts = state.consumableProducts.filter(
    (product: IProductViewModel) => {
      if (!searchQuery) {
        return true;
      }

      const searchableFields: (keyof IProductViewModel)[] = ['name', 'comment'];

      return searchableFields.some((field) => {
        const fieldValue = product[field as keyof IProductViewModel];
        return (
          fieldValue &&
          fieldValue
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      });
    }
  );

  console.log('getProducts: state:', state); // Logg produktene som vises

  return (
    <>
      <Box
        sx={[
          {
            width: 32,
            height: 32,
          },
        ]}
      >
        <IconButton
          sx={{
            p: 0,
            m: 0,
            width: 18,
            height: 18,
          }}
          onClick={() => setDrawerOpen(true)}
        >
          <AddRoundedIcon sx={{ fontSize: 26 }} />
        </IconButton>
      </Box>

      <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ width: '100%', flexShrink: 0 }}
        PaperProps={{ style: { width: '100%' } }}
      >
        <Box sx={{ display: 'flex', padding: 2 }}>
          {/* Left side: Product list */}
          <Box sx={{ width: '40%' }}>
            {/* Render category buttons */}
            <Grid
              container
              sx={{ display: 'flex', flexDirection: 'row' }}
            ></Grid>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 2,
              }}
            >
              <Typography variant='h6'>Product List</Typography>
              <TextField
                variant='outlined'
                size='small'
                placeholder='Search products'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ width: 200 }}
              />
            </Box>

            {renderProductList(filteredProducts)}
          </Box>

          {/* Right side: Selected products */}
          <Box sx={{ width: '40%' }}>{renderSelectedProducts()}</Box>

          {/* Right side: Selected products */}
          <Box sx={{ width: '20%' }}>{renderInfo()}</Box>
        </Box>
      </Drawer>
    </>
  );
};
