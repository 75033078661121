import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import {  Grid } from '@mui/material';
import useWindowsDimension from 'functions/useWindowsDimension';

import axios from 'axios';

import { BillingCompanyViewModel } from 'pages/Billing/BillingConfig';
import { rq } from 'http/apiRoutes';
import { formatDate } from 'functions/misc';
import { useGet } from 'http/useInnovit';
import { PDFFile } from 'components/ui/Billing/PDFFile';
import { pdf, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


function calculateTotalBilling(userTicketsPeriodeData: any): number {
  let totalSum = 0;

  userTicketsPeriodeData.tickets.forEach((ticket: any) => {
    if (ticket.ticketPosts) {
      ticket.ticketPosts.forEach((post: any) => {
        if (post.ticketPostBilling) {
          const billing = post.ticketPostBilling;
          totalSum +=
            (billing.nightBillableValue || 0) +
            (billing.workBillableValue || 0) +
            (billing.midDayBillableValue || 0) +
            (billing.eveningBillableValue || 0);
        }
      });
    }
  });

  return totalSum;
}


export const Test = () => {
  const { width, height } = useWindowsDimension();

  const parentRef = useRef<HTMLDivElement>(null); // Spesifiserer HTMLDivElement som type for ref
  const [parentWidth, setParentWidth] = useState(0);
  const [pdfBlob, setPdfBlob] = React.useState<Blob | null>(null);

  const [billingPost, setBillingPost] = useState<BillingCompanyViewModel | null>(null);
 let startDate = formatDate(new Date('2024-05-21'));
   let endDate = formatDate(new Date('2024-10-30'));
 
   let companyId = '1FE31127-AF5D-4653-8C39-E9D7197FF53D';
   let isEnabled = !billingPost ? true : false;
   const { data: userTicketsPeriodeData } = useGet(
     rq.userTicketsPeriod(companyId, startDate, endDate, isEnabled),
     isEnabled
   );
   useEffect(() => {
     if (!userTicketsPeriodeData) return;
 
     console.log('userTicketsPeriodeData', userTicketsPeriodeData.tickets);
     const totalBillingValue = calculateTotalBilling(userTicketsPeriodeData);
     console.log('userTicketsPeriodeData Value:', totalBillingValue);
     let billingPost: BillingCompanyViewModel = {
       id: '1',
       company: {
         id: '1',
         organizationNumber: '123456789',
         name: 'Østerbø',
         mainAddress: {
           id: '1',
           street: 'Hovedgata 1',
           postalCode: '1234',
           area: 'Oslo',
           city: 'Oslo',
           country: 'Norge',
           postBox: '1234',
         },
         deliveryAddress: {
           id: '1',
           street: 'Hovedgata 1',
           postalCode: '1234',
           area: 'Oslo',
           city: 'Oslo',
           country: 'Norge',
           postBox: '1234',
         },
         invoiceAddress: {
           id: '1',
           street: 'Hovedgata 1',
           postalCode: '1234',
           area: 'Oslo',
           city: 'Oslo',
           country: 'Norge',
           postBox: '1234',
         },
         phone: '12345678',
         email: '',
         companyType: 0,
         webPage: '',
         url: '',
         productBilling: true,
         isSupport: true,
         onHold: true,
         onHoldNote: '',
         contactId: '',
         contact: null,
         workRole: null,
         fixedHourlyRate: 0,
         priority: 0,
         companyProducts: [],
         legacyId: 0,
         thirdPartyId: '',
         thirdParty: 0,
       },
       cost: 0,
       agreementCost: 0,
       agreementInvoiceValue: 0,
       supportCost: 0,
       supportInvoiceValue: totalBillingValue,
       investmentCost: 0,
       investmentInvoiceValue: 0,
       otherCost: 0,
       otherInvoiceValue: 0,
       agreementProductList: [],
       investmentProductList: [],
       ticketBillingList: userTicketsPeriodeData.tickets,
       otherProductList: [],
       isBilled: false,
       invoiceNo: 'IN#2024-000001',
       selectedBillingPost: undefined,
       totalInvoice: 0,
     };
     setBillingPost(billingPost);
     console.log('billingPost:', billingPost);
     const generatePDF = async () => {
       try {
         let invoiceNo = 'IN#2024-000001';
         const blob = await pdf(<PDFFile data={billingPost} />).toBlob();
         setPdfBlob(blob);
 /*         const url = URL.createObjectURL(blob);
         const link = document.createElement('a');
         link.href = url;
         link.download = `${invoiceNo} IN#2024-000001.pdf`;
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
         URL.revokeObjectURL(url); */
      } catch (error) {
         console.error('Feil under generering av PDF:', error);
       }
     };
 
     generatePDF();
   }, [userTicketsPeriodeData]);
  // Håndter første knapp for fakturering
 
  
  useLayoutEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    updateParentWidth(); // Oppdater ved mount
    window.addEventListener('resize', updateParentWidth); // Oppdater ved vindu endres

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  interface DataType {
    id: number;
    name: string;
    // Legg til flere felter basert på dataene du henter
  }
  
    const [data, setData] = useState<DataType[]>([]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get<any[]>('https://www.dustinhome.no/product/5020000831/thinkbook-16-g6');
          setData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);
  
console.log('data:', data);
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {pdfBlob ? (
        <iframe
          src={URL.createObjectURL(pdfBlob)}
          style={{ height: '100%', width: '100%', border: 'none' }}
          title="PDF Viewer"
        ></iframe>
      ) : (
        <p>Laster PDF...</p>
      )}
    </div>
  );
};