import useWindowsDimension from 'functions/useWindowsDimension';
import { rq } from 'http/apiRoutes';
import { useGet } from 'http/useInnovit';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Drawer, Box } from '@mui/material';
import { CompaniesList } from '../CompaniesList';
import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';

type CompaniesDrawerProps = {
  buttonIcon: React.ReactNode; // or 'any' if you prefer
  buttonContext: string; // or 'any', but string is typically used
  handleChange: (
    event: React.MouseEvent<HTMLElement>,
    company: ICompanyViewModel
  ) => void;
};

export const CompaniesDrawer = ({
  buttonIcon,
  buttonContext,
  handleChange,
}: CompaniesDrawerProps) => {
  const { width } = useWindowsDimension();
  const [open, setOpen] = useState(false);
  const [companies, setCompanies] = useState([]);

  const ref = useRef<number>(2);
  const { data: companiesData } = useGet(
    rq.companies(ref.current.toFixed(0), true)
  );

  const handleLocalChange = (
    e: React.MouseEvent<HTMLElement>,
    company: ICompanyViewModel
  ) => {
    // 1) Call the "external" handleChange that you received as a prop
    handleChange(e, company);

    // 2) Close the drawer
    setOpen(false);
  };

  useEffect(() => {
    if (companiesData) {
      //console.log('data', data);
      setCompanies(companiesData.companies);
    }
  }, [companiesData]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%',
          height: '100%',
        }}
      >
        <Button
          sx={{ p: 0, m: 0 }}
          size='small'
          variant='contained'
          onClick={() => setOpen(!open)}
        >
          {buttonIcon}
        </Button>
      </Box>
      <Drawer anchor={'left'} open={open} onClose={() => setOpen(!open)}>
        <Box sx={{ width: width / 2, backgroundColor: 'primary.main' }}>
          <CompaniesList
            handleChange={handleLocalChange}
            isRemoveShowCompanyView={true}
            // companies={companies}
            //   setOpen={setOpen}
            //   handleEmployeeSubmit={handleEmployeeSubmit}
            //   handleRemoveEmployeeSubmit={handleRemoveEmployeeSubmit}
          />
        </Box>
      </Drawer>
    </>
  );
};
