import * as React from 'react';
import { useEffect, useState } from 'react';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import { Grid, Avatar, Stack, Typography, Box } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { EnumObjectType, EnumPriority, MissingPhoto, objectArray } from 'Interfaces/IGlobalTypeConfig';
import GetPriorityColor from 'functions/GetPriorityColor';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { flatten } from 'lodash';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { useGetObjectList } from 'functions/useGetObjectList';
import { t } from 'i18next';
import { TextStyle } from 'data/FontStyle';
import { useFetcher } from 'react-router-dom';

type Props = {
  employee: any;
  company: any;
  priority: EnumPriority;
};
export const SingleTicketContact = ({ employee, company, priority}: Props) => {
  // console.log('SingleTicketContact', employee, company);
  
  const handleChangeEmployee = (e: any) => {
    
  }



  return (
    <Grid
      container
      direction={'row'}
      sx={{ display: 'flex', alignItems: 'center', pt: 1, mb: -1 }}
    >
   
      <Grid xs={6} item sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
        <Grid
          container
          direction={'row'}
          sx={{
            mb: 1,
            borderLeft: 8,
            borderRight: 3,
            borderRadius: 3,
            borderColor: GetPriorityColor(priority),
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
        >
          <img
            src={company?.url || MissingPhoto.COMPANY_URL}
            style={{
              width: 160,
              height: 80,
              borderRadius: 3,
              objectFit: 'contain',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            }}
            alt={'previewUrl'}
          />
          <Stack direction={'column'} sx={{ ml: 1 }}>
            <Typography
              sx={{
                letterSpacing: 2,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              {company?.name}
            </Typography>
            <Typography
              sx={{
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {company?.phone}
            </Typography>
            <Typography
              sx={{
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {company?.email}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid xs={6} item sx={{ display: 'flex', alignItems: 'center', pr: 1 }}>
        <Grid
          container
          direction={'row'}
          sx={{
            mb: 1,
            borderLeft: 8,
            borderRight: 3,
            borderRadius: 3,
            borderColor: GetPriorityColor(priority),
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
        >

         
          <Grid container sx={{display: "flex", flexDirection:"row"}}>
            <img
            src={employee?.url || MissingPhoto.EMPLOYEE_URL}
            style={{
              width: 80,
              height: 80,
              borderRadius: 3,
              objectFit: 'contain',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            }}
            alt={'previewUrl'}
          />
              <Grid item sx={{display: "flex", flexDirection: "column"}}>

            <Typography
              sx={{
                letterSpacing: 2,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              {employee?.name}
            </Typography>

            <Typography
              sx={{
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {employee?.phone}
            </Typography>
            <Typography
              sx={{
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {employee?.email}
            </Typography>

            </Grid>
        </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
