import { combineReducers } from 'redux';
import { initialTicketState, TicketReducer } from './TicketReduces';
import { TicketPostReducer, TicketPostState } from './TicketPostReducer';
import {
  ChecklistInterface,
  initialChecklistInterface,
  TicketChecklistReducer,
} from './TicketCheckListReducer';
import {
  ITicketDetailsInterface,
  ITicketPostInterface,
} from 'Interfaces/ITicketConfig';
import {
  ICompanyInterface,
  ICompanyViewModel,
} from 'Interfaces/ICompaniesConfig';
import { initialCompanyState } from 'pages/Companies/CompanyForm';
import { CompanyReducer } from './CompanyReducer';
import { IEmployeeInterface, ITicketEmployeeInterface } from 'Interfaces/IEmployeeConfig';
import { employeeInitialState } from 'pages/Companies/EmployeeForm';
import { EmployeeReducer, ticketEmployeeInitial } from './EmployeeReducer';

// Kombiner staten fra de ulike reducerne
export interface RootState {
  ticket: ITicketDetailsInterface;
  ticketPost: ITicketPostInterface;
  checklist: ChecklistInterface;
  company: ICompanyInterface;
  employee: ITicketEmployeeInterface;
}

// Initial root state kombinerer alle initial states
export const initialRootState: RootState = {
  ticket: initialTicketState,
  ticketPost: TicketPostState,
  checklist: initialChecklistInterface,
  company: initialCompanyState,
  employee: ticketEmployeeInitial,
};

export const RootReducer = combineReducers<RootState>({
  ticket: TicketReducer,
  ticketPost: TicketPostReducer,
  checklist: TicketChecklistReducer,
  company: CompanyReducer,
  employee: EmployeeReducer,
});
