import { IRateViewModel } from 'data/atoms/atomConfig';
import dayjs from 'dayjs';

export const findHours = (
  type: 'night' | 'work' | 'midDay' | 'evening' | 'notBillable',
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
  rates: IRateViewModel[]
): number => {
  console.log('findHours :-', type, startDate, endDate, rates);

  if (!startDate || !endDate || !rates) {
    console.log('calculateTicketPostBilling :- Missing parameters', type);
    return 0;
  }

  const interval = (() => {
    switch (type) {
      case 'night':
        return rates[0];
      case 'work':
        return rates[1];
      case 'midDay':
        return rates[2];
      case 'evening':
        return rates[3];
      case 'notBillable':
        let returnRate: IRateViewModel = {
          id: '',
          startHour: 0,
          endHour: 24,
          rateValue: 0,
          percentageIncrease: 0,
        };
        return returnRate;
      default:
        return 0;
    }
  })();

  if (!interval) {
    return 0;
  }

  console.log(
    'findHours :- interval',
    interval,
    startDate.hour(),
    endDate.hour(),
    startDate.minute(),
    endDate.minute()
  );

  const startMinutes = startDate.hour() * 60 + startDate.minute();
  const endMinutes = endDate.hour() * 60 + endDate.minute();

  console.log('findHours :- Date ', startDate.hour(), startDate.minute());

  const intervalStartMinutes = interval.startHour * 60;
  const intervalEndMinutes = interval.endHour * 60;

  console.log(
    'findHours :- intervals',
    intervalStartMinutes,
    intervalEndMinutes
  );

  let totalMinutes = 0;

  for (let i = startMinutes; i < endMinutes; i++) {
    if (i >= intervalStartMinutes && i < intervalEndMinutes) {
      totalMinutes++;
    }
  }

  const totalHours = totalMinutes / 60;
  console.log('findHours :- totalHours', totalHours);
  return totalHours;
};
