export interface ChecklistItem {
  id: number;
  name: string;
  completed: boolean;
}

export interface ChecklistInterface {
  checklist: ChecklistItem[];
}

export const initialChecklistInterface: ChecklistInterface = {
  checklist: [],
};

export type ChecklistAction =
  | { type: 'SET_CHECKLIST'; payload: ChecklistItem[] }
  | { type: 'ADD_CHECKLIST_ITEM'; payload: ChecklistItem }
  | { type: 'REMOVE_CHECKLIST_ITEM'; payload: number }; // Remove by ID

export const TicketChecklistReducer = (
  state: ChecklistInterface = initialChecklistInterface,
  action: ChecklistAction
): ChecklistInterface => {
  switch (action.type) {
    case 'SET_CHECKLIST':
      return { ...state, checklist: action.payload };
    case 'ADD_CHECKLIST_ITEM':
      return { ...state, checklist: [...state.checklist, action.payload] };
    case 'REMOVE_CHECKLIST_ITEM':
      return {
        ...state,
        checklist: state.checklist.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
};
