import { Typography } from '@mui/material';
import { getRates } from 'functions/GetRates';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import ratesAtom from 'data/atoms/ratesAtom';

type Rate = {
  startHour: number;
  endHour: number;
  rateValue: number;
};

const calculateAmount = (
  rates: Rate[], // Specify that rates is an array of Rate objects
  startHour: number,
  startMin: number,
  endHour: number,
  endMin: number
) => {
  let totalAmount = 0;
  let currentHour = startHour;
  let currentMin = startMin;

  // Helper function to get rate based on current hour
  const getRateForCurrentHour = (hour: number) => {
    for (const {
      startHour: rateStartHour,
      endHour: rateEndHour,
      rateValue,
    } of rates) {
      if (hour >= rateStartHour && hour < rateEndHour) {
        return rateValue;
      }
    }
    return 0; // Default to 0 if no rate matches
  };

  // Calculate amount based on rates
  while (
    currentHour < endHour ||
    (currentHour === endHour && currentMin < endMin)
  ) {
    const rate = getRateForCurrentHour(currentHour); // Get rate once per hour
    const duration = (currentHour === endHour ? endMin : 60) - currentMin;
    totalAmount += (duration / 60) * rate;

    currentMin = 0;
    currentHour++;
  }

  return totalAmount;
};

export const TotalBillableAmount = (props: any) => {
  const { posts, size, color, fixedHourlyRate, maxPrice } = props;
  console.log('TotalBillableAmount: props, ', props);
  const [rates] = useAtom(ratesAtom);
  // console.log('rates: ', rates);
  // const user = JSON.parse(window.localStorage.getItem('USER'));
  let totalBillableAmount = 0;
  let totalDeductionAmount = 0;
  console.log('totalBillableAmount - posts ', posts);

  // filter all.isBillable posts
  let isBillablePosts = posts.filter(
    (post: any) =>
      post.billable === true && post.direction === 1 && post.internal !== true
  );

  console.log('isBillablePosts', isBillablePosts);

  // calculate total.isBillable amount
  isBillablePosts.map((post: any) => {
    //(post);

    // set start hour and min and end hour and min
    let startHour = new Date(post.startTime).getHours();
    let startMin = new Date(post.startTime).getMinutes();
    let endHour = new Date(post.endTime).getHours();
    let endMin = new Date(post.endTime).getMinutes();
    // use the times and rates to calculate the total amount
    // console.log('TIME:', startHour, startMin, endHour, endMin, rates);
    totalBillableAmount += calculateAmount(
      rates,
      startHour,
      startMin,
      endHour,
      endMin
    );
    console.log('totalBillableAmount - calculation', totalBillableAmount);

    let startDeductHour = new Date(post.startDeduction).getHours();
    let startDeductMin = new Date(post.startDeduction).getMinutes();
    let endDeductHour = new Date(post.endDeduction).getHours();
    let endDeductMin = new Date(post.endDeduction).getMinutes();

    // use the times and rates to calculate the deduction value
    totalDeductionAmount += calculateAmount(
      rates,
      startDeductHour,
      startDeductMin,
      endDeductHour,
      endDeductMin
    );
    totalBillableAmount -= totalDeductionAmount;
    if (maxPrice > 0)
      totalBillableAmount = Math.min(totalBillableAmount, maxPrice);
    return totalBillableAmount;
  });

  console.log('totalBillableAmount - return ', totalBillableAmount);

  return (
    <Typography
      sx={{
        p: 0,
        m: 0,
        width: 150,
        fontSize: size,
        letterSpacing: 3,
        textAlign: 'right',
        color: color,
        fontWeight: 'bold',
      }}
    >
      {totalBillableAmount?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </Typography>
  );
};
