import { useEffect, useState } from 'react';
import { Button, Grid, List, ListItem, Paper, Stack } from '@mui/material';
import { ProductCardView } from 'components/ui/ProductCardView';
import { SearchFilter } from 'components/ui/SearchFilter';
import useWindowsDimension from 'functions/useWindowsDimension';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePut } from 'http/useInnovit';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import {
  IEmployeeInterface,
  IEmployeeUpdateViewModel,
  IEmployeeViewModel,
} from 'Interfaces/IEmployeeConfig';
import {
  EnumObjectType,
  EnumRole,
  IAddressViewModel,
} from 'Interfaces/IGlobalTypeConfig';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { t } from 'i18next';
import {
  IDepartmentViewModel,
  ThirdPartEnum,
} from 'Interfaces/ICompaniesConfig';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { SingleCompanyProductView } from 'components/ui/SingleCompanyProductView';
import { SingleProductView } from 'components/ui/SingleProductView';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';

const initialState: IEmployeeInterface = {
  // employee data
  id: 'new',
  creatorId: '',
  name: '',
  phone: '+47',
  email: '',
  departmentId: '',
  department: {
    id: 'new',
    name: '',
    companyId: '',
    contactId: 'new',
    contact: {} as IEmployeeViewModel,
    phone: '',
    email: '',
    mainAddress: {
      street: '',
      area: '',
      postalCode: '',
      city: '',
      country: '',
      postBox: '',
    } as IAddressViewModel,
    deliveryAddress: {
      street: '',
      area: '',
      postalCode: '',
      city: '',
      country: '',
      postBox: '',
    } as IAddressViewModel,
    canDelete: false,
    isDirty: false,
    isActive: false,
    isDefault: false,
  } as IDepartmentViewModel,
  address: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: '',
    postBox: '',
  } as IAddressViewModel,
  role: EnumRole.Employee,
  primaryContact: false,
  globalAdministrator: false,
  isOnboardingEnabled: false,
  budget: 0,
  title: '',
  url: '',
  fileModel: null,
  workRoleId: null,
  workRole: {} as IWorkRoleViewModel,
  companyProducts: [] as ICompanyProductViewModel[],
  productCatalog: [] as ICompanyProductViewModel[], // for selection
  storageProducts: [] as ICompanyProductViewModel[], // for selection
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  legacyId: 0,
  thirdPartyId: '',
  thirdParty: ThirdPartEnum.None,
  isValid: false,
  isDirty: false,
};

export const AssignProducts = ({
  companyId,
  employeeId,
  employee,
}: {
  companyId: string;
  employeeId: string;
  employee: IEmployeeViewModel | IEmployeeUpdateViewModel | IEmployeeInterface;
}) => {
  // INIT
  const { height } = useWindowsDimension();
  const [selectedProducts, setSelectedProducts] = useState<
    ICompanyProductViewModel[]
  >([]);
  let isEnabled = true;
  const [user] = useAtom(userAtom);

  // API
  const { data: availableProductsData } = useGet(
    rq.getAvailableCompanyProducts(companyId || 'new', isEnabled),
    isEnabled
  );
  const putEmployee = usePut(
    rqPost.employee(companyId, employeeId, true),
    true
  );

  // HANDLES
  const handleProductToggle = (product: ICompanyProductViewModel) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.find((p) => p.id === product.id)) {
        // Remove product if already selected
        return prevSelected.filter((p) => p.id !== product.id);
      } else {
        // Add product if not selected
        return [...prevSelected, product];
      }
    });
  };

  // USEEFFECT
  useEffect(() => {
    console.log('selected products', selectedProducts);
  }, [selectedProducts]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const updatedSelectedProducts = selectedProducts.map((product) => ({
      ...product,
      productQuantity: 1, // Add or overwrite productQuantity to 1
    }));

    const updateEmployee: IEmployeeUpdateViewModel = {
      id: employee.id || '',
      creatorId: user.id,
      name: employee.name,
      phone: employee.phone,
      email: employee.email,
      departmentId: employee.departmentId,
      department: employee.department,
      address: employee.address,
      role: employee.role,
      primaryContact: employee.primaryContact,
      globalAdministrator: employee.globalAdministrator,
      budget: employee.budget,
      title: employee.title,
      fileModel: null,
      companyProducts: updatedSelectedProducts,
      productCatalog: [],
      storageProducts: [],
      unassignedCompanyProducts: [],
      workRoleId:
        'workRole' in employee && employee.workRole
          ? employee.workRole.id
          : null, // Convert workRole to workRoleId
    };

    putEmployee.mutate(updateEmployee, {
      onSuccess: (res: any) => {
        // Handle success (e.g., show a message)
        console.log('PUT EMPLOYEE', updateEmployee, res);
      },
    });
  };

  return (
    <Grid>
      {/*   HEADER */}
      <Grid item xs={12}>
        <SearchFilter
          Icon={FeedRoundedIcon}
          Header={t('company.employees.assignHeader')}
          gfx={
            <Stack direction='row' spacing={1} sx={{ mt: 0.5, mr: 2 }}>
              <SingleEmployeeView employee={employee}></SingleEmployeeView>
            </Stack>
          }
          filterValue={null}
          handleFilterChange={null}
          handleSearch={null}
          handleNew={null}
          selectList={null}
        />
      </Grid>
      <List
        sx={{
          width: '100%',
          height: Math.ceil(height - 290),
          p: 1,
          pt: 0,
          overflow: 'auto',
          backgroundColor: 'primary.main',
        }}
      >
        <Grid item xs={12} sx={{ mb: 2 }}>
          <List
            sx={{
              width: '100%',
              overflow: 'auto',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: 3,
            }}
          >
            {availableProductsData &&
              availableProductsData.companyProducts.map(
                (companyProduct: ICompanyProductViewModel, index: number) => (
                  <ListItem
                    sx={{
                      mb: 1,
                      p: 0,
                      pl: 1,
                      pr: 1,
                      filter: selectedProducts.some(
                        (p) => p.id === companyProduct.id
                      )
                        ? 'brightness(1.3)'
                        : 'brightness(1)',
                    }}
                    key={index * 10}
                    button
                    onClick={() => handleProductToggle(companyProduct)}
                    selected={selectedProducts.some(
                      (p) => p.id === companyProduct.id
                    )}
                  >
                    <SingleCompanyProductView companyProduct={companyProduct} />
                  </ListItem>
                )
              )}
          </List>
        </Grid>
      </List>
      <Paper
        variant='elevation3'
        sx={{
          p: 2,
          height: 64,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Button type='submit' variant='contained' onClick={onSubmit}>
          Assign Products
        </Button>
      </Paper>
    </Grid>
  );
};
