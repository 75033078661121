import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { IBillingCompanyProductViewModel } from 'Interfaces/BillingConfig';
import { pdfStyle } from './PDFStyle';
import React from 'react';

// Define the data type for the prop
interface Props {
  data: {
    investmentProductList: IBillingCompanyProductViewModel[];
    investmentInvoiceValue: number;
  };
}

// Create Document Component
export const PDFInvestmentView = ({ data }: Props) => {
  // Calculate total investment value
  const productList = Array.isArray(data.investmentProductList) ? data.investmentProductList : [];
  const total = productList.reduce((acc, item) => {
    return acc + item.retailPrice * item.quantity;
  }, 0);
  

  return (
    <>
      <View style={pdfStyle.column100}>
        <Text style={pdfStyle.textLeft}>{'INVESTMENT'}</Text>
      </View>

      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'Product Name'}</Text>
        </View>
        <View style={pdfStyle.column25}>
          <Text style={pdfStyle.textLeft}>{'Owner'}</Text>
        </View>
        <View style={pdfStyle.column25}>
          <Text style={pdfStyle.textRight}>{'Line Total'}</Text>
        </View>
      </View>
      
      {/* Divider */}
      <View style={pdfStyle.divider}></View>

      {/* Map over investment products */}
      {data.investmentProductList.map((investmentItem, index) => {
        const lineTotal = investmentItem.retailPrice * investmentItem.quantity;

        return (
          <View key={index} style={pdfStyle.containerView}>
            <View style={pdfStyle.rowView}>
              <View style={pdfStyle.column50}>
                <Text style={pdfStyle.textLeft}>
                  {investmentItem.product.name}
                </Text>
              </View>
              <View style={pdfStyle.column25}>
                <Text style={{ ...pdfStyle.textInfo, paddingLeft: 5, paddingBottom: 5 }}>
                  {investmentItem.owner?.name || 'None'}
                </Text>
              </View>
              <View style={pdfStyle.column25}>
                <Text style={pdfStyle.textRight}>
                  {lineTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </View>
            </View>
          </View>
        );
      })}

      {/* Divider */}
      <View style={pdfStyle.divider} />
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'INVESTMENTS TOTAL'}</Text>
        </View>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textRight}>
            {total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>

      <View style={pdfStyle.avsnitt} />
      <View style={pdfStyle.avsnitt} />
    </>
  );
};
