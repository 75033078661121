import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import CompanyView from 'components/ui/CompanyView';
import { SearchFilter } from 'components/ui/SearchFilter';
import { emptyCompany } from 'data/templates';
import { companyTypes } from 'data/enum';
import { getFilteredList } from 'functions/misc';
import useWindowsDimension from 'functions/useWindowsDimension';
import { Grid, List, Paper } from '@mui/material';
import { PageHeader } from 'components/ui/Global/PageHeader';
import Business from '@mui/icons-material/Business';
import { useTranslation } from 'react-i18next';
import { ICompanyViewModel, ThirdPartEnum } from 'Interfaces/ICompaniesConfig';
import _ from 'lodash';
import { IAddressViewModel } from 'Interfaces/IGlobalTypeConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';

export const initialCompanyState: ICompanyViewModel = {
  id: 'new',
  organizationNumber: '',
  name: '',
  mainAddress: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: 'Norway',
  } as IAddressViewModel,
  deliveryAddress: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: 'Norway',
  } as IAddressViewModel,
  invoiceAddress: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: 'Norway',
  } as IAddressViewModel,
  phone: '+47',
  email: '',
  companyType: 2,
  webPage: '',
  url: '',
  productBilling: true,
  onHold: false,
  isSupport: false,
  onHoldNote: '',
  priority: 2,
  fixedHourlyRate: 0,
  workRole: null,
  contactId: null,
  contact: null,
  companyProducts: [] as ICompanyProductViewModel[],
  legacyId: 0,
  thirdPartyId: '',
  thirdParty: ThirdPartEnum.None,
};

export type CompaniesListProps = {
  handleChange?: (
    event: React.MouseEvent<HTMLElement>,
    company: ICompanyViewModel
  ) => void;
  isRemoveShowCompanyView?: boolean;
};

export const CompaniesList = ({
  handleChange,
  isRemoveShowCompanyView,
}: CompaniesListProps) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { height } = useWindowsDimension();
  const ref = useRef<number>(2);

  let isCompanyShowView = isRemoveShowCompanyView ? false : true;
  // API
  const { data } = useGet(rq.companies(ref.current.toFixed(0), true));

  // State
  const [company, setCompany] =
    useState<ICompanyViewModel>(initialCompanyState);
  const [companies, setCompanies] = useState<ICompanyViewModel[]>([]);
  const [filterType, setFilterType] = useState(2);

  // Handlers
  const handleNew = () => {
    navigate('/companies/new');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!data?.companies) return;
    const filtered = getFilteredList(data.companies, e.target.value);
    setCompanies(filtered);
  };

  const handleFilterChange = (val: number) => {
    setFilterType(val);
    ref.current = val;
  };

  // On data load/update, clone to local list
  useEffect(() => {
    if (!data?.companies) return;
    setCompanies(_.cloneDeep(data.companies));
  }, [data]);

  // Whenever 'company' changes, call the parent's 'handleChange'

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Grid item xs={12}>
        <PageHeader
          Icon={Business}
          title={t('company.header')}
          FormJSX={null}
          HeaderButtons={[]}
        />
      </Grid>

      <Grid item xs={isCompanyShowView ? 5 : 12} sx={{ pr: 1 }}>
        <SearchFilter
          Icon={Business}
          Header={t('company.header')}
          filterValue={filterType}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
          handleNew={handleNew}
          selectList={companyTypes}
        />

        <List
          sx={{
            height: Math.ceil(height - 244),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {companies.length === 0 && <div>No data</div>}
          {companies.map((cmp, index) => (
            <Grid
              key={index}
              sx={{ cursor: 'pointer', marginBottom: 1 }}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                // 1) Set the chosen company in your local state
                setCompany(cmp);
                // 2) Call the parent's handleChange
                handleChange?.(e, cmp);
              }}
            >
              <SingleCompanyView
                key={index}
                index={index}
                company={cmp}
                detailNav={true}
              />
            </Grid>
          ))}
        </List>

        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        />
      </Grid>

      <Grid item xs={isCompanyShowView ? 7 : 0} sx={{ pl: 1 }}>
        <Paper variant='elevation3'>
          <List
            sx={{
              pl: 2,
              pr: 2,
              height: Math.ceil(height - 128),
              backgroundColor: 'primary.main',
              overflow: 'auto',
              borderRadius: 3,
            }}
          >
            {company.id ? (
              <CompanyView company={company} />
            ) : (
              <CompanyView company={emptyCompany} />
            )}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
