import { IRateViewModel } from 'data/atoms/atomConfig';

export const checkForOvertime = (
  rates: IRateViewModel[],
  hours: any,
  minutes: any
) => {
  let isOverTime = false;
  if (rates == null) {
    return false;
  }
  if (
    (hours === rates[1].startHour && minutes > 0) ||
    (hours === rates[1].endHour && minutes > 16) ||
    hours < rates[1].startHour ||
    hours > rates[1].endHour
  ) {
    isOverTime = true;
  }
  return isOverTime;
};
