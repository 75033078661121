import { Box, Button, Grid, List, Paper } from '@mui/material';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import DevicesIcon from '@mui/icons-material/Devices';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
// const useWindowsDimension = require('functions/useWindowsDimension.js');
import useWindowsDimension from 'functions/useWindowsDimension';
import { rq, rqPost } from 'http/apiRoutes';
import { FormBox } from 'components/form';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { EnumObjectType, objectArray } from 'Interfaces/IGlobalTypeConfig';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import { useTranslation } from 'react-i18next';
import { useGetObjectList } from 'functions/useGetObjectList';
import { usePut } from 'http/useInnovit';
import _ from 'lodash';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import {
  ITicketInitialState,
  ITicketUpdateWithProductViewModel,
  TicketUpdateViewModel,
} from 'Interfaces/ITicketConfig';
import { TicketProductDrawer } from './TicketProductDrawer';
import ConvertBase64StringToFileModel from 'functions/ConvertBase64StringToFileModel';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { IProductViewModel } from 'Interfaces/IProductConfig';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';

const { SearchFilter } = require('../../components/ui/SearchFilter');
const { useGet } = require('http/useInnovit');
const { SingleProductView } = require('components/ui/SingleProductView');

type TicketProductParams = {
  ticketId: string;
};

interface SelectedProducts {
  companyProducts: ICompanyProductViewModel[];
  catalogProducts: IProductViewModel[];
  storageProducts: IStorageProductViewModel[];
  isOnboarding: boolean;
}

// const ConvertTicketToTicketUpdateWithProductViewModel = (
//   ticket: TicketUpdateViewModel
// ) => {
//   console.log(`Converting ticket`, ticket);
//   let obj: ITicketUpdateWithProductViewModel= {
//     id: ticket.id,

//   }
// };

export const TicketProducts = () => {
  let { ticketId } = useParams<TicketProductParams>();
  //const { companyId, employeeId } = useParams();

  if (!ticketId) {
    ticketId = '0';
  }

  // console.log('ticket ID: ' + ticketId);
  const { t } = useTranslation();
  const { height } = useWindowsDimension();
  const [user] = useAtom(userAtom);

  const [ticket, setTicket] = useState<any>({});
  const [products, setProducts] = useState<any[]>([]);
  const [companyProducts, setCompanyProducts] = useState<any[]>([]);

  let isEnabled = ticketId === '0' ? false : true;
  const { data: ticketProductsData } = useGet(
    rq.ticketProducts(ticketId, isEnabled),
    isEnabled
  );

  const { data: ticketData } = useGet(
    rq.ticket(ticketId, isEnabled, '1'),
    isEnabled
  );

  const putTicket = usePut(
    rqPost.ticketPut(ticketId, '0', isEnabled),
    isEnabled
  );

  const [isObjectList, setIsObjectList] = useState<boolean>(true);

  let companyId = 'new';
  let employeeId = 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let productId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );

  // const { data: consumableProductData } = useGet(
  //   rq.consumableCompanyProduct(companyId, productId, ticketId, isEnabled),
  //   isEnabled
  // );

  const handleChange = (
    event: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    console.log('...handling change, objectType', objectType);
    console.log('EnumObjectTypes: ', EnumObjectType);

    switch (objectType) {
      case EnumObjectType.Product:
        console.log('products state before change: ', products);
        console.log('selectedProduct: ', selectedObject);
        setProducts((prevState) => [...prevState, selectedObject]);
        console.log('products state after change: ', products);
        break;

      case EnumObjectType.unassignedCompanyProduct:
        setCompanyProducts((prevState) => [...prevState, selectedObject]);
        break;
    }
  };

  useEffect(() => {
    if (ticketProductsData) {
      setProducts(ticketProductsData.products);
      setCompanyProducts(ticketProductsData.companyProducts);
    }
  }, [ticketProductsData]);

  useEffect(() => {
    console.log('...current products state: ', products);
    console.log('...current companyProducts state: ', companyProducts);
  }, [products, companyProducts]);

  useEffect(() => {
    console.log('...current ticket: ', ticketData);
    if (ticketData) {
      setTicket(ticketData);
    }
  }, [ticketData]);

  console.log('ticketProducts', ticketProductsData);

  // const onSubmitProduct = () => {
  //   const _ticket = _.cloneDeep(ticket);

  //   let updatedTicket =
  //     ConvertTicketToTicketUpdateWithProductViewModel(_ticket);

  //   // putTicket.mutate(updatedTicket, {
  //   //   onSuccess: (state: any) => {},
  //   // });
  // };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Grid item xs={6} sx={{ p: 0, m: 0 }}>
        <Grid item xs={12} sx={{ pr: 0.5, m: 0 }}>
          <Paper
            variant='elevation3'
            sx={{
              height: 64,
              borderRadius: 2,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              elevation: 3,
              // backgroundColor: paperDefaultColor_Main,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
              pt: 1.5,
              pl: 2,
            }}
          >
            <FormBox
              Icon={DevicesOtherRoundedIcon}
              title={t('Ticket.TicketDetails.serviceProducts')}
              FormBTN={
                <Grid sx={{ position: 'relative', top: 7 }}>
                  {ticket && ticket.company && ticket.company.id && (
                    <TicketProductDrawer
                      companyId={ticket.company.id}
                      ticketId={ticketId}
                    />
                  )}
                </Grid>
              }
            />
          </Paper>

          <List
            sx={{
              height: Math.ceil(height - 258),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {products.length <= 0 && <div>No data</div>}
            {products &&
              products.map((product: object, index: number) => {
                return (
                  <SingleProductView
                    key={index}
                    product={product}
                  ></SingleProductView>
                );
              })}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              height: 64,
              borderRadius: 2,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              elevation: 3,
              // backgroundColor: paperDefaultColor_Main,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                p: 1,
                m: 0,
                borderRadius: 2,
                backgroundColor: 'primary.main',
                display: 'flex',
                flexDirection: 'flex-end',
              }}
            >
              <Button
                sx={{ width: 250, marginLeft: 'auto' }}
                variant='contained'
                //type='submit'
                // onClick={onSubmitProduct}
                disabled={products.length === 0}
              >
                {t('Ticket.TicketDetails.addProdukt')}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={6} sx={{ p: 0, m: 0 }}>
        <Grid item xs={12} sx={{ pl: 0.5, m: 0 }}>
          <Paper
            variant='elevation3'
            sx={{
              height: 64,
              borderRadius: 2,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              elevation: 3,
              // backgroundColor: paperDefaultColor_Main,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
              pt: 1.5,
              pl: 2,
            }}
          >
            <FormBox
              Icon={DevicesOtherRoundedIcon}
              title={t('Ticket.TicketDetails.salesProducts')}
              FormBTN={
                <ObjectDrawer
                  buttonContext={t('Ticket.TicketDetails.addProduct')}
                  buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
                  objectList={!!objectDrawerList ? objectDrawerList : []}
                  objectType={[
                    // EnumObjectType.Product,
                    // EnumObjectType.StorageProduct,
                    EnumObjectType.unassignedCompanyProduct,
                    // EnumObjectType.Package,
                  ]}
                  handleChange={handleChange}
                  buttonHeight={26}
                  buttonWidth={26}
                />
              }
            />
          </Paper>

          <List
            sx={{
              height: Math.ceil(height - 258),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {companyProducts.length <= 0 && <div>No data</div>}
            {companyProducts &&
              companyProducts?.map(
                (product: { product: object }, index: number) => {
                  const companyProduct = product.product;
                  return (
                    <SingleProductView
                      key={index}
                      product={companyProduct}
                    ></SingleProductView>
                  );
                }
              )}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              height: 64,
              borderRadius: 2,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              elevation: 3,
              // backgroundColor: paperDefaultColor_Main,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          ></Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
