import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { pdfStyle } from './PDFStyle';
import { BillingCompanyViewModel } from 'pages/Billing/BillingConfig';


// Define the data type for the prop
interface Props {
  data: BillingCompanyViewModel;
}

// Create Document Component
export const PDFOtherView = ({ data }: Props) => {
  return (
    <>
      <View style={pdfStyle.column50}>
        <Text style={pdfStyle.textLeft}>{'OTHER'}</Text>
      </View>
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column15}>
          <Text style={pdfStyle.textLeft}>{'Name'}</Text>
        </View>
        <View style={pdfStyle.column35}>
          <Text style={pdfStyle.textLeft}>{'Info'}</Text>
        </View>
        <View style={pdfStyle.column10}>
          <Text style={pdfStyle.textLeft}>{'Quantity'}</Text>
        </View>
        <View style={pdfStyle.column15}>
          <Text style={pdfStyle.textRight}>{'Retail Price'}</Text>
        </View>
        <View style={pdfStyle.column20}>
          <Text style={pdfStyle.textRight}>{'Line Total'}</Text>
        </View>
      </View>
      {/* Divider */}
      <View style={pdfStyle.divider}></View>

      {/* OTHER items */}
      {data.otherProductList.map((product, index) => (
        <View key={index} style={pdfStyle.containerView}>
          <View style={pdfStyle.rowView}>
            <View style={pdfStyle.column15}>
              <Text style={pdfStyle.textLeft}>{product.product.name}</Text>
            </View>
            <View style={pdfStyle.column35}>
              <Text style={pdfStyle.textLeft}>{product.product.brand.brandName}</Text>
            </View>
            <View style={pdfStyle.column10}>
              <Text style={pdfStyle.textRight}>{product.quantity}</Text>
            </View>
            <View style={pdfStyle.column15}>
              <Text style={pdfStyle.textRight}>
                {product.retailPrice.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </View>
            <View style={pdfStyle.column20}>
              <Text style={pdfStyle.textRight}>
                {(product.quantity * product.retailPrice).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </View>
          </View>
        </View>
      ))}

      {/* Divider */}
      <View style={pdfStyle.divider} />
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'OTHER TOTAL'}</Text>
        </View>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textRight}>
            {data.otherInvoiceValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>
      <View style={pdfStyle.avsnitt} />
      <View style={pdfStyle.avsnitt} />
    </>
  );
};
