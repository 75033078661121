import {
  EnumCompanyType,
  EnumPriority,
  IAddressViewModel,
} from 'Interfaces/IGlobalTypeConfig';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { ICompanyProductViewModel } from './ICompanyProductConfig';
import { IProductTypeViewModel } from './BillingConfig';
import { IOrderProductFeaturesViewModel } from './IOrderConfig';
import { IEmployeeViewModel } from './IEmployeeConfig';
import { IBrandViewModel } from 'pages/Setup/setupConfig';

export interface ICompanySimpleViewModel {
  id: string;
  organizationNumber: string;
  name: string;
  phone: string;
  email: string;
  companyTpe: EnumCompanyType;
  webPage: string;
  url: string | null;
  productBilling: boolean;
  isSupport: boolean;
  onHold: boolean;
  onHoldNote: string;
  priority: EnumPriority;
  workRole: IWorkRoleViewModel | null;
}
export interface ISimpleCompanyInfoViewModel {
  id: string;
  name: string;
  organizationNumber: number;
  phone: string;
  email: string;
  url: string;
  priority: EnumPriority;
  onHold: boolean;
  onHoldNote: string;
  deliveryAddress: IAddressViewModel;
}
export interface ICompanyTicketViewModel {
  id: string;
  organizationNumber: number;
  name: string;
  phone: string;
  email: string;
  url: string;
  priority: EnumPriority;
  onHold: boolean;
  onHoldNote: string;
  isSupport: boolean;
  workRole: IWorkRoleViewModel | null;
  productBilling: boolean;
}

export interface ICompanyInterface {
  id: string | null;
  organizationNumber: string;
  name: string;
  mainAddress: IAddressViewModel;
  deliveryAddress: IAddressViewModel | null;
  invoiceAddress: IAddressViewModel | null;
  phone: string;
  email: string;
  supportEmail: string | null;
  companyType: EnumCompanyType;
  webPage: string | null;
  url: string | null;
  fileModel: IFileModel | null;
  productBilling: boolean;
  onHold: boolean;
  isSupport: boolean;
  onHoldNote: string | null;
  priority: EnumPriority;
  fixedHourlyRate: number | null;
  workRole: IWorkRoleViewModel | null;
  workRoleId: string | null;
  contactId: string | null;
  contact: IEmployeeViewModel | null;
  companyProducts: ICompanyProductViewModel[];
  productCatalog: ICompanyProductViewModel[];
  storageProducts: ICompanyProductViewModel[];
  unassignedCompanyProducts: ICompanyProductViewModel[];
  isValid: boolean;
  isDirty: boolean;
  isOnboardingEnabled: boolean;
  isProductChanged: boolean;
  legacyId: number;
  thirdPartyId: string;
  thirdParty: ThirdPartEnum;
}
export interface ICompanyAddViewModel {
  organizationNumber: string;
  name: string;
  mainAddress: IAddressViewModel;
  deliveryAddress: IAddressViewModel | null;
  invoiceAddress: IAddressViewModel | null;
  phone: string;
  email: string;
  supportEmail: string | null;
  companyType: EnumCompanyType;
  webPage: string | null;
  fileModel: IFileModel | null;
  productBilling: boolean;
  onHold: boolean;
  isSupport: boolean;
  onHoldNote: string | null;
  priority: EnumPriority;
  fixedHourlyRate: number | null;
  workRoleId: string | null;
  contactId?: string | null;
  companyProducts: ICompanyProductViewModel[];
  productCatalog: ICompanyProductViewModel[];
  storageProducts: ICompanyProductViewModel[];
  unassignedCompanyProducts: ICompanyProductViewModel[];
  legacyId: number;
  thirdPartyId: string;
  thirdParty: ThirdPartEnum;
}
export enum ThirdPartEnum {
  None = 0,
  CloudFactory = 1,
  Exledger = 2,
}
export interface ICompanyUpdateViewModel {
  id: string;
  organizationNumber: string;
  name: string;
  mainAddress: IAddressViewModel;
  deliveryAddress: IAddressViewModel | null;
  invoiceAddress: IAddressViewModel | null;
  phone: string;
  email: string;
  supportEmail: string | null;
  companyType: EnumCompanyType;
  webPage: string | null;
  fileModel: IFileModel | null;
  productBilling: boolean;
  onHold: boolean;
  isSupport: boolean;
  onHoldNote: string | null;
  priority: EnumPriority;
  fixedHourlyRate: number | null;
  workRoleId: string | null;
  contactId?: string | null;
  companyProducts: ICompanyProductViewModel[];
  productCatalog: ICompanyProductViewModel[];
  storageProducts: ICompanyProductViewModel[];
  unassignedCompanyProducts: ICompanyProductViewModel[];
  legacyId: number;
  thirdPartyId: string;
  thirdParty: ThirdPartEnum;
}

export interface ICompanyViewModel {
  id: string;
  organizationNumber: string;
  name: string;
  mainAddress: IAddressViewModel;
  deliveryAddress: IAddressViewModel;
  invoiceAddress: IAddressViewModel;
  phone: string;
  email: string;
  companyType: number;
  webPage: string;
  url: string;
  productBilling: boolean;
  isSupport: boolean;
  onHold: boolean;
  onHoldNote: string;
  contactId: string | null;
  contact: IEmployeeViewModel | null;
  workRole: IWorkRoleViewModel | null;
  fixedHourlyRate: number;
  priority: number;
  companyProducts: ICompanyProductViewModel[];
  legacyId: number;
  thirdPartyId: string;
  thirdParty: ThirdPartEnum;  
}
export interface IProductUpdateViewModel {
  id: string;
  name: string;
  url?: string | null;
  serialNumber: string | null;
  unitPrice: number;
  retailPrice: number;
  productCategory: string;
  productType: IProductTypeViewModel;
  brandId: string;
  brand: IBrandViewModel;
  quantity: number;
  warning: boolean;
  warningNote?: string | null;
  paymentCycle: number;
  cycleAlignment: number;
  bindingPeriod: number;
  features?: IOrderProductFeaturesViewModel | null;
}
export interface IFileModel {
  fileName: string;
  content: any;
}

export interface IDepartmentInitialState {
  id: string;
  name: String;
  companyId: string;
  contact: IEmployeeViewModel | null;
  contactId: string | null;
  phone: string;
  email: string;
  mainAddress: IAddressViewModel;
  deliveryAddress: IAddressViewModel;
  canDelete: boolean;
  isDirty: boolean;
  isActive: boolean;
  isDefault: boolean;
}

export interface IDepartmentViewModel {
  id: string;
  name: String;
  companyId: string;
  contactId: string | null;
  contact: IEmployeeViewModel;
  phone: string;
  email: string;
  mainAddress: IAddressViewModel;
  deliveryAddress: IAddressViewModel;
  canDelete: boolean;
  isDirty: boolean;
  isActive: boolean;
  isDefault: boolean;
}

export interface IDepartmentAddViewModel {
  companyId: string;
  name: String;
  phone: string;
  email: string;
  mainAddress: IAddressViewModel | null;
  contactId: string;
  isActive: boolean;
  isDefault: boolean;
}

export interface IDepartmentUpdateViewModel {
  id: string;
  companyId: string;
  name: String;
  phone: string;
  email: string;
  mainAddress: IAddressViewModel | null;
  contactId: string;
  isActive: boolean;
  isDefault: boolean;
}
